import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Moment from 'moment'
import TimeField from 'react-simple-timefield';
import API from '../services/API/API'
import PageHead from "../components/pageHead";
import BoxSearchPresence from '../components/boxSearchPresence';
import "../styles/styles.css";
import 'moment/locale/fr';

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

const PresenceCollaboratorPerDay = () => {
    let sumHour = 0.0
    let nbrPresent  = 0
    let nbrMaladie = 0
    let nbrConge = 0
    const role = sessionStorage.getItem("role");
    const [suivis, setSuivis] = useState<Array<any>>([])
    const [searchTitle, setSearchTitle] = useState('')
    const [collaborators, setCollaborators] = useState<Array<any>>([])
    const [show, setShow] = useState(false)
    const [shownbr, setShownbr] = useState(false)
    const [showCol, setShowCol] = useState(false)
    const [showUpdate, setShowUpdate] = useState(false)
    const [date_created, setDate_created] = useState('')
    const [debut, setDebut] = useState('00:00')
    const [fin_midi, setFin_midi] = useState('00:00')
    const [debut_midi, setDebut_midi] = useState('00:00')
    const [fin, setFin] = useState('00:00')
    const [etat, setEtat] = useState('Présent')
    const [current_presence, setCurrent_presence] = useState('')

    const onChangeEtat = (e: FormEvent<HTMLInputElement>) => {
        setEtat(e.currentTarget.value)
    }

    const getPresenceAllCollaborator = async () => {
        /* service.getAllPresenceCollaborator(token)
            .then(response => {
                setState({
                    suivis: response.data,
                    show : false,
                    shownbr : false
                });
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.Prestance.getAllPresenceCollaborator(token)
            setSuivis(response.data)
            setShow(false)
            setShownbr(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const getAllCollaborator = async () => {
      /* service.getAllCollaborator(token)
          .then(response => {
              setState({
                  collaborators: response.data,
              });
          })
          .catch(e => {
              console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getAllCollaborator(token)
            setCollaborators(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
  
    const updatePresenceAdmin = async (id_presence: string) => {
        const data = {
          etat,
          debut,
          fin_midi,
          debut_midi,
          fin
        }
        /* service
          .updatePresenceAdmin(id_presence, data, token)
          .then((response) => {
            setState({
              showUpdate: false
            });
            getAllCollaborator();
            getPresenceAllCollaborator();
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            await API.Prestance.updatePresenceAdmin(id_presence, data, token)
            setShowUpdate(false)
            await getAllCollaborator()
            await getPresenceAllCollaborator()
        }
        catch (error) {
            console.log(error)
        }
    }
  
    const onChangeDebut = (e: FormEvent<HTMLInputElement>) => {
        setDebut(e.currentTarget.value)
    }

    const onChangeFinMidi = (e: FormEvent<HTMLInputElement>) => {
        setFin_midi(e.currentTarget.value)
    }

    const onChangeDebutMidi = (e: FormEvent<HTMLInputElement>) => {
        setDebut_midi(e.currentTarget.value)
    }

    const onChangeFin = (e: FormEvent<HTMLInputElement>) => {
        setFin(e.currentTarget.value)
    }
  
    const handleShowUpdate = (presence: any) => {
        /* setState({
          showUpdate: true,
          current_presence : presence.id,
          debut : presence.debut,
          fin_midi : presence.fin_midi,
          debut_midi : presence.debut_midi,
          fin : presence.fin,
          date_created : Moment(presence.createdAt).format('LL')
        }); */
        setShowUpdate(true)
        setCurrent_presence(presence.id)
        setDebut(presence.debut)
        setFin_midi(presence.fin_midi)
        setDebut_midi(presence.debut_midi)
        setFin(presence.fin)
        setDate_created(Moment(presence.createdAt).format('LL'))
    }
  
    const handleCloseUpdate = () => {
        setShowUpdate(false)
    }
  
    const onSearchTwoDate = async (nom: string, date_start: string, date_end: string) => {
        if(nom === "" && date_start === "" && date_end === "") 
            await getPresenceAllCollaborator();
        else if(nom !== "" && date_start !== "" && date_end !== "") {
          /* service.searchMultipleTwoDateAllColaborateur(nom, date_start, date_end, token)
          .then(response => {
            setState({
              suivis: response.data,
              show : false,
              shownbr : true
            });
          })
          .catch(e => {
            console.log(e);
          }); */
            try {
                const response = await API.Prestance.searchMultipleTwoDateAllColaborateur(nom, date_start, date_end, token)
                setSuivis(response.data)
                setShow(false)
                setShownbr(true)
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            /* service.searchMultipleTwoDateAllColaborateur(nom, date_start, date_end, token)
          .then(response => {
            setState({
              suivis: response.data,
              show : false,
              shownbr : false
            });
          })
          .catch(e => {
            console.log(e);
          }); */
            try {
                const response = await API.Prestance.searchMultipleTwoDateAllColaborateur(nom, date_start, date_end, token)
                setSuivis(response.data)
                setShow(false)
                setShownbr(false)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
  
    const onSearchSuivi = async (nom: string, mois: string) => {
        if(nom === "" && mois === "" ){
            await getPresenceAllCollaborator();
        }
        else if(nom !== "" && mois !== "" ){
            /* service.searchMultiplePresenceAllColaborateur(nom, mois ,token)
              .then(response => {
                setState({
                  suivis: response.data,
                  show : false,
                  shownbr : true
                });
              })
              .catch(e => {
                console.log(e);
              }); */
            try {
                const response = await API.Prestance.searchMultiplePresenceAllColaborateur(nom, mois, token)
                setSuivis(response.data)
                setShow(false)
                setShownbr(true)
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
          /* service.searchMultiplePresenceAllColaborateur(nom, mois ,token)
            .then(response => {
              setState({
                suivis: response.data,
                show : false,
                shownbr : false
              });
            })
            .catch(e => {
              console.log(e);
            }); */
            try {
                const response = await API.Prestance.searchMultiplePresenceAllColaborateur(nom, mois, token)
                setSuivis(response.data)
                setShow(false)
                setShownbr(false)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    
    const handleClose = () => {
        setShow(false)
    }
  
    const handleShow = () => {
        setShow(true)
    }
  
    const handleShowListeCol = () => {
        setShowCol(true)
    }

    const handleCloseListeCol = () => {
        setShowCol(false)
    }
      
    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value)
        if(e.currentTarget.value === "")
            await getAllCollaborator()   
        else{
            /* service.searchCollaboratorPresence(searchTitle, token)
            .then(response => {
                setState({
                collaborators: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            }); */
            try {
                const response = await API.Prestance.searchCollaboratorPresence(searchTitle, token)
                setCollaborators(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
  
    const hoursStringToDecimal = (hoursString: string) => {
        if (hoursString) {
          const [hoursPart, minutesPart] = hoursString.split(":");
          return Number(hoursPart) + Number(minutesPart) / 60;
        }
        return 0
    }
  
    const decimalHoursToString = (hoursDecimal: number) => {
        const numHours = Math.floor(hoursDecimal);
        const numMinutes = Math.round((hoursDecimal - numHours) * 60);
        return `${numHours < 10 ? "0" : ""}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
  
    useEffect(() => {
        Moment.locale('fr');
        (async () => {
            await getAllCollaborator()
            await getPresenceAllCollaborator()
        })()
    }, [])

    return (
        <div>
          <div className="content-wrapper bg-white" style={{backgroundImage: `url(${background})`,
      backgroundSize: "100% 100%",
      backgroundRepeat: "no-repeat"}} >
          <PageHead title="Présence Collaborateur" subject="Liste des présences" name="Présence"/>
          <div className="searchbar-container">
              { !showCol ? 
              <div className="form-inline">
                  <div className="input-group input-group-sm">
                    
                  <Button onClick={handleShowListeCol} className="universal-length mbtn">
                      <i className="fas fa-"></i> <strong>Liste Collaborateurs</strong>
                      </Button>
                      </div>
                  <div className="button-action">
                      {/* <Link to="/Registration"> */}
                      {/* <Button className="universal-length mbtn" block type="submit">
                          <i class="fas fa-plus-circle"></i> <strong>Ajouter un candidat</strong>
                      </Button> */}
                      <Button onClick={handleShow} className="universal-length mbtn">
                      <i className="fas fa-search"></i> <strong>Filtres</strong>
                      </Button>
                      {/* </Link> */}
                  </div>
              </div>
                :
                <div className="form-inline">
                <div className="input-group input-group-sm">
                       <Button onClick={handleCloseListeCol} className="universal-length mbtn">
                       <i className="fas fa-"></i> <strong>Liste Présences</strong>
                       </Button>
                  </div>
                  <div className="input-group input-group-sm" title="Vous pouvez chercher des collaborateurs par nom ou matricule">
                  <span className="universal-length icon-r">
                    <i className="fas fa-search"></i>
                  </span>{" "}
                  <input
                    className="form-control form-control-navbar universal-length recherche col-9"
                    value={searchTitle}
                    onChange={onChangeSearchTitle}
                    type="search"
                    placeholder="Rechercher"
                    aria-label="Search"
                  />
                </div>
                  </div>
                  }

           </div>   
          <div className="sep"></div>
          <div className="sep"></div>
          { !showCol ? 
            <div className="row userList">
            <div className="table-responsive">
              <table className="table">
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Société</th>
                    <th scope="col">Matricule</th>
                    <th scope="col">Nom</th>
                    <th scope="col">Etat</th>
                    <th scope="col">Entrée</th>
                    <th scope="col">Sortie midi</th>
                    <th scope="col">Entrée midi</th>
                    <th scope="col">Sortie</th>
                    <th scope="col">Total heure</th>
                    {role === "admin" ? (
                    <th scope="col">Action</th>
                    ): null }
                  </tr>
                </thead>
                <tbody>
                {suivis.map((present) => { 
                        const cts = present.createdAt;
                        const lastRapport = Moment(cts).format('LL')
                        // present 
                        if(present.etat === "Présent"){
                          nbrPresent += 1
                        }
                        // maladie
                        if(present.etat === "Malade"){
                          nbrMaladie += 1
                        }
                        // maladie
                        if(present.etat === "Congé"){
                          nbrConge += 1
                        }
                        const {start} = present
                        const finmidi = present.fin_midi
                        const debutHour = hoursStringToDecimal(start)
                        const finhour = hoursStringToDecimal(finmidi)
                        const debutmidi = present.debut_midi
                        const {end} = present
                        const debutmidiHour = hoursStringToDecimal(debutmidi)
                        const finmidihour = hoursStringToDecimal(end)
                        const sommematinHoure = finhour - debutHour
                        const sommeMidiHoure = finmidihour - debutmidiHour
                        let sommeTotalHour = 0.0
                        let TotalHour = '0.0'
                        if(finmidi === "00:00"){
                          TotalHour = decimalHoursToString(0.0)
                          sommeTotalHour = 0.0
                        }
                        else if(fin === "00:00"){
                          sommeTotalHour = sommematinHoure
                          TotalHour = decimalHoursToString(sommematinHoure)
                        }
                        else{
                          sommeTotalHour = sommematinHoure + sommeMidiHoure
                          TotalHour = decimalHoursToString(sommeTotalHour)
                        }
                        sumHour += sommeTotalHour;
                      return (
                        <tr>
                          <td>{lastRapport}</td>
                          <td>{present.client.username}</td>
                          <td>{present.user.matricule}</td>
                          <td>{present.user.username}</td>
                          <td>{present.etat}</td>
                          <td>{present.debut}</td>
                          <td>{present.fin_midi}</td>
                          <td>{present.debut_midi}</td>
                          <td>{present.fin}</td>
                          <td>{TotalHour}</td>
                          {role === "admin" ? (
                          <td>
                            <div className="row r-favori">
                              <div className="col-12" title="Modifier">
                                <Button
                                  className="universal-length mbtn btn-favori"
                                  onClick={() => handleShowUpdate(present)}
                                >
                                  <strong>
                                    <i className="fas fa-edit"></i>{" "}
                                  </strong>
                                </Button>
                              </div>

                            </div>
                          </td>
                          ): null }
                        </tr>
                      );
                    })}
                    {shownbr ?
                    <tr>
                    <tr>
                      <th>Total heures du travail</th>
                      <td>{decimalHoursToString(sumHour)}</td>
                    </tr>
                    <tr>
                      <th>Nombre de jours de présences</th>
                      <td>{nbrPresent}</td>
                    </tr>
                    <tr>
                      <th>Nombre de jours de Maladie</th>
                      <td>{nbrMaladie}</td>
                    </tr>
                    <tr>
                      <th>Nombre de jours de congé</th>
                      <td>{nbrConge}</td>
                    </tr>
                    </tr>
                    : null
                    }
                </tbody>
              </table>
            </div>
          </div>
           :
          <div className="row userList">
          <div className="col-12">
            <table className="table">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Matricule</th>
                  <th scope="col">Pseudo</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <tbody>
                {collaborators.map((user) => (
                      <tr>
                        <td align="center">{user.matricule}</td>
                        <td>{user.username}</td>
                        <td>
                          <Link
                            to={
                              `/PresenceAdmin/${ user.id}` 
                            }
                          >
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a className="small-box-footer">
                              voir le(s) client(s){" "}
                              <i className="fas fa-arrow-circle-right" />
                            </a>
                          </Link>
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        }
            <Modal className="my-modal" animation={false} show={show} onHide={handleClose}>
          {/* <Modal.Header closeButton> */}
          {/* <Modal.Title></Modal.Title> */}
          {/* </Modal.Header> */}

          <button type="button" onClick={handleClose} className="close">
            <i className="fas fa-times"></i>
            <span className="sr-only">Close</span>
          </button>
          <Modal.Body>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <BoxSearchPresence actionSearch ={onSearchSuivi} collaborator={collaborators} actionSearchTwoDate={onSearchTwoDate}/>
              </div>
            </div>
          </Modal.Body>
          </Modal>
          </div>
          <Modal className="my-modal" animation={false} show={showUpdate} onHide={handleCloseUpdate} style={{ width: "60%", padding: "10px", margin:"0 auto" }}>
            <button type="button" onClick={handleCloseUpdate} className="close" style={{ position: "initial" }}>
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: "5%" }}>
                <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="poste">Présence du {date_created}</label>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label>
                              <input
                                type="radio"
                                value="Présent"
                                checked={etat === "Présent"}
                                onChange={onChangeEtat}
                              />
                              Présent
                            </label>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label>
                              <input
                                type="radio"
                                value="Absent"
                                checked={etat === "Absent"}
                                onChange={onChangeEtat}
                              />
                              Absent
                            </label>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="form-group">
                            <label>
                              <input
                                type="radio"
                                value="Absent non justifier"
                                checked={
                                  etat === "Absent non justifier"
                                }
                                onChange={onChangeEtat}
                              />
                              Absent non justifier
                            </label>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label>
                              <input
                                type="radio"
                                value="Malade"
                                checked={etat === "Malade"}
                                onChange={onChangeEtat}
                              />
                              Maladie
                            </label>
                          </div>
                        </div>
                        <div className="col-2">
                          <div className="form-group">
                            <label>
                              <input
                                type="radio"
                                value="Congé"
                                checked={etat === "Congé"}
                                onChange={onChangeEtat}
                              />
                              Congé
                            </label>
                          </div>
                        </div>
                      </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: "5%" }}>
                <div className="row">
                  <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="debut">Début</label>
                      <TimeField value={debut} onChange={onChangeDebut} />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="fin_midi">Fin midi</label>
                      <TimeField value={fin_midi} onChange={onChangeFinMidi} />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="debut_midi">Debut midi</label>
                      <TimeField value={debut_midi} onChange={onChangeDebutMidi} />
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="form-group">
                      <label htmlFor="fin">Fin Midi</label>
                      <TimeField value={fin} onChange={onChangeFin} />
                    </div>
                  </div>
                  </div>
                  </div>
                </div>
              </div>
              <div>
                <button className="w-100 btn-register btn btn-success" onClick={() => updatePresenceAdmin(current_presence)} type="submit">
                  <strong><i className="fas fa-edit"></i> Enregistrer </strong>
                </button>
              </div>
            </Modal.Body>
          </Modal>
          </div>       
    )
}

export default PresenceCollaboratorPerDay
import React, { useState, useEffect, FormEvent } from 'react'
import { Modal } from "react-bootstrap";
import TimeField from 'react-simple-timefield';
import Moment from "moment";
import "moment/locale/fr";
import API from '../services/API/API'


import "../styles/styles.css";

interface IParams {
    id: string
    id_user: string
}

interface IMatch {
    params: IParams
}

// eslint-disable-next-line no-unused-vars
interface IProps {
    match: IMatch
}

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

const DoPresenceAdmin = ({ match }: IProps) => {
    const today =  new Date();
    const presenceDay = Moment(today).format("LL");
    const [client_id,] = useState(match.params.id)
    const [id_user,] = useState(match.params.id_user)
    const [suivis, setSuivis] = useState<Array<any>>([])
    const [show, setShow] = useState(false)
    const [start, setDebut] = useState('00:00')
    const [fin_midi, setFin_midi] = useState('00:00')
    const [debut_midi, setDebut_midi] = useState('00:00')
    const [end, setFin] = useState('00:00')
    const [mypresences, setMypresences] = useState<Array<any>>([])
    const [date_created, setDate_created] = useState('')
    /* const presences = [
        { name: "Présent" },
        { name: "Absent" },
        { name: "Absent non justifier" },
        { name: "Malade" },
        { name: "Congé" },
    ] */
    const rowmois = [
        { id: "1", name: "Janvier" },
        { id: "2", name: "Février" },
        { id: "3", name: "Mars" },
        { id: "4", name: "Avril" },
        { id: "5", name: "Mai" },
        { id: "6", name: "Juin" },
        { id: "7", name: "Juillet" },
        { id: "8", name: "Aout" },
        { id: "9", name: "Séptembre" },
        { id: "10", name: "Octobre" },
        { id: "11", name: "Novembre" },
        { id: "12", name: "Décembre" },
    ]
    /* const rowhours = [
        { id: "00:00", name: "Janvier" },
        { id: "00:30", name: "Février" },
        { id: "01:00", name: "Mars" },
        { id: "01:30", name: "Avril" },
        { id: "02:00", name: "Mai" },
        { id: "6", name: "Juin" },
        { id: "7", name: "Juillet" },
        { id: "8", name: "Aout" },
        { id: "9", name: "Séptembre" },
        { id: "10", name: "Octobre" },
        { id: "11", name: "Novembre" },
        { id: "12", name: "Décembre" },
    ] */
    const [mois, setMois] = useState('')
    const [etat, setEtat] = useState('Absent')
    const [current_presence, setCurrent_presence] = useState('')

    const handleShow = (presence: any) => {
        setShow(true)
        setCurrent_presence(presence.id)
        setDebut(presence.debut)
        setFin_midi(presence.fin_midi)
        setDebut_midi(presence.debut_midi)
        setFin(presence.fin)
        setDate_created(Moment(presence.createdAt).format('LL'))
    }

    const handleClose = () => {
        setShow(false)
    }

    const onChangeEtat = (e: FormEvent<HTMLInputElement>) => {
        setEtat(e.currentTarget.value)
    }

    const onChangeDebut = (e: FormEvent<HTMLInputElement>) => {
        setDebut(e.currentTarget.value)
    }

    const onChangeFinMidi = (e: FormEvent<HTMLInputElement>) => {
        setFin_midi(e.currentTarget.value)
    }

    const onChangeDebutMidi = (e: FormEvent<HTMLInputElement>) => {
        setDebut_midi(e.currentTarget.value)
    }

    const onChangeFin = (e: FormEvent<HTMLInputElement>) => {
        setFin(e.currentTarget.value)
    }

    const getPresenceCollaborator = async () => {
        /* service
          .getPresenceCollaborator(id_user, client_id, token)
          .then((response) => {
            setState({
              suivis: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Prestance.getPresenceCollaborator(id_user, client_id, token)
            setSuivis(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getMyPresenceCollaborator = async () => {
        /* service
          .getMyPresenceCollaborator(
            id_user,
            client_id,
            token
          )
          .then((response) => {
            setState({
              mypresences: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Prestance.getMyPresenceCollaborator(id_user, client_id, token)
            setMypresences(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const onChangeMois = async (e: FormEvent<HTMLSelectElement>) => {
        setMois(e.currentTarget.value)
        if (e.currentTarget.value === "") {
            await getMyPresenceCollaborator();
        } else {
          /* service
            .getMyPresenceParMois(
              id_user,
              client_id,
              mois,
              token
            )
            .then((response) => {
              setState({
                mypresences: response.data,
              });
              console.log(response.data);
            })
            .catch((e) => {
              console.log(e);
            }); */
            try {
                const response = await API.Prestance.getMyPresenceParMois(id_user, client_id, mois, token)
                setMypresences(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const addPresence = async () => {
        const data = {
          client_id,
          id_user,
          etat,
        };
        /* service
          .addPresence(data, token)
          .then((response) => {
            setState({});
            getPresenceCollaborator();
            getMyPresenceCollaborator();
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            await API.Prestance.addPresence(data, token)
            await getPresenceCollaborator()
            await getMyPresenceCollaborator()
        }
        catch (error) {
            console.log(error)
        }
    }

    const updatePresenceAdmin = async (id_presence: string) => {
        const data = {
          etat,
          debut: start,
          fin_midi,
          debut_midi,
          fin: end
        }
        /* service
          .updatePresenceAdmin(id_presence, data, token)
          .then((response) => {
            setState({
              show: false
            });
            getPresenceCollaborator();
            getMyPresenceCollaborator();
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            await API.Prestance.updatePresenceAdmin(id_presence, data, token)
            setShow(false)
            await getPresenceCollaborator()
            await getMyPresenceCollaborator()
        }
        catch (error) {
            console.log(error)
        }
    }

    const hoursStringToDecimal = (hoursString: string) => {
        const [hoursPart, minutesPart] = hoursString.split(":");
        return Number(hoursPart) + Number(minutesPart) / 60;
    }
    
    const decimalHoursToString = (hoursDecimal: number) => {
        const numHours = Math.floor(hoursDecimal);
        const numMinutes = Math.round((hoursDecimal - numHours) * 60);
        return `${numHours < 10 ? "0" : ""}${numHours}:${
          numMinutes < 10 ? "0" : ""
        }${numMinutes}`;
    }
    
    useEffect(() => {
        (async () => {
            await getPresenceCollaborator()
            await getMyPresenceCollaborator()
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            {/* /.content-header */}
            {/* Main content */}
  
            <section className="content">
              <div>
                {/* Small boxes (Stat box) */}
                <div className="row">
                  {/* USER INFO */}
                  <div className="box-item detail">
                    {/* small box */}
                    <div className="">
                      {!suivis.length ? (
                        <div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="poste">Présence du {presenceDay}</label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Absent"
                                    checked={etat === "Absent"}
                                    onChange={onChangeEtat}
                                  />
                                  Absent
                                </label>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Absent non justifier"
                                    checked={
                                      etat === "Absent non justifier"
                                    }
                                    onChange={onChangeEtat}
                                  />
                                  Absent non justifier
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Malade"
                                    checked={etat === "Malade"}
                                    onChange={onChangeEtat}
                                  />
                                  Maladie
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Congé"
                                    checked={etat === "Congé"}
                                    onChange={onChangeEtat}
                                  />
                                  Congé
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <button
                                type="button"
                                onClick={addPresence}
                                className="w-20 btn btn-success"
                              >
                                Enregistrer
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="sep"></div>
                      <div className="sep"></div>
                      <div className="row userList">
                        <div className="table-responsive">
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Matricule</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Etat</th>
                                <th scope="col">Entrée</th>
                                <th scope="col">Sortie</th>
                                <th scope="col">Entrée</th>
                                <th scope="col">Sortie</th>
                                <th scope="col">Total heure</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {suivis.map((suivi: any) => suivi.presences.map((present: any) => {
                                  const cts = present.createdAt;
                                  const lastRapport = Moment(cts).format("LL");
                                  const { debut } = present;
                                  const finmidi = present.fin_midi;
                                  const debutHour =
                                    hoursStringToDecimal(debut);
                                  const finhour =
                                    hoursStringToDecimal(finmidi);
                                  const debutmidi = present.debut_midi;
                                  const { fin } = present;
                                  const debutmidiHour =
                                    hoursStringToDecimal(debutmidi);
                                  const finmidihour =
                                    hoursStringToDecimal(fin);
                                  const sommematinHoure = finhour - debutHour;
                                  const sommeMidiHoure =
                                    finmidihour - debutmidiHour;
                                  const sommeTotalHour =
                                    sommematinHoure + sommeMidiHoure;
                                  let TotalHour = '0.0';
                                  if (finmidi === "00:00") {
                                    TotalHour = decimalHoursToString(0.0);
                                  } else if (fin === "00:00") {
                                    TotalHour =
                                      decimalHoursToString(sommematinHoure);
                                  } else {
                                    TotalHour =
                                      decimalHoursToString(sommeTotalHour);
                                  }
                                  return (
                                    <tr>
                                      <td>{lastRapport}</td>
                                      <td>{suivi.matricule}</td>
                                      <td>{suivi.username}</td>
                                      <td>{present.etat}</td>
                                      <td>{present.debut}</td>
                                      <td>{present.fin_midi}</td>
                                      <td>{present.debut_midi}</td>
                                      <td>{present.fin}</td>
                                      <td>{TotalHour}</td>
                                      <td>
                                              {" "}
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleShow(present)
                                                }
                                              >
                                                Modifier
                                              </button>
                                        </td>
                                    </tr>
                                  );
                                }))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sep"></div>
                <h3 style={{ textAlign: 'center' }}>
                  <b style={{ color: "white" }}>Présences</b>
                </h3>
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="mois">
                      <b style={{ color: "white" }}> Recherche par mois : </b>
                    </label>
                    <select
                      required
                      style={{ width: "15%" }}
                      value={mois}
                      onChange={onChangeMois}
                      placeholder="Choisir Votre Présence"
                    >
                      <option value=""></option>
                      {rowmois.map((month: any) => (
                        <option value={month.name}>{month.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row userList">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Matricule</th>
                          <th scope="col">Nom</th>
                          <th scope="col">Etat</th>
                          <th scope="col">Entrée</th>
                          <th scope="col">Sortie</th>
                          <th scope="col">Entrée</th>
                          <th scope="col">Sortie</th>
                          <th scope="col">Total heure</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mypresences.map((suivi: any) => suivi.presences.map((present: any) => {
                            const cts = present.createdAt;
                            const lastRapport = Moment(cts).format("LL");
                            const { debut } = present;
                            const finmidi = present.fin_midi;
                            const debutHour = hoursStringToDecimal(debut);
                            const finhour = hoursStringToDecimal(finmidi);
                            const debutmidi = present.debut_midi;
                            const { fin } = present;
                            const debutmidiHour =
                              hoursStringToDecimal(debutmidi);
                            const finmidihour = hoursStringToDecimal(fin);
                            const sommematinHoure = finhour - debutHour;
                            const sommeMidiHoure = finmidihour - debutmidiHour;
                            const sommeTotalHour = sommematinHoure + sommeMidiHoure;
                            let TotalHour = '0.0';
                            if (finmidi === "00:00") {
                              TotalHour = decimalHoursToString(0.0);
                            } else if (fin === "00:00") {
                              TotalHour =
                                decimalHoursToString(sommematinHoure);
                            } else {
                              TotalHour =
                                decimalHoursToString(sommeTotalHour);
                            }
                            return (
                              <tr>
                                <td>{lastRapport}</td>
                                <td>{suivi.matricule}</td>
                                <td>{suivi.username}</td>
                                <td>{present.etat}</td>
                                <td>{present.debut}</td>
                                <td>{present.fin_midi}</td>
                                <td>{present.debut_midi}</td>
                                <td>{present.fin}</td>
                                <td>{TotalHour}</td>
                                <td>
                                              {" "}
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleShow(present)
                                                }
                                              >
                                                Modifier
                                              </button>
                                        </td>
                              </tr>
                            );
                          }))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Modal className="my-modal" animation={false} show={show} onHide={handleClose} style={{ width: "60%", padding: "10px", margin:"0 auto" }}>
                <button type="button" onClick={handleClose} className="close" style={{ position: "initial" }}>
                  <i className="fas fa-times"></i>
                  <span className="sr-only">Close</span>
                </button>
                <Modal.Body>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: "5%" }}>
                    <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="poste">Présence du {date_created}</label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Présent"
                                    checked={etat === "Présent"}
                                    onChange={onChangeEtat}
                                  />
                                  Présent
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Absent"
                                    checked={etat === "Absent"}
                                    onChange={onChangeEtat}
                                  />
                                  Absent
                                </label>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Absent non justifier"
                                    checked={
                                      etat === "Absent non justifier"
                                    }
                                    onChange={onChangeEtat}
                                  />
                                  Absent non justifier
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Malade"
                                    checked={etat === "Malade"}
                                    onChange={onChangeEtat}
                                  />
                                  Maladie
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Congé"
                                    checked={etat === "Congé"}
                                    onChange={onChangeEtat}
                                  />
                                  Congé
                                </label>
                              </div>
                            </div>
                          </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: "5%" }}>
                    <div className="row">
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="debut">Début</label>
                          <TimeField value={start} onChange={onChangeDebut} />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="fin_midi">Fin midi</label>
                          <TimeField value={fin_midi} onChange={onChangeFinMidi} />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="debut_midi">Debut midi</label>
                          <TimeField value={debut_midi} onChange={onChangeDebutMidi} />
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="form-group">
                          <label htmlFor="fin">Fin Midi</label>
                          <TimeField value={end} onChange={onChangeFin} />
                        </div>
                      </div>
                      </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button className="w-100 btn-register btn btn-success" onClick={() => updatePresenceAdmin(current_presence)} type="submit">
                      <strong><i className="fas fa-edit"></i> Enregistrer </strong>
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
        </div>
    )
}

export default DoPresenceAdmin
import React, { useState, useEffect } from 'react'
import API from '../services/API/API'
import PageHead from '../components/pageHead'
import BoxItem from '../components/boxItem'

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

const FavorisClient = () => {
    const role = sessionStorage.getItem('role') ?? '';
    const [favorites, setFavorites] = useState<Array<any>>([])
    const [id_user, ] = useState(sessionStorage.getItem('id') ?? '')

    const retrieveFavorites = async () => {
        /* service.getFavorites(this.state.id_user, token)
          .then(response => {
            this.setState({
              favorites: response.data
            });
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Favorite.getFavorites(id_user, token)
            setFavorites(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const deleteFavorites = async (id: string, id_candidat: string) => {
        /* service.deleteFavorites(id, id_candidat, token)
          .then(response => {
            this.retrieveFavorites()
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            await API.Favorite.deleteFavorites(id, id_candidat, token)
            await retrieveFavorites()
        }
        catch (error)  {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveFavorites()
        })()
    }, [])

    return (
        <div>
          <div className="content-wrapper" style={{backgroundImage: `url(${background})`,
       backgroundSize: "100% 100%",
       backgroundRepeat: "no-repeat"}}>
             <PageHead title="Mes favoris" subject="Liste des favoris" name="Favoris" />
            <section className="content">
              <div>
                <div className="row">
                  {favorites.map((favoris) => favoris.customers.map((candidat: any) => 
                      // const images = candidat.files.slice(1, 2)
                       (
                        <BoxItem mode="delete" ok="client" user={id_user} roleProps={role} candidat={candidat} actionFavorite={deleteFavorites} />
                      )
                    ))}
                </div>
              </div>
            </section>
          </div>
        </div>
    )
}

export default FavorisClient
import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from "react-bootstrap";
// eslint-disable-next-line no-irregular-whitespace
import swal from 'sweetalert';
import API from "../services/API/API";
import PageHead from "../components/pageHead";

const background = sessionStorage.getItem('background')
const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};

const UserRegister = () => {
    const role = sessionStorage.getItem('role');
    const [, setId] = useState<any>(null)
    const [id_user,] = useState<any>(sessionStorage.getItem('id'))
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [matricule, setMatricule] = useState('')
    const [roles, setRoles] = useState<Array<any>>([])
    const [selectedValue, setSelectedValue] = useState('')
    const [utilisateurs, setUtilisateurs] = useState<Array<any>>([])
    const [, setSubmitted] = useState(false)
    const [show, setShow] = useState(false)
    const [searchTitle, setSearchTitle] = useState('')

    const retrieveUsers = async () => {
        /* service.getAllUsers(id_user, token)
          .then(response => {
            setState({
              utilisateurs: response.data
            });
            //console.log(" -------------------------------- " + response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.User.getAllUsers(id_user, token)
            setUtilisateurs(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value)
        if (e.currentTarget.value === "") {
            await retrieveUsers();
        } else {
          /* service
            .findByNameOrMatricule(searchTitle, token)
            .then((response) => {
              setState({
                utilisateurs: response.data
              });
              //console.log(response.data);
            })
            .catch((e) => {
              console.log(e);
            }); */
            try {
                const response = await API.CollaboratorEvaluation.findByNameOrMatricule(searchTitle, token)
                setUtilisateurs(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    
    
    const onChangeMatricule = (e: FormEvent<HTMLInputElement>) => {
        setMatricule(e.currentTarget.value)
    }

    const onChangeUsername = (e: FormEvent<HTMLInputElement>) => {
        setUsername(e.currentTarget.value)
    }

    const handleShow = () => {
        setShow(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    const onChangeSelected = (e: FormEvent<HTMLSelectElement>) => {
        setSelectedValue(e.currentTarget.value)
    }
    
    const onChangeEmail = (e: FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)
    }
    
    const onChangePassword = (e: FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value)
    }

    /* const onChangePasswordUpdate = (e: FormEvent<HTMLInputElement>) => {
        setPasswordUpdate(e.currentTarget.value)
    } */
    
    const activer = async (user_idP: string) => {
        /* service
          .enableUser(user_id, token)
          .then((response) => {
            retrieveUsers();
          })
          .catch((e) => {
            alert(e);
            console.log(e);
          }); */
        try {
            await API.CollaboratorEvaluation.enableUser(user_idP, token)
            await retrieveUsers()
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const desactiver = async (user_idP: string) => {
        /* service
          .desableUser(user_id, token)
          .then((response) => {
            retrieveUsers();
          })
          .catch((e) => {
            alert(e);
            console.log(e);
          }); */
        try {
            await API.CollaboratorEvaluation.desableUser(user_idP, token)
            await retrieveUsers()
        }
        catch (error) {
            console.log(error)
        }
    }
      
    const updatePassword = async (user_idP: string) => {
        /* swal("Entrez le nouveau mot de psse:", {
            content: "input",
            buttons: true,
        } as any)
        .then((value) => {
          if (value) {
          var data ={
            password : value
          }
          service
          .updatePassword(user_id, data, token)
          .then((response) => {
            retrieveUsers();
          })
          .catch((e) => {
            alert(e);
            console.log(e);
          });
        }
        else{
          retrieveUsers();
        }
        }); */
        try {
            const value = await swal("Entrez le nouveau mot de psse:", {
                content: "input",
                buttons: true,
            } as any)

            if (value) {
                const data = {
                    password: value
                }
                await API.User.updatePassword(user_idP, data, token)
                await retrieveUsers()
            }
            else await retrieveUsers()
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const saveUser = async (matriculeP: string, usernameP: string, emailP: string, passwordP: string, selectedValueP: string) => {
        const data = {
          username: usernameP,
          email: emailP,
          matricule: matriculeP,
          password: passwordP,
          roles: [...roles, selectedValueP]
        };
        console.log(data)
        /* service.signup(data)
          .then(response => {
            setState({
              id: response.data.id,
              username: response.data.username,
              email: response.data.email,
              password: response.data.password,
              roles: response.data.roles,
              show: false,
              submitted: true
            });
            retrieveUsers();
          })
          .catch(e => {
            alert(e)
            console.log(e);
          }); */
        try {
            const response = await API.User.signup(data)
            setId(response.data.id)
            setUsername(response.data.username)
            setEmail(response.data.email)
            setPassword(response.data.password)
            setRoles(response.data.roles)
            setShow(false)
            setSubmitted(true)

            await retrieveUsers()
        }
        catch (error) {
            console.log(error)
        }
    }
      
    /* const newUser = () => {
        setId('')
        setUsername('')
        setEmail('')
        setPassword('')
        setMatricule('')
        setRoles([])
        setSelectedValue('')
        setSubmitted(false)
    } */

    useEffect(() => {
        (async () => {
            await retrieveUsers()
        })()
    })

    return (
        <div >
          <div className="content-wrapper bg" style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%"
          }} >
            {/* TITLE & BREADCRUMBS */}
            {role !== "user" && role !== "collaborator" ? (
              <PageHead title="Utilisateurs" subject="Liste des utilisateurs" name="Utilisateur" />
  
            ) : null}
  
            {/* SEARCH FORM */}
            {role !== "user" && role !== "collaborator" ? (
              <div className="action-container">
                              <div className="form-inline">
                  <div className="input-group input-group-sm" title="Vous pouvez faire un recherche par nom, matricule">
                    <span className="universal-length icon-r">
                      <i className="fas fa-search"></i>
                    </span>{" "}
                    <input
                      className="form-control form-control-navbar universal-length recherche col-10"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                      type="search"
                      placeholder="Rechercher"
                      aria-label="Search"
                    />
                  </div>
                  <div className="button-action" title="Vous pouvez ajouter un candidat">
  
                    <Button
                      onClick={handleShow}
                      className="universal-length mbtn"
                      type="submit"
                    >
                      <i className="fas fa-plus-circle"></i>{" "}
                      <strong>Ajouter un utilisateur</strong>
                    </Button>
                  </div>
                  </div>
              </div>
            ) : null}
  
            {/* END SEARCH FORM */}
            <section className="content">
              <div>
                {role !== "user" && role !== "collaborator" ? (
                  <div className="table-responsive">
                    <table className="table table-curved">
                      <thead >
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Username</th>
                          <th scope="col">Matricule</th>
                          <th scope="col">E-mail</th>
                          {role === "admin" ? <th scope="col">Mot de passe</th> : null }
                          <th scope="col">Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        
                        {utilisateurs.map((user) => (
                          <tr>
                            <td> {user.id} </td>
                            <td> {user.username} </td>
                            <td> {user.matricule} </td>
                            <td> {user.email} </td>
                           {role === "admin" ?<td> {user.passwordVisible} 
                            <Button
                                    className="universal-length" style={{width: "50%"}}
                                    onClick={() => updatePassword(user.id)}
                                  >
                                    <strong>
                                      <i className="fas fa-edit"></i>Modifier
                                    </strong>
                                  </Button>
                                  </td>
                                  : null
                                } 
                            <td>
                              <div className="row r-favori">
                                <div className="col-12" title="Modifier">
      
                                  {user.active === 1 ? (
                                  <Button
                                    className="universal-length btn-danger"
                                    onClick={() => desactiver(user.id)}
                                  >
                                    <strong>
                                      <i className="fas fa-delete"></i>Désactiver
                                    </strong>
                                  </Button>
                                  ):(
                                  <Button
                                    className="universal-length btn-success"
                                    onClick={() => activer(user.id)}
                                  >
                                    <strong>
                                      <i className="fas fa-check"></i>Activer
                                    </strong>
                                  </Button>
                                  )}
                                </div>
  
                              </div>
                            </td>
                            
  
                          </tr>
                        ))}
                      </tbody>
                    </table>
  
  
                  </div>
  
                ) : (
                  <div>
                    <h1>Bienvenue sur notre plateforme</h1>
                  </div>
                )}
              </div>
            </section>
            
              <Modal className="my-modal" id="0" animation={false} show={show} onHide={handleClose} style={{ width: "60%", padding: "10px", margin:"0 auto" }}>
                <button type="button" onClick={handleClose} className="close" style={{ position: "initial" }}>
                  <i className="fas fa-times"></i>
                  <span className="sr-only">Close</span>
                </button>
                <Modal.Body>
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: "5%" }}>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="matricule">Matricule</label>
                          <input
                            type="number"
                            className="form-control"
                            id="matricule"
                            required
                            value={matricule}
                            onChange={onChangeMatricule}
                            name="matricule"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="pseudo">Nom & prénom(s)</label>
                          <input
                            type="text"
                            className="form-control"
                            id="pseudo"
                            required
                            value={username}
                            onChange={onChangeUsername}
                            name="pseudo"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="email">Email</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            required
                            value={email}
                            onChange={onChangeEmail}
                            name="email"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="password">Mot de passe</label>
                          <input
                            type="password"
                            className="form-control"
                            id="password"
                            required
                            value={password}
                            onChange={onChangePassword}
                            name="password"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <label htmlFor="password">Roles</label>
                          <select required className="form-control" value={selectedValue} onChange={onChangeSelected} >
                            <option value=""></option>
                            {role === 'admin'
                              ? <option value="admin">Administrateur</option>
                              : null
                            }
                            <option value="moderator">Equipe Olona</option>
                            <option value="collaborator">Collaborateur</option>
                          </select>
                        </div>
                      </div>
  
                      
                    </div>
                  </div>
                  <div>
                    <button className="w-100 btn-register btn btn-success" onClick={() => saveUser(matricule, username, email, password, selectedValue)} type="submit">
                      <strong><i className="fas fa-edit"></i> Enregistrer </strong>
                    </button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
        </div>
      )
}

export default UserRegister
import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import LoginContainer from './LoginRoute'
import RegisrationSpotaneousContainer from './RegistrationRoute'
import DefaultContainer from './Route'
import '../views/app/App.css';

const AppRouter = () => (
    <div className="wrapper">
      <BrowserRouter>
        <Switch>
        <Route exact path="/Login" component={LoginContainer}/>
        <Route exact path="/testevaluation" component={RegisrationSpotaneousContainer}/>
        <Route component={DefaultContainer}/>
        </Switch>
      </BrowserRouter>
    </div>
);

export default AppRouter
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useEffect, useState, FormEvent } from 'react';
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Modal } from 'react-bootstrap';
import * as emailjs from "emailjs-com";
import API from '../services/API/API';

const template_id = "template_hmyrfrd"
const serice_id = "service_0w314u9"
const user_id = "user_zOdi9xxPdyaZik1HKn38h"
/* const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
}; */

interface IPoste {
    name: string
}

interface IEvaluation {
    name: string
    note: number
}

interface ICandidate {
    id: string | number
    firstname: string
    lastname: string
    email: string
    postes: IPoste
    poste_precis: string
    status: string
    disponibilite: string
    note: number
    evaluations: Array<IEvaluation>
    prix_admin: string
    prix: string
    comment: string
    point_fort: string
    point_faible: string
}
 /* eslint-disable no-unused-vars */
type actionFavoriteFunction = (userId: string | number, candidat_id: string | number) => void

type actionAssignmentFunction = (userId: string | number, candidat_id: string | number) => void

type actionPrixFunction = (id: string | number, prixAdmin: string | number) => void
/* eslint-enable no-unused-vars */

/* eslint-disable react/require-default-props */
interface IProps {
    ok?: string
    mode?: string 
    roleProps: string | null
    assignement?: string
    candidat: ICandidate
    user: any
    actionFavorite?: any
    actionAssignment?: any
    actionPrix?: any
}

/* eslint-enable react/require-default-props */

/* interface ISendTo {
    from_name: string | null
    to_name: string
    message: string
    reply_to: string
} */

const BoxItem = ({ ok, mode, roleProps, assignement, candidat, user, actionAssignment, actionFavorite, actionPrix }: IProps) => {
    const [role, setRole] = useState<string>('')
    const [show, setShow] = useState(false)
    const [showModalRegister, setShowModalRegister] = useState(false)
    const [prix_admin, setPrixAdmin] = useState('')
    const [email, setEmail] = useState('')
    const [matricule, setMatricule] = useState('')
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState(candidat.firstname)
    const [sender, setSender] = useState('')
    const [roles] = useState(['collaborator'])

    useEffect(() => {
        const sessionUsername = sessionStorage.getItem('username')
        if (sessionUsername) setSender(sessionUsername)
        const r = sessionStorage.getItem('role')
        if (r) setRole(r)
    }, [])

    const onChangeUsername = (e: FormEvent<HTMLInputElement>) => {
        setUsername(e.currentTarget.value)
    }

    const handleFavorites = (userId: string | number, candidat_id: string | number) => {
        actionFavorite(userId, candidat_id);
    }

    const handleAssignments = (userId: string | number, candidat_id: string | number) => {
        actionAssignment(userId, candidat_id);
    }

    const handleUpdatePrix = (id: string | number, prixAdmin: string | number) => {
        actionPrix(id, prixAdmin);
    }

    const handleClose = () => {
        setShow(false)
    }

    /* const handleShow = () => {
        setShow(true)
    } */

    const handleCloseModalRegister = () => {
        setShowModalRegister(false)
    }

    const handleShowModalRegister = (e: FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        setShowModalRegister(true)
    }

    const onChangePrixAdmin = (e: FormEvent<HTMLInputElement>) => {
        setPrixAdmin(e.currentTarget.value)
    }

    /* const deleteCandidat = async (id: string | number) => {
        try {
            await API.Candidate.delete(id, token)
        }
        catch (error) {
            console.log(error)
        }
    } */
    const onChangeEmail = (e: FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)        
    }

    const onChangeMatricule = (e: FormEvent<HTMLInputElement>) => {
        setMatricule(e.currentTarget.value)
    }

    const onChangePassword = (e: FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value)
    }
    
    const saveUser = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        /* var data = {
          username: username,
          email: email,
          matricule: matricule,
          password: password,
          roles: roles
        };
        console.log(data)
        service.signup(data)
          .then(response => {
            handleAssignments(user, candidat.id)
            onSubmit(candidat.email, candidat.firstname)
          })
          .catch(e => {
            alert(e)
            console.log(e);
          });
        */
        const data = {
            username,
            email,
            matricule,
            password,
            roles
        }
        try {
            await API.User.signup(data)
            handleAssignments(user, candidat.id)
            onSubmit(candidat.email, candidat.firstname)
        }
        catch (error) {
            console.log(error)
        }
    }
      
    const onSubmit = async (mail: string, candidatName: string) => {
        const sendto = {
            from_name: '',
            to_name: '',
            message: '',
            reply_to: ''
        }
        sendto.from_name = sender;
        sendto.to_name = candidatName;
        sendto.message = "Félicitation ! A la suite de nos différents entretiens, nous avons le plaisir de vous informer que vous rejoignez la OLONA TEAM. Votre candidature au poste de (poste) a bien été retenue. Nous vous remercions de bien vouloir prendre contact avec Mihary, notre responsable recrutement, afin que nous puissions nous rencontrer.Nous nous tenons à votre disposition pour tout/tous renseignement(s) complémentaire(s) qu’il vous plairait de nous demander.";
        sendto.reply_to = mail;

        /* emailjs.send(
          serice_id,
          template_id,
          sendto,
          user_id
        )
          .then((response) => {
            setState({
                showModalRegister : false
            });
          })
          .catch((err) => {
            console.log("FAILED...", err);
            alert("mesage no complète ou serveur error");
          }); */
        try {
            await emailjs.send(
                serice_id,
                template_id,
                sendto,
                user_id
            )
            setShowModalRegister(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
            <div className="box-item">
                {/* small box */}
                    <div className="row r-1">
                        <div className="col-8 general-infos">
                            <p className="name"><strong>{candidat.lastname} {candidat.firstname}</strong></p>
                            {assignement === "assignement"
                                ?
                                <p />
                                :
                                <div>
                                <p className="poste"><strong>Secteur : {candidat.postes.name}</strong></p>
                                <p className="poste"><strong>Poste : {candidat.poste_precis}</strong></p>
                                </div>
                            }
                            <div className="sep" />
                            {role !== "user" ?
                            <div>
                            { candidat.status != null
                                ?
                                <span className="dispo"><strong>{candidat.status}</strong></span>
                                :
                                <span className="dispo"><strong>aucune info</strong></span>
                            }
                            </div>
                              :
                             <span className="dispo"><strong>{candidat.disponibilite}</strong></span>
                            }
                        </div>
                    </div>
                    <div className="row r-2 r-4">
                        <div className="col-12">
                            <h5>NOTE <span className="text-black">( {candidat.note} / 10 )</span></h5>
                            <div className="progress">
                                <div className="progress-bar" style={{ width: `${candidat.note * 10  }%` }} role="progressbar" aria-valuenow={candidat.note} aria-valuemin={0} aria-valuemax={10}></div>
                            </div>
                            {candidat.evaluations.map((evaluation) =>
                                evaluation.name === "Langue Francais parlé " ?
                                    <div>
                                        <div className="sep" />
                                        <h5>NIVEAU DE FRANCAIS<span className="text-black"> ( {evaluation.note} / 10 )</span></h5>
                                        <div className="progress">
                                            <div className="progress-bar" style={{ width: `${evaluation.note * 10  }%` }} role="progressbar" aria-valuenow={evaluation.note} aria-valuemin={0} aria-valuemax={10}></div>
                                        </div>
                                    </div>
                                    : null
                            )}
                            <p />
                        </div>
                    </div>
                    {mode === 'client' ?
                    <div>
                        <div className="row r-2 r-4">
                            <div className="col-12">
                                <h5 className="text-rigth igrey">FORFAIT OLONA</h5>
                                <p><i className="igrey fa-2x fas fa-wallet" /> €  <span> {candidat.prix_admin.toLocaleString()}</span> / Mois</p>
                            </div>
                        </div>
                         <div className="row r-2 r-4">
                         <div className="col-12">
                             <h5 className="text-rigth igrey">Commentaire</h5>
                             <p>{candidat.comment}</p>
                         </div>
                     </div>
                  </div>
                        :
                        <div className="row r-2 r-4">
                            {ok === 'client' ?
                             <div>
                             <div className="row r-2 r-4">
                                 <div className="col-12">
                                     <h5 className="text-rigth igrey">FORFAIT OLONA</h5>
                                     <p><i className="igrey fa-2x fas fa-wallet" /> €  <span> {candidat.prix_admin.toLocaleString()}</span> / Mois</p>
                                 </div>
                             </div>
                              <div className="row r-2 r-4">
                              <div className="col-12">
                                  <h5 className="text-rigth igrey">Commentaire</h5>
                                  <p>{candidat.comment}</p>
                              </div>
                          </div>
                       </div>
                                : <div>
                                <div className="col-12">
                                    <h5 >Prétention Salariale</h5>
                                    <p><i className="fas igrey fa-2x fa-money-check-alt" /> Ar {candidat.prix.toLocaleString()} / Mois</p>
                                </div>
                                {roleProps === 'admin' ?
                                <div className="col-12">
                                    <h5 className="text-rigth igrey">FORFAIT OLONA</h5>
                                    <p><i className="igrey fa-2x fas fa-wallet" /> €  <span> {candidat.prix_admin.toLocaleString()}</span> / Mois</p>
                                   
                                </div>
                                : null
                                }
                                </div>
                            }
                        </div>}
                    <div className="row r-2">
                        <div className="col-12">
                            <h5>Point fort <i className="ok fas fa-check-circle" /></h5>
                            <p>{candidat.point_fort}</p>
                            <div className="sep" />
                            <h5>Point faible <i className="oknt fas fa-times-circle" /></h5>
                            <p>{candidat.point_faible}</p>
                        </div>
                    </div>
                    <div className="sep" />
                    <div className="row r-favori">
                        {mode !== 'assignmentClient'
                            ?
                            <div className="col-12">
                                {mode !== 'assignment'
                                    ?
                                    <div>
                                        {mode === 'delete'
                                            ?
                                            <Button className="universal-length mbtn btn-favori delete" onClick={() => handleFavorites(user, candidat.id)}>
                                                <strong><i className="fas fa-user-times" /> Supprimer des favoris</strong>
                                            </Button>
                                            :
                                            <Button className="universal-length mbtn btn-favori" onClick={() => handleFavorites(user, candidat.id)}>
                                                <strong><i className="fas fa-star" /> Ajouter aux favoris</strong>
                                            </Button>
                                        }
                                    </div>
                                    :
                                    <div>
                                        {candidat.status === 'en entretien'
                                    ?
                                    <div>
                                    <button type="button" onClick={handleShowModalRegister}  className="universal-length mbtn btn-favori add">
                                    <i className="fas fa-success">Valider</i>
                                    </button>
                                    <button type="button" onClick={() => handleAssignments(user, candidat.id)}  className="universal-length mbtn btn-favori delete">
                                    <i className="fas">Refuser</i>
                                    </button>
                                    </div>
                                    : <div>
                                          <Button className="universal-length mbtn btn-favori delete" onClick={() => handleAssignments(user, candidat.id)}>
                                                <strong><i className="fas fa-user-times" /> Supprimer</strong>
                                            </Button>
                                    </div>
                                    }
                                    </div>
                           
            
                                }
                            </div>
                            : null
                        }
                    </div>
                    <div className="box-info">
                        <Link to={`/Detail/${  candidat.id}`} >
                            Plus d`infos<i className="fas fa-arrow-circle-right" />
                        </Link>
                    </div>
                    <div className="box-info" />
                    <Modal className="my-modal" animation={false} show={show} onHide={handleClose}>
                        {/* <Modal.Header closeButton> */}
                        {/* <Modal.Title></Modal.Title> */}
                        {/* </Modal.Header> */}

                        <button type="button" onClick={handleClose} className="close">
                            <i className="fas fa-times" />
                            <span className="sr-only">Close</span>
                        </button>
                        <Modal.Body>
                        <form onSubmit={() => handleUpdatePrix(candidat.id, prix_admin)}>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="prix_admin">Forfait Olona <span className="custom-text-blue">( € / mois )</span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="prix_admin"
                                                required
                                                value={prix_admin}
                                                onChange={onChangePrixAdmin}
                                                name="prix_admin"
                                                placeholder="€ / mois"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button className="w-100 btn-register btn btn-success"  type="submit">
                                    <strong><i className="fas fa-edit" /> Modifier le forfait</strong>
                            </button>
                        </form>
                        </Modal.Body>
                    </Modal>

                    <Modal className="my-modal" animation={false} show={showModalRegister} onHide={handleCloseModalRegister}>
                        {/* <Modal.Header closeButton> */}
                        {/* <Modal.Title></Modal.Title> */}
                        {/* </Modal.Header> */}

                        <button type="button" onClick={handleCloseModalRegister} className="close">
                            <i className="fas fa-times" />
                            <span className="sr-only">Close</span>
                        </button>
                        <Modal.Body>
                        <form onSubmit={saveUser}>
                    <div className="user-register">
                      <div className="box-container">
                        <div className="box">
                          <div className="title mb-5">
                            <h2> Ajoutez <span className="text-highlight">{candidat.firstname}</span> comme collaborateur</h2>
                          </div>
                          <div className="form-group">
                            <label htmlFor="matricule">Matricule</label>
                            <input
                              type="text"
                              className="form-control"
                              id="matricule"
                              required
                              value={matricule}
                              onChange={onChangeMatricule}
                              name="matricule"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="pseudo">Nom & prénom(s)</label>
                            <input
                              type="text"
                              className="form-control"
                              id="pseudo"
                              required
                              value={username}
                              onChange={onChangeUsername}
                              name="pseudo"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              required
                              value={email}
                              onChange={onChangeEmail}
                              name="email"
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="password">Mot de passe</label>
                            <input
                              type="password"
                              className="form-control"
                              id="password"
                              required
                              value={password}
                              onChange={onChangePassword}
                              name="password"
                            />
                          </div>
                          <div className="row">
                            <div className="col-12 text-center">
                              <button type="submit" className="w-50 btn btn-success">
                                Enregistrer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    </form>
                        </Modal.Body>
                    </Modal>
            </div>
        )
}

export default BoxItem;
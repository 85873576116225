import React, { useState, useEffect, FormEvent } from "react";
import DatePicker from "react-datepicker";
import ContactList from "./ContactList";
import API from '../services/API/API'
import "react-datepicker/dist/react-datepicker.css";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

const Register = () => {
    const [id, setId] = useState(null)
    const [date_start, setDate_start] = useState(new Date())
    const [duration, setDuration] = useState('')
    const [selectedValue, setSelectedValue] = useState('')
    const [file, setFile] = useState<File | string>('')
    const [Checked, setChecked] = useState(true)
    const [clients, setClients] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [items, setItems] = useState<Array<any>>([])
    const [name, setName] = useState('')
    const [lastname, setLastname] = useState('')
    const [status, setStatus] = useState('')
    const [phone, setPhone] = useState('')
    const [mail, setMail] = useState('')

    const addItem = () => {
        if (name !== "") {
            const newItem = {
                client_Id: id,
                name,
                lastname,
                status,
                phone,
                mail,
            };
        
            setItems([...items, newItem])
        
            setName('')
            setLastname('')
            setStatus('')
            setPhone('')
            setMail('')
        }
    }
    
    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        const { checked } = e.currentTarget
        setChecked(checked)
    }
    
    const onChangeFile = (e: FormEvent<HTMLInputElement>) => {
        const f = e.currentTarget.files ?  e.currentTarget.files[0] : ''
        setFile(f)
    }
    
    const onChangeDuration = (e: FormEvent<HTMLInputElement>) => {
        setDuration(e.currentTarget.value)
    }
    
    const onChangeDate = (date: Date | [Date | null, Date | null ] | null) => {
        if (date) {
            const d = date as Date
            setDate_start(d)
        }
    }
    
    const onChangeSelected = (e: FormEvent<HTMLSelectElement>) => {
        setSelectedValue(e.currentTarget.value)
    }
    
    const getListClient = async () => {
        try {
            const response = await API.Client.getClientNotOk(token)
            setClients(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const fileupload = async (id_user: string) => {
        const formData = new FormData();
        formData.append(`file`, file);
        formData.append('id_user', id_user);

        try {
            await API.File.uploadFileConfidential(formData, token)
            setSubmitted(true)
        }
        catch (error) {
            console.log(error)
        }
      }
    
      const saveUser = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
          date_start,
          duration,
          tacite: Checked,
          etat: 'PUBLISHED',
        };
        try {
            const response = await API.Client.updateClient(selectedValue, data, token)
            setId(response.data.id)
            fileupload(response.data.id)
        }
        catch (error) {
            console.log(error)
        }
    }
      
    const saveContact = async () => {
        const data = {
          data: items,
        };
        try {
            await API.Contact.addContact(data, token)
            window.location.href = '/SuiviClientList'
        }
        catch (error) {
            console.log(error)
        }
    }

    /* const newUser = () => {
        setId(null)
        setDate_start(new Date())
        setDuration('')
        setRoles([])
        setSelectedValue('')
        setFile('')
        setSubmitted(false)
    } */

    useEffect(() => {
        (async () => {
            await getListClient()
        })()
    }, [])

    return (
        <div>
          <div className="content">
            {/* /.content-header */}
            {/* Main content */}
            <section className="content">
              <div>
                {/* Small boxes (Stat box) */}
                {submitted ? (
                  <div>
                  <div className="row box-register">
                    <div className="col-10 offset-1 pt-5 pb-5">
                      <h2>Contact</h2>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="lastname">Prénom(s)</label>
                            <input
                              type="text"
                              className="form-control"
                              id="lastname"
                              required
                              value={lastname}
                              onChange={(e) => setLastname(e.currentTarget.value)}
                              name="lastname"
                              placeholder="ex : John"
                            />
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="firstname">Nom</label>
                            <input
                              type="text"
                              className="form-control"
                              id="firstname"
                              value={name}
                              onChange={(e) => setName(e.currentTarget.value)}
                              name="firstname"
                              placeholder="ex : Smith"
                            />
                          </div>
                        </div>
  
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="status">Statut</label>
                                <input type="text"  className="form-control" value={status} onChange={(e) => setStatus(e.currentTarget.value)}/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="phone">Phone</label>
                            <input type="number" className="form-control" value={phone} onChange={(e) => setPhone(e.currentTarget.value)} placeholder="ex: 023366667"/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="mail">Email</label>
                            <input type="email"     className="form-control" value={mail} onChange={(e) => setMail(e.currentTarget.value)} placeholder="ex: zo@gmail.com"/>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12"></div>
                        <button type="button" onClick={addItem} className="btn-register btn btn-save-contact" >Ajouter le contact</button>
                      </div>
                      <ContactList entries={items} />
                    </div>
                    
                  </div>
                  <div className="row">
                    <button type="button" onClick={saveContact} className="btn btn-save-information"> Enregistrer </button>
                  </div>
                  </div>
                ) : (
                  <form onSubmit={saveUser}>
                  <div className="row box-register">
                    <div className="col-10 offset-1 pt-5 pb-5">
                    <div className="row">
                    <div className="col-12">
                      <div className="affect d-inline-flex">
                        <div> Choisir un client prospect</div>
                        <div>
                          <select required className="form-control" value={selectedValue} onChange={onChangeSelected}>
                            <option value=""></option>
                            {clients.map((user: any) => (
                              <option value={user.id}>{user.username}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="date_start">Date du début</label>
                            <DatePicker
                              selected={date_start}
                              className="form-control"
                              onChange={(date) => onChangeDate(date)}
                              showTimeSelect
                              dateFormat="Pp"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="poste">Durée du contrat</label>
                            <input
                              type="text"
                              className="form-control"
                              id="duration"
                              required
                              value={duration}
                              onChange={onChangeDuration}
                              name="duration"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <input
                              type="checkbox"
                              onChange={e => handleChange(e)}
                              defaultChecked={Checked}
                            />
                            <label htmlFor="firstname">Tacite de reconduction</label>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label htmlFor="confidentiel" className=" control-label">Fichiers confidentiels</label>
                            <input required className="form-control-file" type="file" name="file" onChange={onChangeFile} />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <button type="submit" className="w-100 btn-register btn btn-save-information"> Enregistrer et Suivant</button>
                      </div>
                    </div>
                  </div>
                  </form>
                )}
              </div>
            </section>
          </div>
        </div>
      )
}

export default Register
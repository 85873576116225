import React, { useState, useEffect, FormEvent } from "react";
import { Button } from 'react-bootstrap';
import API from '../services/API/API'

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

interface IProps {
    actionClose: any
}

const Definition = ({ actionClose }: IProps) => {
    const [, setDefinition_id] = useState(null)
    const [item, setItem] = useState('')
    const [items, setItems] = useState<Array<any>>([])
    const [libelle, setLibelle] = useState('')
    const [isOk, setIsOk] = useState(false)

    // eslint-disable-next-line no-unused-vars
    const handleClose = () => {
        actionClose()
    }

    const onChangeLastname = (e: FormEvent<HTMLInputElement>) => {
        setLibelle(e.currentTarget.value)
    }

    const onChangeItem = (e: FormEvent<HTMLSelectElement>) => {
        setItem(e.currentTarget.value)
    }

    const saveDefinition = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            item_id: item,
            libelle
        };

        /* service.createDefinition(data, token)
            .then(response => {
                //console.log(data);
                setState({
                    definition_id: response.data.definition_id,
                    item_id: response.data.item,
                    libelle: response.data.libelle
                });
                handleClose()
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.CollaboratorEvaluation.createDefinition(data, token)
            setDefinition_id(response.data.definition_id)
            setLibelle(response.data.libelle)
            setIsOk(true)
            // item_id: response.data.item,
        }
        catch (error) {
            console.log(error)
        }
    }

    const newDefinition = () => {
        setDefinition_id(null)
        setItems([])
        setLibelle('')
        setIsOk(false)
    }

    const retrieveItems = async () => {
        /* service.getAllItems()
          .then(response => {
            setState({
              items: response.data
            });
            //console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.CollaboratorEvaluation.getAllItems(token)
            setItems(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveItems()
        })()
    }, [])

    return (
        <div>
            {/* Main content */}
            <section className="content">
            <div>
          {isOk ? (
              <div className="submitted">
                    <div style={{textAlign: 'center'}}>
                        <i className="ok fas fa-3x fa-check-circle"></i>
                        <h2><strong>Enregistrement terminé avec succès</strong></h2>
                        <Button onClick={newDefinition} className="universal-length mbtn">
                            <i className="fas fa-plus-circle"></i> <strong>Ajouter un autre définition</strong>
                        </Button>
                    </div>
                </div>
            ) : (
                <div className="submit-form">
                    
                        <form onSubmit={saveDefinition}>
                        <div className="row box-register">
                            <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="title-container">
                                    <div className="title">
                                        <h2>Ajouter Définition</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="libelle">Libelle</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="libelle"
                                                required
                                                value={libelle}
                                                onChange={onChangeLastname}
                                                name="libelle"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="item">Item</label>
                                            <select  required style={{ width: '100%'}} value={item} onChange={onChangeItem} placeholder="Choisir un Item">
                                                <option value=""></option>
                                                {items.map((el) => (
                                                    <option value={el.item_id}>{el.libelle}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    
                                </div>
                               
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-save-information"> Enregistrer </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                </div>
                )}
            </div>
            </section>
        </div>
    )
}

export default Definition
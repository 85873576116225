import http from '../APIs/olonaTeams'

export default class CandidateAPI {
    // for candidat
    public getAll = (page: string | number, limit: string | number, token: any) => http.get(`/customers?page=${page}&limit=${limit}`, token)
    
    public get = (id: string | number) => http.get(`/customer/${id}`)
    
    public create = (data: any) => http.post("/customer",  data)
    
    public update = (id: string | number, data: any, token: any) => http.put(`/customer/${id}`, data, token)
    
    public searchCustomer = (nom: string, prenom: string, note: string | number, prix: string | number, point_faible: string, point_fort: string, poste: string, sousposte: string, page: string | number, limit: string | number, token: any) => http.get(`/searchcustomer?nom=${nom}&prenom=${prenom}&note=${note}&prix=${prix}&point_faible=${point_faible}&point_fort=${point_fort}&poste=${poste}&sousposte=${sousposte}&page=${page}&limit=${limit}`, token)
    
    public searchCustomerSpontaneous = (nom: string, prenom: string, note: string | number, prix: string | number, point_faible: string, point_fort: string, poste: string, sousposte: string, page: string | number, limit: string | number, token: any) => http.get(`/searchcustomerSpontaneous?nom=${nom}&prenom=${prenom}&note=${note}&prix=${prix}&point_faible=${point_faible}&point_fort=${point_fort}&poste=${poste}&sousposte=${sousposte}&page=${page}&limit=${limit}`, token)
      
    public updatePrixCustomer = (id: string | number, data: any, token: any) => http.put(`/prixcustomer/${id}`, data, token)
    
    public updateStatusCustomer = (id: string | number, token: any) => http.put(`/statuscustomer/${id}`, token)

    public updateCandidat = (id: string | number, data: any , token: any) => http.put(`/updatecustomer/${id}`, data, token)
    
    public delete = (id: string | number, token: any) => http.delete(`/customer/${id}`, token)
    
    public findByNom = (nom: string, page: string | number, limit: string | number, token: any) => http.get(`/customer?nom=${nom}&page=${page}&limit=${limit}`, token)
    
    public findByNomSpontannee = (nom: string, page: string | number, limit: string | number, token: any) => http.get(`/customerSpontannee?nom=${nom}&page=${page}&limit=${limit}`, token)
    
    public findCountCustmerSpontannee = (token: any) => http.get(`/customerCount`,token)

    public findCountTalent = (token: any) => http.get(`/customerCountTalent`,token)
    
    public getDetailCandidats = (id_candidat: string | number, token: any) => http.get(`/customer/${id_candidat}`, token)
}
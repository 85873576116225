import React, { useState, useEffect } from "react";
import API from "../services/API/API";
import SinglePagePDFViewer from "../views/pdf/single-page";
import defaultAvatar from '../assets/images/default_avatar.jpg'
import PageHead from "../components/pageHead";
import "../styles/styles.css";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')
const URL_CV = "https://www.api.olonateams.com/uploads/"

interface IParams {
  id: string
}

interface IMatch {
  params: IParams
}

interface IProps {
  match: IMatch
}

const DetailSpontanee = ({ match }: IProps) => {
    const [id, ] = useState(match.params.id)
    const [candidats, setCandidats] = useState<Array<any>>([])

    const getDetailCandidat = async () => {
        /* service.getDetailCandidats(this.state.id, token)
          .then(response => {
            this.setState({
              candidats: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Candidate.getDetailCandidats(id, token)
            setCandidats(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await  getDetailCandidat()
        })()
    }, [])

    return (
        <div >
          {candidats.map((candidat) => (
              <div className="content-wrapper" style={{backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%"}}>
                <PageHead title={candidat.firstname} subject="Detail du candiidat" name="Detail"/>
              {/* /.content-header */}
              {/* Main content */}
              <section className="content">
                <div>
                  {/* Small boxes (Stat box) */}
                  <div className="row">
                    {/* USER INFO */}
                    <div className="user-info-container col-md-5 col-lg-5 mt-2 col-xs-12">
                      <div className="box-item detail">
                        {/* small box */}
                        <div className="">
                          <div className="row r-1">
                            <div className="col-12 text-al">
                              <div className="image image-olona detail">
                                <img src={defaultAvatar} className="" alt="Avatar" />
                              </div>
                            </div>
                            <div className="col-12 mt-3 general-infos text-center">
                              <p className="name text-black"><strong>{candidat.lastname} {candidat.firstname}</strong></p>
                              <p className="poste"><strong>Secteur : {candidat.postes.name}</strong></p>
                              <p className="poste"><strong>Poste : {candidat.poste_precis}</strong></p>
                              <div className="sep"></div>
                              <span className="dispo"><strong>{candidat.disponibilite}</strong></span>
                            </div>
                          </div>
                          <div className="row r-2 r-4">
                            <div className="col-12">
                               <h4>COMPETENCES/Candidat</h4>                       
                              <div className="sep"></div>
                              {candidat.evaluations.map((evaluation: any) => (
                                <div>
                                  <h5>{evaluation.name}<span className="text-black"> ( {evaluation.note} / 10 )</span></h5>
                                  <div className="progress">
                                    <div className="progress-bar" style={{ width: `${evaluation.note * 10  }%` }} role="progressbar" aria-valuenow={evaluation.note} aria-valuemin={0} aria-valuemax={10}></div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="sep"></div>
                        </div>
                      </div>
                      {/* <div className="image">
                        <img width="160px" height="160px" src={'http://localhost:8080/uploads/' + candidat.files[1].name} className="img-circle elevation-2" alt="User Image" />
                      </div>
                      <div className="inner">
                        <p>{candidat.firstname}/{candidat.postes.name}</p>
                        <p>Commentaire : {candidat.comment}</p>
                      </div>
  
                      <p className="small-box-footer">{candidat.postes.name}</p> */}
                      {/* {role !== 'user'
                        ?
                        <div>
                          <div>
                            <p>Affecter à :</p>
                            <select value={this.state.selectedValue} onChange={this.onChangeSelected}>
                              <option value=""></option>
                              {users.map((user) => (
                                <option value={user.id}>{user.username}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <button onClick={() => this.addAssignments(this.state.selectedValue, candidat.id)} className="btn btn-success">
                              Assigner
                            </button>
                          </div>
                        </div>
                        : null} */}
                    </div>
  
                    {/* PDF */}
                    <div className="user-cv-container col-md-7 col-lg-7 col-xs-12 mt-2">
                    <h3><a href={URL_CV + candidat.files[0].name} target="_blank" rel="noreferrer">Télécharger le fichier</a></h3>
                      {/* show PDF as image here */}
                      <div className="App">
                       
                        <SinglePagePDFViewer pdf={URL_CV + candidat.files[0].name} />
                        <hr />
  
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          ))}
        </div>
      )
}

export default DetailSpontanee
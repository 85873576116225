import React, { useState, useEffect, FormEvent } from 'react'
import Moment from 'moment'
import 'moment/locale/fr';
import { Button, Modal } from 'react-bootstrap';
import API from '../services/API/API'
import BoxSearchSuivi from "../components/boxSearchSuivi";
import PageHead from "../components/pageHead";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

const CollaboratorListClient = () => {
    const [suivis, setSuivis] = useState<Array<any>>([])
    const [collaborateurs, setCollaborateurs] = useState<Array<any>>([])
    const [id_user, ] = useState(sessionStorage.getItem('client_id') ?? '')
    const [show, setShow] = useState(false)
    const [searchTitle, setSearchTitle] = useState('')

    const getSuiviAllCollaborator = async (nom: string) => {
        try {
            const response = await API.Collaborator.searchAllColaborateur(nom, token)
            setSuivis(response.data)
            setShow(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const onSearchSuivi = async (nom: string, date_start: string, date_end: string) => {
        /* service.searchMultipleColaborateur(id_user, nom, date_start, date_end, token)
          .then(response => {
            setState({
              suivis: response.data,
              show : false
            });
          })
          .catch(e => {
            console.log(e);
          }); */
        if (nom === "" && date_start === "" && date_end === "") {
            await getSuiviAllCollaborator(nom)
        }
        else {
            try {
                const response = await API.Collaborator.searchMultipleAllColaborateur(nom, date_start, date_end, token)
                setSuivis(response.data)
                setShow(false)
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const handleClose = () => {
        setShow(false)
    }
    
    const handleShow = () => {
        setShow(true)
    }

    const retrieveCollaborators = async () => {
        /* service.getAllCollaboratorClient(id_user, token)
          .then(response => {
            setState({
              suivis: response.data
            });
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            console.log(id_user, token)
            const response = await API.Collaborator.getAllCollaboratorClient(id_user, token)
            console.log(response)
            setSuivis(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value)
        if(e.currentTarget.value === ""){
            retrieveCollaborators();
        }
        else{
            /* service.searchColaborateur(id_user, searchTitle, token)
            .then(response => {
            setState({
                suivis: response.data
            });
            })
            .catch(e => {
            console.log(e);
            }); */
            try {
                const response = await API.Collaborator.searchColaborateur(id_user, e.currentTarget.value, token)
                setSuivis(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
      
    const retrieveUserCollaborators = async () => {
        /* service.getUserCollaborator(id_user, token)
          .then(response => {
            setState({
              collaborateurs: response.data
            });
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getUserCollaborator(id_user, token)
            setCollaborateurs(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const readSuiviCollaborateur = async () => {
        /* service.readSuiviCollaborateur(id_user, token)
          .then(response => {
          })
          .catch(e => {
            console.log(e);
        }); */
        try {
            await API.Collaborator.readSuiviCollaborateur(id_user, token)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveCollaborators();
            await retrieveUserCollaborators();
            await readSuiviCollaborateur();
        })()
    }, [])

    return (
        <div className="content-wrapper bg-white" style={{backgroundImage: `url(${background})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat"}} >
            <PageHead title="Suivi Collaborateur" subject="Liste des rapports" name="Suivi"/>
          <div className="searchbar-container">
                <div className="form-inline">
                  <div className="input-group input-group-sm">
                    <span className="universal-length icon-r">
                      <i className="fas fa-search"></i>
                    </span>{" "}
                    <input
                      className="form-control form-control-navbar universal-length recherche col-10"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                      type="search"
                      placeholder="Rechercher"
                      aria-label="Search"
                    />
                  </div>
                  <div className="button-action">
                {/* <Link to="/Registration"> */}
                {/* <Button className="universal-length mbtn" block type="submit">
                    <i class="fas fa-plus-circle"></i> <strong>Ajouter un candidat</strong>
                  </Button> */}
                <Button onClick={handleShow} className="universal-length mbtn" >
                <i className="fas fa-search"></i> <strong>Filtres</strong>
                </Button>
                {/* </Link> */}
              </div>
                </div>
                
              </div>
              {suivis.map((suivi: any) => {
                  const date = Moment(suivi.createdAt).format('LLL')
                  return (
                    <div className="card-comment" style={{
                      margin: `10px`,
                      borderRadius: 5,
                      backgroundColor: `white`}}>
                    <div className="comment-text" style={{
                      margin: `10px`,}}>
                      <span className="username">
                      <b>{suivi.user.username}</b>
                        <span className="text-muted float-right">
                          {date}
                        </span>
                      </span>
                      {/* eslint-disable-next-line react/no-danger */}
                      <td dangerouslySetInnerHTML={{__html: suivi.message}} />
                    </div>
                  </div>  
              )})}
              <Modal className="my-modal" animation={false} show={show} onHide={handleClose}>
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}

            <button type="button" onClick={handleClose} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <BoxSearchSuivi actionSearch ={onSearchSuivi} suivi="client" collaborator={collaborateurs}/>
                </div>
              </div>
            </Modal.Body>
            </Modal>
            </div>
    )
}

export default CollaboratorListClient
/* eslint-disable no-irregular-whitespace */
import React, { useState, useEffect } from 'react'
import swal from 'sweetalert';
import API from '../services/API/API';
import PageHead from "../components/pageHead";
import BoxItem from '../components/boxItem';

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

const Assignment = () => {
    const [assignments, setAssignments] = useState<Array<any>>([])
    const [id_user, setId_user] = useState(sessionStorage.getItem('id') ?? '')
    const role = sessionStorage.getItem('role')

    const addFavorites = async (userId: string, candidatId: string) => {
        const data = {
          id_user: userId,
          id_candidat: candidatId
        };

        /* service.addFavorite(data, token)
          .then(response => {
            this.setState({
              
            });
            swal("Candidat ajouté aux favoris", {
              icon: "success",
            })
          })
          .catch(e => {
            alert('erreur lors d\'ajout aux favoris')
          }); */
        try {
            await API.Favorite.addFavorite(data, token)
            swal('Candidat ajouté aux favoris', {
                icon: 'success'
            })
        }
        catch (error) {
            console.log(error)
        }
    }

    const retrieveAssignments = async () => {
        try {
            const response = await API.Assignement.getAssignments(id_user, token)
            console.log(response)
            setAssignments(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    /* const deleteAssignments = async (ids: string, id_candidat: string) => {
        try {
            await API.Assignement.deleteAssignment(id, id_candidat, token)
            await retrieveAssignments()
        }
        catch (error) {
            console.log(error)
        }
    } */

    useEffect(() => {
        const id = sessionStorage.getItem('client_id')
        if (id) setId_user(id)
        ;(async () => {
            await retrieveAssignments()
        })()
    }, [])

    return (
        <div>
          <div className="content-wrapper" style={{backgroundImage: `url(${background})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat"}} >
            <PageHead
                title="Mes Candidats"
                subject="Liste des candidats"
                name="Candidat"
            />
            <section className="content">
                <div className="row">
                  {assignments.map((assignment) => assignment.customer.map((candidat: any) => (
                        <BoxItem mode="client" user={id_user} roleProps={role} candidat={candidat} actionFavorite={addFavorites} />   
                      )))}
                </div>
            </section>
          </div>
        </div>
      )
}

export default Assignment
import React from "react";
import { Route } from 'react-router-dom';
import Login from "../views/Login";
import '../views/app/App.css';

const LoginContainer = () => (
    <div className="login-style">
      <Route path="/Login">
        <Login/>
        </Route>
    </div>
)

export default LoginContainer
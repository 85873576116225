import React, { useEffect, useState, FormEvent } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as emailjs from "emailjs-com";
import Pagination from "react-js-pagination";
import Moment from 'moment'
import API from '../services/API/API'
import ProgressBar from "../components/progressBar";
import Avis from "../components/avis";
import BoxSearch from "../components/boxSearch";
import PageHead from "../components/pageHead";
import 'moment/locale/fr';

const limitPage = "25";
const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");
const template_id = "template_hmyrfrd"
const serice_id = "service_0w314u9"
const user_id = "user_zOdi9xxPdyaZik1HKn38h"
const defaultValue = { 
  firstname: '',
  lastname: '',
  note: '',
  poste: '',
  point_fort: '',
  point_faible: '',
  prix: '',
  sousposteValue: '',
}

const CustomerSpotaneous = () => {
    const [role,] = useState(sessionStorage.getItem('role') ?? '')
    const [candidats, setCandidats] = useState([])
    const [searchTitle, setSearchTitle] = useState('')
    const [username,] = useState(sessionStorage.getItem('username') ?? '')
    const [showSearch,setShowSearch] = useState(false)
    const [show, setShow] = useState(false)
    const [currentCandidat, setCurrentCandidat] = useState<any | null>(null)
    const [activePage,setActivePage] = useState(1)
    const [totalCustomers, setTotalCustomers] = useState<number>(0)
    const [search, setSearch] = useState(false)
    const [selected, setSelected] = useState(defaultValue)

    const handleCloseSearch = () => {
        setShowSearch(false)
    }

    const handleShowSearch = () => {
        setShowSearch(true)
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = (candidat: any) => {
        setCurrentCandidat(candidat)
        setShow(true)
    }
    
    const onSearchCandidat = async (nom: string, prenom: string, note: string, prix: string, point_faible: string, point_fort: string, poste: string, sousposte: string) => {
        /* service
          .searchCustomerSpontaneous(
            nom,
            prenom,
            note,
            prix,
            point_faible,
            point_fort,
            poste,
            sousposte,
            activePage,
            limitPage,
            token
          )
          .then((response) => {
            setState({
              candidats: response.data.results,
              totalCustomers: response.data.totalCustomers,
              showSearch: false,
              search: true,
            });
          })
          .catch((e) => {
            console.log(e);
          }); */
          setSelected({
            firstname: nom,
            lastname: prenom,
            note,
            poste,
            point_fort,
            point_faible,
            prix,
            sousposteValue: sousposte,
          })
        try {
            const response = await API.Candidate.searchCustomerSpontaneous(nom, prenom, note, prix, point_faible, point_fort, poste, sousposte, activePage, limitPage, token)
            setCandidats(response.data.results)
            setTotalCustomers(response.data.totalCustomers)
            setShowSearch(false)
            setSearch(true)
        }
        catch (error) {
            console.log(error)
        }
    }

    const retrieveCandidats = async (searchTitleP: string, page: number) => {
        /* service
          .findByNomSpontannee(searchTitle, page, limitPage, token)
          .then((response) => {
            setState({
                candidats: response.data.results,
                totalCustomers: response.data.totalCustomers,
                showSearch: false,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Candidate.findByNomSpontannee(searchTitleP, page, limitPage, token)
            setCandidats(response.data.results)
            setTotalCustomers(response.data.totalCustomers)
            setShowSearch(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const handlePageChange = async (pageNumber: number) => {
        setActivePage(pageNumber)
        if (!search) await retrieveCandidats("", pageNumber)
    }
      // accepteCandidat(candidat) {

    const onSubmit = async (emailP: string, candidatName: string, message: string, ok: boolean) => {
        const sendto: any = {};
        sendto.from_name = username;
        sendto.to_name = candidatName;
        sendto.message = message;
        sendto.reply_to = emailP;
            // console.log("Send to", sendto);
            /* emailjs.send(
              serice_id,
              template_id,
              sendto,
              user_id
            )
              .then((response) => {
                if (ok) {
                  window.location.href = "/Dashboard";
                  //console.log("SUCCESS!", response.status, response.text);
                } else {
                  //console.log("REFUS!", response.status, response.text);
                  retrieveCandidats(searchTitle, activePage);
                }
              })
              .catch((err) => {
                console.log("FAILED...", err);
                alert("mesage no complète ou serveur error");
              }); */
        try {
            await emailjs.send(serice_id, template_id, sendto, user_id)
            if (ok) window.location.href = '/Dashboard'
            else await retrieveCandidats(searchTitle, activePage)
        }
        catch (error) {
            console.log(error)
        }
    }

    const accepteCandidat = async (id: string, emailP: string, candidatName: string) => {
        const data = {
          id,
        };

        /* service
          .update(data, token)
          .then((response) => {
            //SEND MAIL OK
            // sendMyMail(candidat.mail, candidat.firstname+" "+candidat.lastname, "vous êtes accepté");
            var mes = "Suite à votre candidature, nous avons le plaisir de vous informer que votre candidature a bien été retenue. Nous vous contacterons dès que possible.";
            //var mes = "Félicitation ! A la suite de nos différents entretiens, nous avons le plaisir de vous informer que vous rejoignez la OLONA TEAM. Votre candidature au poste de (poste) a bien été retenue. Nous vous remercions de bien vouloir prendre contact avec Mihary, notre responsable recrutement, afin que nous puissions nous rencontrer.Nous nous tenons à votre disposition pour tout/tous renseignement(s) complémentaire(s) qu’il vous plairait de nous demander.";
            onSubmit(email, candidatName, mes, true);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            await API.Candidate.update(id, data, token)
            const mes = "Suite à votre candidature, nous avons le plaisir de vous informer que votre candidature a bien été retenue. Nous vous contacterons dès que possible."
            onSubmit(emailP, candidatName, mes, true)
        }
        catch (error) {
            console.log(error)
        }
    }
      // deleteCandidat(candidat) {

    const deleteCandidat = async (id: string, emailP: string, candidatName: string) => {
        /* service
          .delete(id, token)
          .then((response) => {
            //SEND MAIL NOT OK
            // sendMyMail(candidat.mail, candidat.firstname+" "+candidat.lastname, "vous êtes refusé");
            retrieveCandidats();
            var mes =
              "En réponse à votre candidature, je suis au regret de devoir vous informer que celle-ci n'a pas été retenue. Soyez cependant assuré que cette décision ne met pas en cause vos qualités personnelles, ni même celles de votre formation. Nous sommes très sensibles à l'intérêt que vous portez à notre entreprise, et conservons vos coordonnées afin de vous recontacter au besoin. Nous vous souhaitons une pleine réussite dans vos recherches futures. Veuillez agréer, Madame/Monsieur, l'expression de nos salutations distinguées.";
            onSubmit(email, candidatName, mes, false);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            await API.Candidate.delete(id, token)
            const mes = "En réponse à votre candidature, je suis au regret de devoir vous informer que celle-ci n'a pas été retenue. Soyez cependant assuré que cette décision ne met pas en cause vos qualités personnelles, ni même celles de votre formation. Nous sommes très sensibles à l'intérêt que vous portez à notre entreprise, et conservons vos coordonnées afin de vous recontacter au besoin. Nous vous souhaitons une pleine réussite dans vos recherches futures. Veuillez agréer, Madame/Monsieur, l'expression de nos salutations distinguées.";
            onSubmit(emailP, candidatName, mes, false);
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {    
        setSearchTitle(e.currentTarget.value)
        if (e.currentTarget.value === '')
            retrieveCandidats(searchTitle, activePage);
        else{
            /* service
          .findByNomSpontannee(searchTitle,activePage, limitPage, token)
          .then((response) => {
            setState({
                candidats: response.data.results,
                totalCustomers: response.data.totalCustomers,
            });
            //console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
            try {
                const response = await API.Candidate.findByNomSpontannee(e.currentTarget.value, activePage, limitPage, token)
                setCandidats(response.data.results)
                setTotalCustomers(response.data.totalCustomers)
            }   
            catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveCandidats(searchTitle, activePage)
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"}}
          >
            <PageHead title="Les candidatures spontanées" subject="Liste des candidatures" name="Candidatures"/>
            {/* SEARCH FORM */}
            <div className="action-container">
              <div className="searchbar-container">
                <div className="form-inline">
                  <div className="input-group input-group-sm">
                    <span className="universal-length icon-r">
                      <i className="fas fa-search"></i>
                    </span>{" "}
                    <input
                      className="form-control form-control-navbar universal-length recherche col-10"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                      type="search"
                      placeholder="Rechercher"
                      aria-label="Search"
                    />
                  </div>
                  <div>
                    <Button
                      onClick={handleShowSearch}
                      className="universal-length mbtn"
                    >
                      <i className="fas fa-search"></i>Filtres
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {/* END SEARCH FORM */}
            <div>
              {/* {role !== 'user'
                              ?
                              <div className="row">
                                  {candidats.map((candidat) => (
                                      <BoxItem user={id_user} role={role} candidat={candidat} actionFavorite={addFavorites} />
                                  ))
                                  }
                              </div>
                              :
                              <div>
                                  <h1>Bienvenue sur notre plateforme</h1>
                              </div>
                          } */}
              {role !== "user" ? (
                <div >
                  <div className="table-responsive">
                    <table className="table table-curved">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Date</th>
                          <th scope="col">Nom</th>
                          <th scope="col">Secteur</th>
                          <th scope="col">Poste</th>
                          <th scope="col">Disponibilité</th>
                          <th scope="col">Note des candidats</th>
                          <th scope="col">Prétention/Mois</th>
                          <th className="text-center" colSpan={3} scope="col">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {candidats.map((candidat: any) => (
                          <tr>
                            <td >
                              <Link to={`/DetailSpontanee/${  candidat.id}`}>
                              Plus d`infos<i className="fas fa-info-circle"></i>
                              </Link>
                            </td>
                            <td>
                              <strong>{Moment(candidat.createdAt).format('LL')}</strong>
                            </td>
                            <td>
                              <strong>
                                {candidat.lastname} {candidat.firstname}
                              </strong>
                            </td>
                            <td>{candidat.postes.name}</td>
                            <td>{candidat.poste_precis}</td>
                            <td>
                              <span className="disponibilite">
                                {candidat.disponibilite}
                              </span>
                            </td>
                            <td>
                              <strong>( {candidat.note} / 10 ) </strong>
                              <ProgressBar value={candidat.note} max={10} />
                            </td>
                            <td>
                              <strong>{candidat.prix.toLocaleString()} Ar</strong>
                            </td>
                            
                            <td className="decision accept" align="right">
                              <button type="button" onClick={() => handleShow(candidat)}>
                                Accepter
                              </button>
                            </td>
                            <td
                              className="decision deny"
                              align="right"
                            >
                              <button type="button" onClick={() =>
                                deleteCandidat(
                                  candidat.id,
                                  candidat.email,
                                  candidat.firstname
                                )
                              }>refuser</button>
                            </td>
  
                            {/* <td className="decision accept" align="right"><button onClick={() => accepteCandidat(candidat)}>Accepter</button></td>
                                                      <td className="decision deny" align="right"><button onClick={() => deleteCandidat(candidat)}>refuser</button></td> */}
                            <td></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={candidats.length}
                      totalItemsCount={totalCustomers}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                    <Modal
                      className="my-modal"
                      id={
                        currentCandidat != null
                          ? currentCandidat.id
                          : "0"
                      }
                      animation={false}
                      show={show}
                      onHide={handleClose}
                    >
                      {/* <Modal.Header closeButton> */}
                      {/* <Modal.Title></Modal.Title> */}
                      {/* </Modal.Header> */}
  
                      <button type="button" onClick={handleClose} className="close">
                        <i className="fas fa-times"></i>
                        <span className="sr-only">Close</span>
                      </button>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 left-image-register">
                            <div className="text">
                              <p>Olona</p>
                              <p>digital . outsourcing . data</p>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <Avis
                              username={username}
                              candidat={currentCandidat}
                              actionAccepter={accepteCandidat}
                            />
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                    <Modal
                      className="my-modal"
                      animation={false}
                      show={showSearch}
                      onHide={handleCloseSearch}
                    >
                      {/* <Modal.Header closeButton> */}
                      {/* <Modal.Title></Modal.Title> */}
                      {/* </Modal.Header> */}
  
                      <button type="button" onClick={handleCloseSearch} className="close">
                        <i className="fas fa-times"></i>
                        <span className="sr-only">Close</span>
                      </button>
                      <Modal.Body>
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <BoxSearch actionSearch={onSearchCandidat} selected={selected} etat = "NOT PUBLISHED"/>
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>
                  </div>
                </div>
              ) : (
                <div>
                  <h1>Bienvenue sur notre plateforme</h1>
                </div>
              )}
              {/* <div align="center">
                                  <button onClick={() => accepteCandidat(candidat.id)} className="btn btn-success">
                                      Accepter
                                  </button>
                                  <button onClick={() => deleteCandidat(candidat.id)}>
                                      Refuser
                                  </button>
                              </div> */}
            </div>
          </div>
        </div>
      )
}

export default CustomerSpotaneous
import http from '../APIs/olonaTeams'

export default class PrestanceAPI {
    // ppresence
  public getPresenceCollaborator = (id_user: string | number, id_client: string | number, token: any) => http.get(`/presencecollaborator/${id_user}/${id_client}`, token)

  public getMyPresenceCollaborator = (id_user: string | number, id_client: string | number, token: any) => http.get(`/mypresencecollaborator/${id_user}/${id_client}`, token)

  public getMyPresenceParMois = (id_user: string | number, id_client: string | number,mois: string | number, token: any) => http.get(`/mypresenceparmois/${id_user}/${id_client}/${mois}`, token)

  public getMyPresenceTwoDate = (id_user: string | number, id_client: string | number,start: string | number | Date, end: string | number | Date, token: any) => http.get(`/mypresencepartwodate/${id_user}/${id_client}/${start}/${end}`, token)

  public getPresenceAllCollaboratorClient = (id_client: string | number, token: any) => http.get(`/presenceparcollaboratorclient/${id_client}`, token)

  public getAllPresenceCollaborator = (token: any) => http.get(`/presencecollaborators/`, token)

  public searchCollaboratorPresence = (nom: string, token: any) => http.get(`/searchCollaborator?nom=${nom}`, token)
  
  public addPresence = (data: any, token: any) => http.post(`/presence`,data, token)

  public updatePresence = (id_presence: string | number, periode: string | number, token: any) => http.put(`/updatedatefin/${id_presence}/${periode}`,token)
  
  public updatePresenceAdmin = (id_presence: string | number, data: any, token: any) => http.put(`/updatePresence/${id_presence}`, data, token)

  public searchMultiplePresenceColaborateur = (id_client: string | number, nom: string, mois: string | number,  token: any) => http.get(`/searchMultiplePresenceCollaborateur/${id_client}?nom=${nom}&mois=${mois}`, token)

  public searchMultipleTwoDateClientColaborateur = (id_client: string | number, nom: string, date_start: string | number, date_end: string | number, token: any) => http.get(`/searchMultipleTwoDateClientCollaborateur/${id_client}?nom=${nom}&start=${date_start}&end=${date_end}`, token)

  public searchMultiplePresenceAllColaborateur = (nom: string, mois: string | number,  token: any) => http.get(`/searchMultiplePresenceAllCollaborateur?nom=${nom}&mois=${mois}`, token)

  public searchMultipleTwoDateAllColaborateur = (nom: string,date_start: string | number, date_end: string | number, token: any) => http.get(`/searchMultipleTwoDateAllCollaborateur?nom=${nom}&start=${date_start}&end=${date_end}`, token)

}
import React, { useEffect, useState } from "react";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import Moment from "moment";
import draftToHtml from 'draftjs-to-html';
import ShowMoreText from "react-show-more-text";
import API from "../services/API/API";
import "moment/locale/fr";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../styles/styles.css";

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}
interface IProps {
    match: IMatch
}

const SuiviCollaborator = ({ match }: IProps) => {
    const [message, setMessage] = useState('')
    const [client_id, ] = useState(match.params.id ?? '')
    const [id_user, ] = useState(sessionStorage.getItem('id') ?? '')
    const [suivis, setSuivis] = useState<Array<any>>([])
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const onChangeMsg = (editorStateP: EditorState) => {
        setEditorState(editorStateP)
        setMessage(draftToHtml(convertToRaw(editorStateP.getCurrentContent())))
    }
    
    const addSuivi = async () => {
        const data = {
          client_id,
          id_user,
          message,
        };
        /* service
          .createSuiviCollaborator(data, token)
          .then((response) => {
            setState({
              message: "",
            });
            window.location.href = "/SuiviCollaborator/" + state.client_id;
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            await API.Collaborator.createSuiviCollaborator(data, token)
            setMessage('')
            window.location.href = `/SuiviCollaborator/${client_id}`
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const getSuiviCollaborator = async () => {
        /* service
          .getSuiviCollaborator(state.id_user, state.client_id, token)
          .then((response) => {
            setState({
              suivis: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getSuiviCollaborator(id_user, client_id, token)
            setSuivis(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        Moment.locale("fr");
        (async () => {
            await getSuiviCollaborator()
        })()
    })

    return (
        <div>
          <div
            className="content-wrapper"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%"
            }}
          >
            {/* /.content-header */}
            {/* Main content */}
  
            <section className="content">
              <div>
                {/* Small boxes (Stat box) */}
                <div className="row">
                  {/* USER INFO */}
                  <div className="box-item detail">
                    {/* small box */}
                    <div className="">
                      <form onSubmit={addSuivi}>
                        <div className="container-fluid">
  
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="comment" className="control-label">Rapport du jour</label>
                                <Editor
                                  editorStyle={{ border: "1px solid #C0C0C0", minHeight: "200px", fontFamily: "'Montserrat', sans-serif;" }}
                                  editorState={editorState}
                                  onEditorStateChange={onChangeMsg}
                                />
                                {/* <textarea required className="form-control" type="text" id="comment" name="comment" value={state.fiche}
                                                      onChange={onChangeFichePoste} accept="" /> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <button type="submit" className="w-100 btn-register btn btn-success"> Enregistrer</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="sep"></div>
                <div className="sep"></div>
                <h3><b style={{ color: "white" }}>Mes rapports</b></h3>
                <section className="content">
                  <div>
                    <table className="table table-curved">
                      <thead >
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Collaborateur</th>
                          <th scope="col">Message</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {suivis.map((suivi, i) => (
                            <tr>
                              <td> {i + 1} </td>
                              <td> {suivi.user.username} </td>
                              <td>
                                <ShowMoreText
                                  /* Default options */
                                  lines={3}
                                  more="voir plus"
                                  less="voir moins"
                                  anchorClass="my-anchor-css-class"
                                  // onClick={executeOnClick}
                                  expanded={false}
                                  width={300}
                                >
                                  <p
                                    /* eslint-disable-next-line react/no-danger */
                                    dangerouslySetInnerHTML={{
                                      __html: suivi.message,
                                    }}
                                  />
                                </ShowMoreText>
                              </td>
                              <td> {Moment(suivi.createdAt).format('LLL')} </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
            </section>
          </div>
        </div>
    );
}

export default SuiviCollaborator
import React, { useState, useEffect, FormEvent } from "react";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
// eslint-disable-next-line no-irregular-whitespace
import swal from 'sweetalert';
import API from '../services/API/API'
import PageHead from "../components/pageHead";

const token = {
    headers: { "x-access-token": sessionStorage.getItem("accessToken") },
  };
const background = sessionStorage.getItem("background");

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}

interface IProps {
    match: IMatch
}

const CollaboratorList = ({ match }: IProps) => {
    const [collaborators, setCollaborators] = useState<Array<any>>([])
    const [username, setUsername] = useState('')
    const [selectedValue, setSelectedValue] = useState('')
    const [id_user,] = useState(match.params.id)
    const [show, setShow] = useState(false)
    const [collaboratorsList, setCollaboratorsList] = useState<Array<any>>([])
    const [, setId] = useState('')

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    const onChangeSelected = (e: FormEvent<HTMLSelectElement>) => {
        setSelectedValue(e.currentTarget.value)
    }

    const retrieveCollaborators = async () => {
        /* service
          .getUserCollaborator(id_user, token)
          .then((response) => {
            setState({
              collaborators: response.data,
              username: response.data[0].username,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getUserCollaborator(id_user, token)
            setCollaborators(response.data)
            setUsername(response.data[0].username)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getListCollaborator = async () => {
        /* service
          .getAllCollaborator(token)
          .then((response) => {
            setState({
              collaboratorsList: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getAllCollaborator(token)
            setCollaboratorsList(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const addCollaborator = async () => {
        const data = {
          id_user: selectedValue,
          client_id: id_user,
        };

        /* service
          .addCollaborator(data, token)
          .then((response) => {
            setState({
              id: response.data.id,
              show: false,
            });
            retrieveCollaborators();
          })
          .catch((e) => {
            alert(e);
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.addCollaborator(data, token)
            setId(response.data.id)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleDelete = async (user_id: string) => {
        /* service
        .deleteCollaborateurClient(user_id,match.params.id, token)
        .then((response) => {
          //SEND MAIL NOT OK
          // sendMyMail(candidat.mail, candidat.firstname+" "+candidat.lastname, "vous êtes refusé");
          swal("Pouf ! Votre fichier a été supprimé !", {
            icon: "success",
          });
          retrieveCollaborators();
          getListCollaborator();
        })
        .catch((e) => {
          console.log(e);
        }); */
        try {
            await API.Collaborator.deleteCollaborateurClient(user_id, match.params.id, token)
            swal("Pouf ! Votre fichier a été supprimé !", {
                icon: "success",
            });
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveCollaborators();
            await getListCollaborator();
        })()
    }, [])

    return (
        <div
          className="content-wrapper"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%"
          }}
        >
          <PageHead
            title={username}
            subject="Liste des collaborateurs"
            name="Collaborateurs"
          ></PageHead>
          <div className="action-container">
            <div className="button-action">
              <Button
                onClick={handleShow}
                className="universal-length mbtn"
              >
                <i className="fas fa-plus-circle"></i>{" "}
                <strong>Ajouter un collaborateur</strong>
              </Button>
            </div>
          </div>
  
          <section className="content">
            <div className="row userList">
              <div className="col-12">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Pseudo</th>
                      <th className="text-center" colSpan={2} scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {collaborators.map((col) => col.collaborators.map((user: any) => (
                          <tr>
                            <td></td>
                            <td>{user.username}</td>
                            <td>
                              <Link
                                to={
                                  `/SuiviParCollaborator/${ 
                                  user.id 
                                  }/${ 
                                  match.params.id}`
                                }
                              >
                                <p className="small-box-footer">
                                  Voir rapport quotidien{" "}
                                  <i className="fas fa-arrow-circle-right" />
                                </p>
                              </Link>
                            </td>
                            <td className="decision deny" align="center">
                              <button type="button" onClick={() => handleDelete(user.id)}>
                              <i className="fas fa-delete"></i>supprimmer
                              </button>
                            </td>
                          </tr>
                        )))}
                  </tbody>
                </table>
              </div>
            </div>
            <Modal
              className="my-modal"
              animation={false}
              show={show}
              onHide={handleClose}
            >
              {/* <Modal.Header closeButton> */}
              {/* <Modal.Title></Modal.Title> */}
              {/* </Modal.Header> */}
  
              <button type="button" onClick={handleClose} className="close">
                <i className="fas fa-times"></i>
                <span className="sr-only">Close</span>
              </button>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="row box-register">
                      <div className="col-10 offset-1 pt-5 pb-5">
                        <div className="row">
                          <div className="col-12">
                            <div className="affect d-inline-flex">
                              <div> Choisir un collaborateur </div>
                              <div>
                                <select
                                  value={selectedValue}
                                  onChange={onChangeSelected}
                                >
                                  <option value=""></option>
                                  {collaboratorsList.map((user) => (
                                    <option value={user.id}>
                                      {user.username}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div className="row">
                              <button
                                type="button"
                                onClick={addCollaborator}
                                className="universal-length mbtn btn btn-primary btn-block"
                              >
                                {" "}
                                Enregistrer
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </section>
        </div>
      )
}

export default CollaboratorList
import React, { useState, useEffect } from "react";
import API from '../services/API/API';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

interface IProps {
    client: string
    collaborateur: string
    /* eslint-disable react/require-default-props */
    mois?: number
    annee?: string
    collaborateurName?: string
    /* eslint-enable react/require-default-props */
}

const EvaluationDetailsComponent = ({ client, collaborateur, collaborateurName, mois, annee }: IProps) => {
    const [res, setRes] = useState<Array<any>>([])
    const [SumValue, setSumValue] = useState(0)
    // eslint-disable-next-line no-unused-vars
    const [notes, setNotes] = useState([])
    const [evaluations, setEvaluations] = useState<Array<any>>([])
    const [sommes, setSommes] = useState<Array<any>>([])

    /* findSumNote(){
        let somme = 0;
        sommes.map(({note}) => somme += note)
        setState({
            totalNote: somme
        });
    } */

    /* addItem(e) {
        if (_inputNote.value !== "") {
            var newItem = {
                user_id: client,
                observation: _inputObservation.value,
                definition_id: _inputDefinition.value,
                id_candidat: collaborateur,
                note: _inputNote.value,
                mois: _inputMois.value,
                annee: _inputAnnee.value,
            };

            setState((prevState) => {
                return {
                    items: previtems.concat(newItem)
                };
            });

            _inputObservation.value = "";
            _inputDefinition.value = "";
            _inputNote.value = "";
            _inputMois.value = "";
            _inputAnnee.value = "";
        }


    } */

    /* onChangeObservation(editorState) {
        setState({
            editorState,
            observation: draftToHtml(convertToRaw(editorgetCurrentContent()))
        });
    } */

    /* onChangeDefinition(e) {
        setState({
            definition: e.target.value
        });
    } */

    /* onChangeNote(e) {
        setState({
            note: e.target.value
        });
    } */

    /* onChangeMonth(e) {
        setState({
            mois: e.target.value
        });
    } */

    /* onChangeYear(e) {
        setState({
            annee: e.target.value
        });
    } */

    /* saveEvaluation(e) {
        e.preventDefault();
        var data = {
            data: items,
        };
        service.createEvaluation(data, token)
            .then(response => {
                console.log(data);
                setState({
                    note: note,
                    mois: mois,
                    annee: annee,
                    user_id: user_id,
                    observation: observation,
                    definition_id: definition_id,
                    id_candidat: id_candidat
                });
            })
            .catch(e => {
                console.log(e);
            });

    } */

    /* newDefinition() {
        setState({
            definition_id: null,
            items: [],
            libelle: ""
        });
    } */

    const retrieveEvaluations = async (client_id: string, collaborateur_id: string, month: number, year: string) => {
        /* if(sessionStorage.getItem('role') === "user"){
        service.GetEvaluationByIdMonthYear(client_id, collaborateur_id, mois, annee, token)
            .then((response) => {
                setState({
                    evaluations: response.data,
                });
                
                //console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
        }
        else{
            service.GetEvaluationByIdMonthYearCollaborator(collaborateur_id, mois, annee, token)
            .then((response) => {
                setState({
                    evaluations: response.data,
                });
                
                //console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            });
        } */
        if(sessionStorage.getItem('role') === 'user') {
            try {
                const response = await API.CollaboratorEvaluation.GetEvaluationByIdMonthYear(client_id, collaborateur_id, month, year, token)
                setEvaluations(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const response = await API.CollaboratorEvaluation.GetEvaluationByIdMonthYearCollaborator(collaborateur_id, month, year, token)
                setEvaluations(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    /* const retrieveDefinitions = async () => {
        try {
            const response = await API.CollaboratorEvaluation.getAllDefinition(token)
            setDefinitions(response.data)
        }
        catch (error) {
            console.log(error)
        }
    } */

    const retrieveSommes = async (client_id: string, collaborateur_id: string, month: number, year: string) => {
        /* if(sessionStorage.getItem('role') === "user"){
        service.getSommeNotes(client_id, collaborateur_id, mois, annee)
            .then(response => {
                setState({
                    sommes: response.data
                });
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
        }
        else{
            service.getSommeNotesCollaborator(collaborateur_id, mois, annee)
            .then(response => {
                setState({
                    sommes: response.data
                });
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            });
        } */
        if(sessionStorage.getItem('role') === 'user') {
            try {
                const response = await API.CollaboratorEvaluation.getSommeNotes(client_id, collaborateur_id, month, year, token)
                setSommes(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const response = await API.CollaboratorEvaluation.getSommeNotesCollaborator(collaborateur_id, month, year, token)
                setSommes(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const retrieveNotes = async () => {
        /* service.getAllNotes()
            .then(response => {
                setState({
                    notes: response.data
                });
                //console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.CollaboratorEvaluation.getAllNotes(token)
            setNotes(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const digitToMonth = (digit: number) => {
        switch (digit) {
            case 1:
                return "Janvier";
            case 2:
                return "Fevrier";
            case 3:
                return "Mars";
            case 4:
                return "Avril";
            case 5:
                return "Mai";
            case 6:
                return "Juin";
            case 7:
                return "Juillet";
            case 8:
                return "Août";
            case 9:
                return "Septembre";
            case 10:
                return "Octobre";
            case 11:
                return "Novembre";
            case 12:
                return "Decembre";
            default:
                return "";
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveEvaluations(client, collaborateur, mois ?? 1, annee ?? '');
            await retrieveNotes();
            await retrieveSommes(client, collaborateur, mois ?? 1, annee ?? '');
        })()
        setSumValue(sommes.reduce((a, v: any) => a + parseInt(v.note, 10), 0))
        let groups: any;
        for (let i = 0; i < evaluations.length; i + 1) {
            const groupName = evaluations[i].categorie;
            if (!groups[groupName]) {
                groups[groupName] = [];
            }
            groups[groupName].push({"item": evaluations[i].item, "id" : evaluations[i].id,
            "user_id":evaluations[i].user_id,"username":evaluations[i].username,
            "definition":evaluations[i].definition,"mois":evaluations[i].mois,"annee":evaluations[i].annee,
            "note":evaluations[i].note,"client":evaluations[i].client,"observation":evaluations[i].observation,
            "createdAt":evaluations[i].createdAt,"updatedAt":evaluations[i].updatedAt});
        }
        const ev =[]
        // eslint-disable-next-line no-restricted-syntax
        for (const groupName in groups) {
            ev.push({categorie: groupName, items: groups[groupName]});
        }
        
        setRes(ev)
    }, [])

    return (
        <div>
            {/* Main content */}
            <section className="content">
                <div>

                    <div className="submit-form">
                        <div className="row box-register">
                            <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="title-container">
                                    <div className="title">
                                        <h2>Evaluation {digitToMonth(mois ?? 1)} {annee} </h2>
                                        <h1>{collaborateurName}</h1>
                                    </div>
                                    <div className="col-6" style={{ backgroundColor: "white", padding: "15px", borderRadius: "5px", marginBottom: "15px" }}>
                                        
                                    </div>
                                </div>
                                <div className="table-responsive">

                                    <table className="table table-curved">
                                        <thead >
                                        
                                            <tr>
                                                <th scope="col" style={{backgroundColor:"#f79b0b"}}>#</th>
                                                <th scope="col" style={{backgroundColor:"#f79b0b"}}>Items</th>
                                                <th scope="col" style={{backgroundColor:"#f79b0b"}}>Définitions</th>
                                                <th scope="col" style={{backgroundColor:"#f79b0b"}}>Note/40</th>
                                                <th scope="col" style={{backgroundColor:"#f79b0b"}}>Observation</th>
                                            </tr>
                                            
                                        </thead>
                                        <tbody>
                                        
                                            {res.map((evaluat) => {
                                                const { items } = evaluat
                                              return (
                                                <div style={{display:"contents"}}>
                                                    <tr>
                                                        <td style={{backgroundColor:"#8080804f"}}></td>
                                                        <td style={{backgroundColor:"#8080804f"}}> <h3 style={{columnSpan: "initial"}}> {evaluat.categorie} </h3> </td>
                                                        <td style={{backgroundColor:"#8080804f"}}> </td>
                                                        <td style={{backgroundColor:"#8080804f"}}> </td>
                                                        <td style={{backgroundColor:"#8080804f"}}> </td>
                                                    </tr>
                                                
                                                    {items.map((evaluation: any, index: number) => 
                                                (
                                                <tr>
                                                    <td> {index+1} </td>
                                                    <td> {evaluation.item} </td>
                                                    <td> {evaluation.definition} </td>
                                                    <td> {evaluation.note} </td>
                                                    <td> {evaluation.observation} </td>
                                                </tr>
                                                ))}
                                                </div>
                                            )})}
                                            <hr></hr>
                                            {sommes.map((somme: any) => 
                                                (
                                                <tr>
                                                    <td> </td>
                                                    <td>  </td>
                                                    <td style={{fontWeight:"bold", fontSize:"medium"}}>{somme.categorie} </td>
                                                    <td style={{fontWeight:"bold", fontSize:"medium"}}>{somme.note}</td>
                                                    <td> </td>
                                                </tr>
                                                ))}
                                                <tr>
                                                    <td style={{fontWeight:"bold", fontSize:"medium", backgroundColor:"#8080804f"}}> </td>
                                                    <td style={{fontWeight:"bold", fontSize:"medium", backgroundColor:"#8080804f"}}>  </td>
                                                    <td style={{fontWeight:"bold", fontSize:"medium", backgroundColor:"#8080804f"}}> NOTE DU MOIS / 40</td>
                                                    <td style={{fontWeight:"bold", fontSize:"medium", backgroundColor:"#8080804f"}}>{SumValue} </td>
                                                    <td style={{fontWeight:"bold", fontSize:"medium", backgroundColor:"#8080804f"}}> </td>
                                                </tr>
                                        </tbody>
                                    </table>

                                </div>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default EvaluationDetailsComponent
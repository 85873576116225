import React, { useState, FormEvent } from "react";
import API from "../services/API/API";

const background = sessionStorage.getItem('background')

const Registration = () => {
    const role = sessionStorage.getItem('role');
    const [, setId] = useState<any>(null)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [note, setNote] = useState('0')
    const [poste, setPoste] = useState('')
    const [point_fort, setPoint_fort] = useState('')
    const [point_faible, setPoint_faible] = useState('')
    const [evaluation, setEvaluation] = useState('0')
    const [disponibilite, setDisponibilite] = useState('')
    const [prix, setPrix] = useState('')
    const [prix_admin, setPrix_admin] = useState('50')
    const [comment, setComment] = useState('')
    const [selectedFile, setSelectedFile] = useState<any>(null)
    const [avatar, setAvatar] = useState<any>(null)
    const [, setSelectedAvatar] = useState<any>(null)
    const [submitted, setSubmitted] = useState(false)

    const onChangeFileCv = (e: FormEvent<HTMLInputElement>) => {
        // Update the state 
        const File = e.currentTarget.files ? e.currentTarget.files[0] : null
        setSelectedFile(File)
    };
    
    const handleChange = (e: FormEvent<HTMLInputElement>) => {
        const avatarFile = e.currentTarget.files ? e.currentTarget.files[0] : null
        const selectedAvatarFile = e.currentTarget.files ? e.currentTarget.files[0] : null
        setAvatar(avatarFile)
        setSelectedAvatar(selectedAvatarFile)
    }
    
    const onChangeFirstname = (e: FormEvent<HTMLInputElement>) => {
        setFirstname(e.currentTarget.value)
    }
    
    const onChangeLastname = (e: FormEvent<HTMLInputElement>) => {
        setLastname(e.currentTarget.value)
    }
    
    const onChangeNote = (e: FormEvent<HTMLInputElement>) => {
        setNote(e.currentTarget.value)
    }
    
    const onChangePoste = (e: FormEvent<HTMLInputElement>) => {
        setPoste(e.currentTarget.value)
    }
    
    const onChangePrix = (e: FormEvent<HTMLInputElement>) => {
        setPrix(e.currentTarget.value)
    }

    const onChangePrixAdmin = (e: FormEvent<HTMLInputElement>) => {
        setPrix_admin(e.currentTarget.value)
    }

    const onChangeEvaluation = (e: FormEvent<HTMLInputElement>) => {
        setEvaluation(e.currentTarget.value)
    }
    
    const onChangePointfaible = (e: FormEvent<HTMLInputElement>) => {
        setPoint_faible(e.currentTarget.value)
    }
    
    const onChangePointFort = (e: FormEvent<HTMLInputElement>) => {
        setPoint_fort(e.currentTarget.value)
    }
    
    const onChangeDisponibilite = (e: FormEvent<HTMLInputElement>) => {
        setDisponibilite(e.currentTarget.value)
    }
    
    const onChangeComment = (e: FormEvent<HTMLTextAreaElement>) => {
        setComment(e.currentTarget.value)
    }
    
    const fileupload = async (id_candidat: string) => {
        const formData = new FormData();
        formData.append(`file`, selectedFile);
        formData.append('id_candidat', id_candidat);
        /* service.uploadFile(formData)
        .then(response => {
          var formData = new FormData();
        formData.append(`file`, selectedAvatar);
        formData.append('id_candidat', id_candidat);
        service.uploadFile(formData)
        .then(response => {
          setState({
            id: response.data.id,
            submitted: true
          });
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
        })
        .catch(e => {
          console.log(e);
        }); */
        try {
            const response = await API.File.uploadFile(formData)
            setId(response.data.id)
            setSubmitted(true) 
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const saveCandidat = async () => {
        const data = {
          firstname,
          lastname,
          note,
          poste,
          evaluation,
          disponibilite,
          prix,
          prix_admin,
          point_faible,
          point_fort,
          comment,
          etat: 'PUBLISHED'
        };
    
        /* service.create(data)
          .then(response => {
            setState({
              id: response.data.id,
              firstname: response.data.firstname,
              lastname: response.data.lastname,
              note: response.data.note,
              poste: response.data.poste,
              evaluation: response.data.evaluation,
              disponibilite: response.data.disponibilite,
              prix: response.data.prix,
              point_faible: response.data.point_faible,
              point_fort: response.data.point_fort,
              comment: response.data.comment
            });
            fileupload(response.data.id)
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Candidate.create(data)
            setId(response.data.id)
            setFirstname(response.data.firstname)
            setLastname(response.data.lastname)
            setNote(response.data.note)
            setPoste(response.data.poste)
            setEvaluation(response.data.evaluation)
            setDisponibilite(response.data.disponibilite)
            setPrix(response.data.prix)
            setPoint_faible(response.data.point_faible)
            setPoint_fort(response.data.point_fort)
            setComment(response.data.comment)
            await fileupload(response.data.id)
        }
        catch (error) {
            console.log(error)
        }
    }

    const newCandidat = () => {
        setId('')
        setFirstname('')
        setLastname('')
        setNote('')
        setPoste('')
        setEvaluation('')
        setDisponibilite('')
        setPrix('')
        setPoint_faible('')
        setPoint_fort('')
        setComment('')
    }

    return (
        <div >
          <div className="content-wrapper" style={{backgroundImage: `url(${background})`}} >
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0 text-dark">Registration Candidat</h1>
                  </div>{/* /.col */}
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item"><a href="#">Registration</a></li>
                      <li className="breadcrumb-item active">Ajouter un candidat</li>
                    </ol>
                  </div>{/* /.col */}
                </div>{/* /.row */}
              </div>{/* /.container-fluid */}
            </div>
            {/* /.content-header */}
            {/* Main content */}
            <section className="content">
              <div>
                {/* Small boxes (Stat box) */}
                <form>
                  {submitted ? (
                    <div>
                      <h4>You submitted successfully!</h4>
                      <button type="button" className="btn btn-success" onClick={newCandidat}>
                        Add
                        </button>
                    </div>
                  ) : (
                    <div className="card-body">
                      <div className="form-group">
                      <label htmlFor="avatar">Photo de profile</label>
                      <div className="image">
                      <img width="100px" height="100px" src={avatar} alt="Profil"/>
                      </div>
                      <input type="file" accept="image/*" name="selectedAvatar" onChange={handleChange}/>
                      </div>
                      <div className="form-group">
                        <label htmlFor="firstname">Nom</label>
                        <input
                          type="text"
                          className="form-control"
                          id="firstname"
                          required
                          value={firstname}
                          onChange={onChangeFirstname}
                          name="firstname"
                        />
                      </div>
  
                      <div className="form-group">
                        <label htmlFor="lastname">Prénom</label>
                        <input
                          type="text"
                          className="form-control"
                          id="lastname"
                          required
                          value={lastname}
                          onChange={onChangeLastname}
                          name="lastname"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="poste">Poste</label>
                        <input
                          type="text"
                          className="form-control"
                          id="poste"
                          required
                          value={poste}
                          onChange={onChangePoste}
                          name="poste"
                        />
                      </div>
  
                      <div className="form-group">
                        <label htmlFor="note">Note</label>
                        <input
                          type="range" min="0" max="10" 
                          className="form-control"
                          id="note"
                          required
                          value={note}
                          onChange={onChangeNote}
                          name="note"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="point_faible">Point Faible</label>
                        <input
                          type="text"
                          className="form-control"
                          id="point_faible"
                          required
                          value={point_faible}
                          onChange={onChangePointfaible}
                          name="point_faible"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="point_fort">Point Fort</label>
                        <input
                          type="text"
                          className="form-control"
                          id="point_fort"
                          required
                          value={point_fort}
                          onChange={onChangePointFort}
                          name="point_fort"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="evaluation">Evaluation</label>
                        <input
                          type="range" min="0" max="5" 
                          className="form-control"
                          id="evaluation"
                          required
                          value={evaluation}
                          onChange={onChangeEvaluation}
                          name="evaluation"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="disponibilite">Disponibilité</label>
                        <input
                          type="text"
                          className="form-control"
                          id="disponibilite"
                          required
                          value={disponibilite}
                          onChange={onChangeDisponibilite}
                          name="disponibilite"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="prix">Prix</label>
                        <input
                          type="number"
                          className="form-control"
                          id="prix"
                          required
                          value={prix}
                          onChange={onChangePrix}
                          name="prix"
                        />
                      </div>
                      {role !== 'user' && role !== 'moderator'
                        ? <div className="form-group">
                          <label htmlFor="prix_admin">Prix réel</label>
                          <input
                            type="number"
                            className="form-control"
                            id="prix_admin"
                            value={prix_admin}
                            onChange={onChangePrixAdmin}
                            name="prix_admin"
                          />
                        </div>
                        : null
                      }
  
                      <div className="form-group">
                        <label htmlFor="image" className="col-md-3 control-label">CV</label>
                        <div className="col-sm-9">
                          <input className="form-control" type="file" name="profile" onChange={onChangeFileCv} />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="comment" className="col-md-3 control-label">Commentaire</label>
                        <div className="col-sm-9">
                          <textarea className="form-control" id="comment" name="comment" value={comment}
                            onChange={onChangeComment} />
                        </div>
                      </div>
                      <button type="button" onClick={saveCandidat} className="btn btn-success">
                        Enregistrer
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </section>
          </div>
        </div>
      );
}

export default Registration
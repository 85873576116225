import React from 'react'

interface IProps {
    value: number
    max: number
}

const ProgressBar = ({ value, max }: IProps) => (
    <div className="progress">
        <div className="progress-bar" style={{ width: `${value * 10  }%` }} role="progressbar" aria-valuenow={value} aria-valuemin={0} aria-valuemax={max}></div>
    </div>
)

export default ProgressBar
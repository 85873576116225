import React, { useState, useEffect } from "react";
import htmlToDraft from "html-to-draftjs";
import { EditorState, ContentState } from "draft-js";
import { Button, Modal } from "react-bootstrap";
import ShowMoreText from "react-show-more-text";
import Moment from "moment";
import swal from 'sweetalert';
import API from '../services/API/API'
import BoxItem from '../components/boxItem';
import Fiche from "../components/addFiche";
import PageHead from "../components/pageHead";
import defaultAvatar from "../assets/images/default_avatar.jpg";
import "moment/locale/fr";
import "../styles/styles.css";


const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");
const limitPage = "25";

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}

interface IProps {
    match: IMatch
}

const DetailClient = ({ match }: IProps) => {
    const [role,] = useState(sessionStorage.getItem("role") ?? '')
    const [id, ] = useState(match.params.id)
    const [id_user,] = useState(sessionStorage.getItem('id') ?? '');
    const [currentPost, setCurrentPost] = useState<any>({})
    const [, setCandidats] = useState<Array<any>>([])
    const [assignments, setAssignments] = useState<Array<any>>([])
    const [, setTotalCustomers] = useState('')
    const [activePage, ] = useState(1)
    const [users, setUsers] = useState<Array<any>>([])
    const [fiche, setFiche] = useState<any>({})
    const [isUpdateOrCreate, setIsUpdateOrCreate] = useState('create')
    const [show, setShow] = useState(false)
    const [showAssign, setShowAssign] = useState(false)
    const [, setEditorState] = useState(EditorState.createEmpty())

    const retrieveAssignments = async () => {
        /* service.getAssignmentsPoste(id, currentPost.id, token)
          .then(response => {
            setState({
                assignments: response.data
            });
            console.log(assignments)
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Assignement.getAssignmentsPoste(id, currentPost.id, token)
            setAssignments(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const handleClose = () => {
        setShow(false)
    }
    
    const retrieveCandidats = async (pageNumber: number) => {
        /* service
          .getAll(pageNumber, limitPage, token)
          .then((response) => {
            setState({
              candidats: response.data.results,
              totalCustomers: response.data.totalCustomers,
              showSearch: false,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Candidate.getAll(pageNumber, limitPage, token)
            setCandidats(response.data.results)
            setTotalCustomers(response.data.totalCustomers)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const handleCloseAssign = () => {
        setShowAssign(false)
    }
    
    const handleShow = () => {
        setShow(true)
        setIsUpdateOrCreate('create')
    }
    
    const handleShowAssign = async (ficheP: any) => {
        setShowAssign(true)
        setCurrentPost(ficheP)
        await retrieveAssignments()
    }
    
    const handleUpdateShow = (ficheP: any) => {
        setShow(true)
        setFiche(ficheP)
        setIsUpdateOrCreate('update')
    }
    
    const getDetailUser = async () => {
        /* service
          .getDetailClient(id, token)
          .then((response) => {
            const html = response.data[0].fiche;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
              const contentState = ContentcreateFromBlockArray(
                contentBlock.contentBlocks
              );
              const editorState = EditorcreateWithContent(contentState);
              setState({ editorState: editorState, users: response.data });
            }
            //console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Client.getDetailClient(id, token)
            const html = response.data[0].fiche;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                  contentBlock.contentBlocks
                );
                const newEditorState = EditorState.createWithContent(contentState);
                setEditorState(newEditorState)
                setUsers(response.data)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const deleteFiche = async (idP: string) => {
        /* service
          .deleteFiche(idP, token)
          .then((response) => {
            //SEND MAIL NOT OK
            // sendMyMail(candidat.mail, candidat.firstname+" "+candidat.lastname, "vous êtes refusé");
            swal("Pouf ! Votre fichier a été supprimé !", {
              icon: "success",
            });
            getDetailUser();
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            await API.Form.deleteFiche(idP, token)
            swal("Pouf ! Votre fichier a été supprimé !", {
                icon: "success",
            });
            await getDetailUser()
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await getDetailUser();
            await retrieveCandidats(activePage);
            await retrieveAssignments();
        })()
    }, [])

    return (
        <div>
          {users.map((user) => (
            <div
              className="content-wrapper"
              style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
              }}
            >
              <PageHead
                title={user.company}
                subject="Fiche client"
                name="Client"
              ></PageHead>
              <div className="action-container">
                <div className="button-action">
                  <Button
                    onClick={handleShow}
                    className="universal-length mbtn"
                    type="submit"
                  >
                    <i className="fas fa-plus-circle"></i>{" "}
                    <strong>Ajouter un autre fiche</strong>
                  </Button>
                </div>
  
                
  
              </div>
              {/* /.content-header */}
              {/* Main content */}
              <section className="content">
                <div>
                  {/* Small boxes (Stat box) */}
                  <div className="row">
                    {/* USER INFO */}
                    <div className="user-info-container col-md-5 col-lg-5 mt-2 col-xs-12">
                      <div className="box-item detail">
                        {/* small box */}
                        <div className="">
                          <div className="row r-1">
                            <div className="col-12 text-al">
                              <div className="image image-olona detail">
                                <img
                                  src={defaultAvatar}
                                  className=""
                                  alt="User"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="sep"></div>
                          <div className="sep"></div>
                          <hr />
                          <div className="row r-2 r-4">
                            <div className="col-12">
                              <h4>Contact</h4>
                              <div className="sep"></div>
                              <h3>{user.email}</h3>
                            </div>
                          </div>
                          <div className="sep"></div>
                        </div>
                      </div>
  
                    </div>
  
                    {/* PDF */}
                    <div
                      className="user-cv-container col-md-7 col-lg-7 col-xs-12 mt-2"
                      style={{ backgroundColor: "white", borderRadius: "10px" }}
                    >
                      {/* show PDF as image here */}
  
                      <div className="App">
                        <h3
                          style={{
                            color: "white",
                            display: "flex",
                            padding: "5px",
                            backgroundColor: "#cecece",
                            fontStyle: "oblique",
                            margin: "revert",
                          }}
                        >
                          Fiche de poste
                        </h3>
  
  
                        <ShowMoreText
                          /* Default options */
                          lines={10}
                          more="voir plus"
                          less="voir moins"
                          anchorClass="my-anchor-css-class"
                          expanded={false}
                          width={300}
                        >
                          {/* eslint-disable-next-line react/no-danger */}
                          <p dangerouslySetInnerHTML={{ __html: user.fiche }} />
                        </ShowMoreText>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="sep"></div>
                  <div className="sep"></div>
                  <div className="table-responsive">
                    <table className="table table-inbox table-hover">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Autre fiche de poste</th>
                          <th scope="col">Date de création</th>
                          <th scope="col" colSpan={3} >
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {user.fiches.map((item: any) => (
                          <tr className="">
                            <td className="inbox-small-cells">
                              <i className="fa fa-star"></i>
                            </td>
                            <td className="view-message">
                              <b>{fiche.title}</b>
                              <ShowMoreText
                                /* Default options */
                                lines={1}
                                more="voir plus"
                                less="voir moins"
                                anchorClass="my-anchor-css-class"
                                expanded={false}
                                width={300}
                              >
                                  {/* eslint-disable-next-line react/no-danger */ }
                                <p dangerouslySetInnerHTML={{ __html: item.message }}
                                />
                              </ShowMoreText>
                            </td>
                            <td className="view-message">
                              {Moment(item.createdAt).format("LLL")}
                            </td>
                            <td className="decision accept" >
                              <button type="button" onClick={() => handleUpdateShow(fiche)}>
                                <i className="fas fa-edit"></i> Modifier
                              </button>
                            </td>
                            <td className="decision deny" >
                              <button type="button" onClick={() => deleteFiche(fiche.id)}>
                                <i className="fas fa-trash"></i>supprimmer
                              </button>
                            </td>
                            <td >
                            <button type="button" className="btn-warning" onClick={() => handleShowAssign(fiche)}>
                                <i className="fas fa-plus-circle"></i> Assigner de(s) candidat(s)
                              </button>
                            
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </section>
            </div>
          ))}
          <Modal
            className="my-modal"
            animation={false}
            show={show}
            onHide={handleClose}
          >
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}
  
            <button type="button" onClick={handleClose} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 left-image-register">
                  <div className="text">
                    <p>Olona</p>
                    <p>digital . outsourcing . data</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <Fiche clientId={match.params.id} mode={isUpdateOrCreate} fiches={fiche} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
  
          <Modal
            className="my-modal"
            animation={false}
            show={showAssign}
            onHide={handleCloseAssign}
          >
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}
  
            <button type="button" onClick={handleCloseAssign} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                {assignments.map((assignment) => assignment.customer.map((candidat: any) => (
                        
                        <BoxItem mode="client" user={id_user} roleProps={role} candidat={candidat} />   
                      )))}
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
}

export default DetailClient
import React, { useState, FormEvent } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/* eslint-disable no-unused-vars */
type actionSearchFunction = (nom: string, date_start: Date, date_end: Date) => void
/* eslint-enable no-unused-vars */

interface ICol {
    username: string
}

interface ICollaborator {
    username: string
    collaborators: Array<ICol>
}

interface IProps {
    // eslint-disable-next-line react/require-default-props
    suivi?: string
    collaborator: Array<ICollaborator>
    actionSearch: any
}

const BoxSearchSuivi = ({ suivi, collaborator, actionSearch }: IProps) => {
    // const [id, setId] = useState(null)
    const [username, setUsername] = useState('')
    const [date_start, setDate_start] = useState(new Date())
    const [date_end, setDate_end] = useState(new Date())

    const handleSearch = (nom: string, start: Date, end: Date) => {
        actionSearch(nom, start, end)
        // setId(null)
        setUsername('')
        setDate_end(new Date())
        setDate_start(new Date())
    }
    
    const onChangeFirstname = (e: FormEvent<HTMLSelectElement>) => {
        setUsername(e.currentTarget.value)
    }
    
    const onChangeDateStart = (date: Date | [Date | null, Date | null ] | null) => {
        if (date) {
            const d = date as Date
            d.setHours(23, 59, 0, 0);
            setDate_start(d)
        }
    }
    
    const onChangeDateEnd = (date: Date | [Date | null, Date | null ] | null) => {
        if (date) {
            const d = date as Date
            d.setHours(23, 59, 0, 0);
            setDate_end(d)
        }
    }

    return (
        <div>
          {/* Main content */}
          <section className="content">
            <div>
              <div className="row box-register">
                <div className="col-10 offset-1 pt-5 pb-5">
                  <div className="title-container">
                    <div className="title">
                      <h2>
                        Chercher <br />
                        le(s) rapport(s) du collaborateur : <br />
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                                          <div className="col-12">
                                              <div className="form-group">
                                                  <label htmlFor="poste">Nom</label>
                                              </div>
                                          </div>
                                          <div className="col-12">
                                              <div className="form-group">
                                                  <select  required  value={username} onChange={onChangeFirstname} placeholder="Choisir un collaborateur">
                                                      <option value="" ></option>
                                                      {collaborator.map((col) => {
                                                        if(suivi !== "client"){
                                                          return (<option value={col.username}>{col.username}</option>)
                                                        }
                                                        
                                                            const colok = col.collaborators
                                                            return (
                                                              colok.map((el) => (
                                                                <option value={el.username}>{el.username}</option>
                                                            )))
                                                        
                                                      }
                                                      )}
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group custom-range">
                        <label htmlFor="date_start">
                           Du
                        </label>
                        <DatePicker
                          selected={new Date(date_start.setHours(0,0,0,0))}
                          className="form-control"
                          onChange={(date) => onChangeDateStart(date)}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="date_end">
                          Au :
                        </label>
                        <DatePicker
                          selected={new Date(date_end.setHours(23,59,0,0))}
                          className="form-control"
                          onChange={(date) => onChangeDateEnd(date)}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3">
                      <Button
                        className="universal-length mbtn"
                        onClick={() =>
                          handleSearch(
                            username,
                            date_start,
                            date_end
                          )
                        }
                      >
                        <strong>
                          <i className="fas fa-search"></i> Chercher
                        </strong>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )
}

export default BoxSearchSuivi
import http from '../APIs/olonaTeams'

export default class CollaboratorEvaluationAPI {
    // Module evalution collaborateur
  public getAllCategories = (token: any) => http.get(`/categories/`, token)

  public getAllDefinition = (token: any) => http.get(`/evaluationdefinitions/`, token)

  public getAllDefinition2 = (token: any) => http.get(`/evaluationdefinitionsvue/`, token)

  public getAllEvaluation = (token: any) => http.get(`/evaluationmodule/`, token)

  public getEvaluationById = (client_id: string | number, collaborateur_id: string | number, token: any) => http.get(`/evaluationid/${client_id}/${collaborateur_id}`, token)

  public GetEvaluationByIdMonthYear = (client_id: string | number, collaborateur_id: string | number, mois: string | number, annee: string | number, token: any) => http.get(`/evaluationid/${client_id}/${collaborateur_id}/${mois}/${annee}`, token)

  public GetEvaluationByIdMonthYearCollaborator = (collaborateur_id: string | number, mois: string | number, annee: string | number, token: any) => http.get(`/evaluationidCollaborator/${collaborateur_id}/${mois}/${annee}`, token)

  public getCollaboratorByClientId = (client_id: string | number, token: any) => http.get(`/collaboratorclient/${client_id}`, token)

  public getCollaboratorEvaluation = (client_id: string | number, token: any) => http.get(`/collaboratorevaluation/${client_id}`, token)

  public getCollaboratorEvaluationc = (user_id: string | number, token: any) => http.get(`/collaboratorevaluationc/${user_id}`, token)

  public getCollaboratorEvaluationAdmin = (token: any) => http.get(`/collaboratorevaluationAdmin/`, token)

  public findEvaluationColaborateur = (client_id: string | number, trimestre: string | number, collaborateur: string | number, annee: string | number, token: any) => http.get(`/findevaluation/${client_id}/${trimestre}/${collaborateur}/${annee}`, token)

  public findAllEvaluationColaborateur = (trimestre: string | number, collaborateur: string | number, annee: string | number, token: any) => http.get(`/findAllevaluation/${trimestre}/${collaborateur}/${annee}`, token)
  
  public getAllItems = (token: any) => http.get(`/evaluationitems/`, token)

  public getAllNotes = (token: any) => http.get(`/evaluationnotes/`, token)

  public getSommeNotes = (client_id: string | number, collaborateur_id: string | number, mois: string | number, annee: string | number, token: any) => http.get(`/sumnotes/${client_id}/${collaborateur_id}/${mois}/${annee}`, token)

  public getSommeNotesCollaborator = (collaborateur_id: string | number, mois: string | number, annee: string | number, token: any) => http.get(`/sumnotesCollaborator/${collaborateur_id}/${mois}/${annee}`, token)

  public createDefinition = (data: any, token: any) => http.post(`/definition`, data, token)

  public createItem = (data: any, token: any) => http.post(`/item`, data, token)

  public createEvaluation = (data: any, token: any) => http.post(`/evaluations`, data, token)

  public find = (motcle: string, user: string | number, token: any) => http.get(`/evaluations/${motcle}/${user}`, token)

  public findCollaborator = (motcle: string, user: string | number, token: any) => http.get(`/evaluationsCollaborator/${motcle}/${user}`, token)

  public findAdmin = (motcle: string ,token: any) => http.get(`/evaluationsAdmin/${motcle}`, token)

  public updateDefinition = (id: string | number, data: any, token: any) => http.put(`/updatedefinition/${id}`, data, token)

  public updateItem = (id: string | number, data: any, token: any) => http.put(`/updateitem/${id}`, data, token)

  public desableUser = (id: string | number, token: any) => http.put(`/desable/${id}`, token)

  public enableUser = (id: string | number, token: any) => http.put(`/enable/${id}`, token)

  public findByNameOrMatricule = (motCle: string, token: any) => http.get(`/useractive/${motCle}`, token)

  public findDefinition = (motCle: string , token: any) => http.get(`/definition/${motCle}`, token)

    public findItem = (motCle: string , token: any) => http.get(`/item/${motCle}`, token)
}
import React, { useState, useEffect, FormEvent} from "react";
import Button from "react-bootstrap/Button";
import API from '../services/API/API'
import history from '../services/history/history'
import "../styles/Login.css";

const Login = () => {
    const [, setId] = useState(null)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [, setRole] = useState('')
    const [error, ] = useState('')
    const [path, setPath] = useState('/ClientList')

    const signin = async () => {
        const data = JSON.stringify({
          "email": email,
          "password": password,
        });
        try {
            const response = await API.User.signin(data)
            setId(response.data.id)
            setEmail(response.data.email)
            setPassword(response.data.password)
            setSubmitted(true)
            setRole(response.data.roles[0])
            const {roles} = response.data
              roles.forEach((item: any) => {
                if (item === 'ROLE_USER') {
                  sessionStorage.setItem('role', "user")
                  sessionStorage.setItem('background','/dist/img/bg-client-page.png')
                  sessionStorage.setItem('bgmenu','/dist/img/bg-menu-client.png')
                  setPath('/Assignment')
                }
                else if(item === 'ROLE_MODERATOR') {
                  sessionStorage.setItem('role', "moderator")
                  sessionStorage.setItem('background','/dist/img/bg-team-page.jpg')
                  sessionStorage.setItem('bgmenu','/dist/img/bg-menu-team.png')
                  setPath('/Dashboard')
                }
                else if(item === 'ROLE_COLLABORATOR') {
                  sessionStorage.setItem('role', "collaborator")
                  sessionStorage.setItem('background','/dist/img/bg-collabo-page.png')
                  sessionStorage.setItem('bgmenu','/dist/img/bg-menu-col.png')
                  setPath('/ClientList')
                }
                else {
                  sessionStorage.setItem('role', "admin")
                  sessionStorage.setItem('background', '/dist/img/bg-admin.png')
                  sessionStorage.setItem('bgmenu','/dist/img/bg-menu-admin.png')
                  setPath('/Dashboard')
                }
              });
            sessionStorage.setItem('id', response.data.id)
            sessionStorage.setItem('username', response.data.username)
            sessionStorage.setItem('accessToken', response.data.accessToken)
            sessionStorage.setItem('client_id', response.data.id_client)
        }
        catch (err) {
            console.log(err)
        }
    }

    const onKeyDown = async (event: any) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          await signin();
        }
    }
    
    const onChangeEmail = (e: FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)
    }
    
    const onChangePassword = (e: FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value)
    }

    useEffect(() => {
        if (submitted) {
            history.push(path)
            window.location.reload()
        }
    }, [submitted])

    return (
        <div>
            <div className="Login" >
              <div className="image">
                <img src="/dist/img/logo-olona-teams-black.png" alt="Logo Olona Teams" />
              </div>
              <div className="formLogin">
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    required
                    value={email}
                    onChange={onChangeEmail}
                    name="email"
                  />
                </div>
  
                <div className="form-group">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="password"
                    required
                    value={password}
                    onChange={onChangePassword}
                    name="password"
                    onKeyDown= {onKeyDown}
                  />
                </div>
                <Button size="lg" type="submit" onClick={signin}>
                  Login
                </Button>
                </div>
                <span style={{fontSize:"large", color:"red", display:"block"}}>{error}</span>
            </div>
        </div>
      )
}

export default Login
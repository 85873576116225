import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import API from '../services/API/API';
import PageHead from "../components/pageHead";
import EvaluationMoisComponent from "../components/evaluationmois";

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

const EvaluationCollaborateur = () => {
    const role = sessionStorage.getItem("role");
    const [collaborateurs, setCollaborateurs] = useState<Array<any>>([])
    // eslint-disable-next-line no-unused-vars
    const [evaluations, setEvaluations] = useState<Array<any>>([])
    const [searchTitle, setSearchTitle] = useState('')
    const [show, setShow] = useState(false)
    const [activePage, setActivePage] = useState(1)
    const [totalCustomers, ] = useState(0)
    const [search, ] = useState(false)
    const [id_user, ] = useState(sessionStorage.getItem('client_id') ?? '')
    const [currentCollaborateur, setCurrentCollaborateur] = useState('')

     // eslint-disable-next-line no-unused-vars
     const retrieveEvaluationSommes = async (pageNumber: number) => {
        /* service.getCollaboratorEvaluation(id_user, token)
        .then((response) => {
            setState({
                evaluations: response.data,
            });
            //console.log(response.data);
        })
        .catch((e) => {
            console.log(e);
        }); */
        try {
            const response = await API.CollaboratorEvaluation.getCollaboratorEvaluation(id_user, token)
            setEvaluations(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleClose = async () => {
        setShow(false)
        await retrieveEvaluationSommes(1);
    }

    const handleShow = () => {
        setShow(true)
    }

    const evaluer = (collaborateur_id: string) => {
        // setState({ show: true });
        setCurrentCollaborateur(collaborateur_id)
        handleShow()
    }
    
    /* const retrieveEvaluations = async (pageNumber: number) => {
        try {
            const response = await API.Collaborator.getUserCollaborator(id_user, token)
            setEvaluations(response.data)
        }
        catch (error) {
            console.log(error)
        }
    } */
    const retrieveCollaborators = async () => {
        try {
            const response = await API.CollaboratorEvaluation.getCollaboratorByClientId(id_user, token)
            setCollaborateurs(response.data)
        }   
        catch (error) {
            console.log(error)
        } 
    }
    
    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value)
        if (e.currentTarget.value === "") {
            await retrieveEvaluationSommes(activePage)
        } else {
            try {
                const response = await API.CollaboratorEvaluation.find(e.currentTarget.value, id_user, token)
                setEvaluations(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    
    const handlePageChange = async (pageNumber: number) => {
        setActivePage(pageNumber)
        if (!search) await retrieveEvaluationSommes(pageNumber)
    }

    useEffect(() => {
        (async () => {
            await retrieveCollaborators()
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%"}}
          >
            {/* TITLE & BREADCRUMBS */}
            {role !== "collaborator" ? (
             <PageHead title="Mes Collaborateurs" subject="Liste collaborateurs" name="Evaluation" />
  
            ) : null}
  
            {/* SEARCH FORM */}
            {role !== "collaborator" ? (
              <div className="action-container">
                <div className="searchbar-container">
                  <div className="form-inline">
                    <div className="input-group input-group-sm" title="Vous pouvez faire des recherche par nom, premon, note, disponibilté ou status">
                      <span className="universal-length icon-r">
                        <i className="fas fa-search"></i>
                      </span>{" "}
                      <input
                        className="form-control form-control-navbar universal-length recherche"
                        value={searchTitle}
                        onChange={onChangeSearchTitle}
                        type="search"
                        placeholder="Rechercher"
                        aria-label="Search"
                      />
                    </div>
                    
                  </div>
                </div>
                
              </div>
            ) : null}
  
            {/* END SEARCH FORM */}
            <section className="content">
              <div>
                {role !== "collaborator" ? (
                  <div>
                    <table className="table table-curved">
                      <thead >
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Username</th>
                          <th scope="col" style={{width: "20%"}} >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      { collaborateurs.map((collaborateur, i) => (
                            
                          <tr>
                            <td> {i+1} </td>
                            <td> {collaborateur.username} </td>
                            <td>
                                      <Button
                                          className="btn mbtn btn-information"
                                          onClick={() =>
                                            evaluer(collaborateur.id)
                                          }
                                      >
                                          <strong>
                                          Evaluer
                                          </strong>
                                      </Button>
                            </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
  
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={collaborateurs.length}
                      totalItemsCount={totalCustomers}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                 
                ) : (
                  <div>
                    <h1>Bienvenue sur notre plateforme</h1>
                  </div>
                )}
              </div>
            </section>
  
            <Modal
              className="my-modal"
              animation={false}
              show={show}
              onHide={handleClose}
            >
  
              <button type="button" onClick={handleClose} className="close">
                <i className="fas fa-times"></i>
                <span className="sr-only">Close</span>
              </button>
              <Modal.Body>
                <div className="row">
                  
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <EvaluationMoisComponent client={id_user} collaborateur={currentCollaborateur} actionClose={handleClose}/>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            
          </div>
        </div>
      )
}

export default EvaluationCollaborateur
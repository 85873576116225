import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import API from "../services/API/API";
import defaultAvatar from "../assets/images/default_avatar.jpg";

const bgmenu = sessionStorage.getItem("bgmenu");
const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};

const Menu = () => {
    const username = sessionStorage.getItem("username");
    const role = sessionStorage.getItem("role");
    const [counts, setCounts] = useState('')
    const [id_user,] = useState(sessionStorage.getItem("client_id") ?? '')
    const [count_suivi, setCount_suivi] = useState('')
    const [count_talent, setCount_talent] = useState('')

    const getCount = async () => {
        /* service
          .findCountCustmerSpontannee(token)
          .then((response) => {
            this.setState({
              counts: response.data.count,
            });
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Candidate.findCountCustmerSpontannee(token)
            setCounts(response.data.count)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getCountTalent = async () => {
        /* service
          .findCountTalent(token)
          .then((response) => {
            this.setState({
              count_talent: response.data.count,
            });
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Candidate.findCountTalent(token)
            setCount_talent(response.data.count)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getCountSuivi = async () => {
        /* service
          .countSuiviCollaborateur(this.state.id_user, token)
          .then((response) => {
            this.setState({
              count_suivi: response.data.count,
            });
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.countSuiviCollaborateur(id_user, token)
            setCount_suivi(response.data.count)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await getCount()
            await getCountSuivi()
            await getCountTalent()
        })()
    })

    return (
        <div>
          <aside
            className="main-sidebar sidebar-dark-secondary elevation-4"
            style={{
              backgroundImage: `url(${bgmenu})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            {/* Brand Logo */}
            <div>
              <div className="brand-link">
                <img
                  src="/dist/img/logo-olona-teams-white.png"
                  alt="Logo Olona Teams"
                  style={{width: "-webkit-fill-available", display: "flex", marginTop:"auto", padding: "inherit"}}
                />
                </div>
            </div>
            {/* Sidebar */}
            <div className="sidebar">
              {/* Sidebar user panel (optional) */}
              <div className="user-panel mt-3 pb-3 mb-3 d-flex" >
                <div className="image">
                  <img
                    src={defaultAvatar}
                    className="img-circle elevation-2"
                    alt="User"
                  />
                </div>
                <div className="info" style={{whiteSpace:"normal", display:"flex", flexDirection:"row", justifyContent: "center"}}>
                  <b color="white">{username} </b>
                </div>
              </div>
              {/* Sidebar Menu */}
              <nav className="mt-2">
                <ul
                  className="nav nav-pills nav-sidebar flex-column"
                  data-widget="treeview"
                  role="menu"
                  data-accordion="false"
                >
                  {/* Add icons to the links using the .nav-icon class
             with font-awesome or any other icon font library */}
              
                  <li className="nav-item has-treeview menu-open">
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>
                        Dashboard
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {role === "collaborator" ? (
                        <div>
                          <li className="nav-item" title="Vous trouverez laliste des vos client">
                            <NavLink className="nav-link" to="/ClientList" >
                              <i className="fas fa-circle nav-icon" />{" "}Mes clients
                            </NavLink>
                          </li>            
                          {/* <li className="nav-item" title="Vous trouverez la liste des vos client">
                              <NavLink className="nav-link" to="/socialmodule" >
                                <i className="fas fa-circle nav-icon" />{" "}Gestion Réseaux Sociaux
                              </NavLink>
                      </li> */}
                        </div>
                      ) : (
                        <div>
                          {role !== "user" ? (
                            <div>
                              <li className="nav-item" title="Vous trouverez la liste de tout les talents">
                                <NavLink className="nav-link" to="/Dashboard">
                                  <i className="fas fa-circle nav-icon" />
                                  <span>{" "}
                                   Liste des Talents
                                  </span>
                                  <span className="badge badge-info right">
                                    {count_talent}
                                  </span>
                                </NavLink>
                              </li>
  
                              <li className="nav-item" title="Vous trouverez la liste de client prospects">
                                <NavLink className="nav-link" to="/UserList" >
                                  <i className="fas fa-circle nav-icon" />{" "}Liste
                                  des Clients Prospects
                                </NavLink>
                              </li>
                              <li className="nav-item" title="Vous trouverez la liste des candidatures spontannés">
                                <NavLink
                                  className="nav-link"
                                  to="/CandidatSpontannee"
                                >
                                  <i className="fas fa-circle nav-icon" />
                                  <span>{" "}
                                    Liste des candidats <br />
                                    spontanés
                                  </span>
                                  <span className="badge badge-info right">
                                    {counts}
                                  </span>
                                </NavLink>
                              </li>
                              <li className="nav-item" title="Vous trouverez la liste des vos client">
                              <NavLink className="nav-link" to="/ClientList" >
                                <i className="fas fa-circle nav-icon" />{" "}Mes clients
                              </NavLink>
                            </li>
                            
                              {role === "admin" ? (
                                <div>
                                  <li className="nav-item" title="Ici c'est le suivi des clients,vous pourrez faire la suivi des clients, vous trouverez aussi la liste des client, la suivi des clients et les collaborateurs qu'on lui a attribué">
                                    <NavLink
                                      className="nav-link"
                                      to="/SuiviClientList"
                                    >
                                      <i className="fas fa-circle nav-icon" />{" "}
                                      Suivi Clients
                                    </NavLink>
                                  </li>
                                  <li className="nav-item" title="vous trouverez aussi la liste des rapports effectués par tous les collaborateurs">
                                    <NavLink
                                      className="nav-link"
                                      to="/SuiviCollaboratorParJour"
                                    >
                                      <i className="fas fa-circle nav-icon" />{" "}
                                      Suivi Collaborateurs
                                    </NavLink>
                                  </li>
                                  
                                </div>
                              ) : null}
                              
                            </div>
                          ) : (
                            <div>
                              <li className="nav-item" title="Vous trouverez vos liste des candidats qu'on vous a proposé">
                                <NavLink className="nav-link" to="/Assignment">
                                  <i className="fas fa-circle nav-icon" />{" "}Mes
                                  candidats
                                </NavLink>
                              </li>
                              <li className="nav-item" title="Vous trouverez les suivis de tous vos collaborateurs">
                                <NavLink
                                  className="nav-link"
                                  to="/CollaboratorListClient"
                                >
                                  <i className="fas fa-circle nav-icon" />{" "}
                                  Suivi collaborateurs  <span className="badge badge-info right">
                                    {count_suivi}
                                  </span>
                                </NavLink>
                              </li>
                            </div>
                          )}
                        </div>
                      )}
                      {role === "collaborator" ? null : (
                        <div>
                          {role !== "user" ? (
                            <div>
                                <li className="nav-item" title="On ajout un utilisateur tel que collaborteur, equipe olona et administrateur(si vous avez le droit)">
                                <NavLink className="nav-link" to="/Register">
                                  <i className="fas fa-circle nav-icon" />{" "}
                                  Ajouter un Utilisateur
                                </NavLink>
                              </li>
                              <li className="nav-item" title="Vous trouverez vos favoris pour les candidats">
                                <NavLink className="nav-link" to="/Favorites">
                                  <i className="fas fa-circle nav-icon" />{" "}
                                  Mes Favoris
                                </NavLink>
                              </li>
                            </div>
                          ) : (
                            <li className="nav-item" title="Vous trouverez vos favoris pour les candidats">
                              <NavLink className="nav-link" to="/FavoritesClient">
                                <i className="fas fa-circle nav-icon" />{" "}
                                Mes Favoris
                              </NavLink>
                            </li>
                          )}
                          {/* <li className="nav-item" title="Vous trouverez la liste des clients">
                              <NavLink className="nav-link" to="/socialmoduleadmin" >
                                <i className="fas fa-circle nav-icon" />{" "}Gestion Réseaux Sociaux
                              </NavLink>
                          </li> */}
                        </div>
                      )}
                    </ul>
                  </li>
                  <li className="nav-item has-treeview menu-open">
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-table"></i>
                      <p>
                        Evaluation
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                    {role === "admin" ? (
                        <div>
                          <li className="nav-item" title="Vous trouverez la liste des évaluations de tous les claborateurs">
                            <NavLink className="nav-link" to="/EvaluationsAdmin" >
                              <i className="fas fa-circle nav-icon" />{" "}Liste des évaluations
                            </NavLink>
                          </li>
                          
                          <li className="nav-item" title="Vous trouverez laliste des vos client">
                            <NavLink className="nav-link" to="/Definition" >
                              <i className="fas fa-circle nav-icon" />{" "}Definition
                            </NavLink>
                          </li>
                          <li className="nav-item" title="Vous trouverez laliste des vos client">
                            <NavLink className="nav-link" to="/Item" >
                              <i className="fas fa-circle nav-icon" />{" "}Items
                            </NavLink>
                          </li>
                          
                        </div>
                        ) :
                        (
                          role === "user"  ?
                          <div>
                            <li className="nav-item" title="Vous trouverez laliste des vos client">
                            <NavLink className="nav-link" to="/Collaborateurs" >
                              <i className="fas fa-circle nav-icon" />{" "}Liste collaborateurs
                            </NavLink>
                          </li>
                          <li className="nav-item" title="Vous trouverez laliste des vos client">
                            <NavLink className="nav-link" to="/Collaborateur" >
                              <i className="fas fa-circle nav-icon" />{" "}Liste des évaluations
                            </NavLink>
                          </li>
                          
                        </div>
                        :
                        <li className="nav-item" title="Vous trouverez laliste des vos client">
                        <NavLink className="nav-link" to="/Evaluationsc" >
                          <i className="fas fa-circle nav-icon" />{" "}Mes évaluations
                        </NavLink>
                      </li>
                        )
                        }
                      </ul>
                  </li>
                  {role !== "collaborator" && role !== "moderator" ? 
                  <li className="nav-item has-treeview menu-open">
                    <a href="#" className="nav-link">
                      <i className="nav-icon fas fa-edit"></i>
                      <p>
                        Présence
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </a>
                    <ul  className="nav nav-treeview">
                      {role === "admin"? 
                        <li className="nav-item" title="Vous trouverez la liste de présence de vos collaborateurs ainsi, total des heures effectué par jour">
                                <NavLink className="nav-link" to="/PresenceCollaboratorParJour">
                                <i className="fas fa-circle nav-icon" />{" "}Présences
                                collaborateurs
                        </NavLink>
                          </li>
                          :
                          role === "user" ?
                          <li className="nav-item" title="Vous trouverez la liste de présence de vos collaborateurs ainsi, total des heures effectué par jour">
                          <NavLink className="nav-link" to="/PresenceCollaborator">
                            <i className="fas fa-circle nav-icon" />{" "}Présences
                            collaborateurs
                          </NavLink>
                          </li>
                          : null
                          
                        }
                    </ul>
                  </li>
                  : null
                  }
  
                </ul>
              </nav>
              {/* /.sidebar-menu */}
            </div>
            {/* /.sidebar */}
          </aside>
        </div>
      )
}

export default Menu
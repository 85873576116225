import React, { useState, useEffect } from "react";
import Moment from "moment";
import API from "../services/API/API";
import PageHead from "../components/pageHead";
import "moment/locale/fr";
import "../styles/styles.css";

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

interface IParams {
    id: string
    client_id: string
}

interface IMatch {
    params: IParams
}
interface IProps {
    match: IMatch
}

const SuiviParCollaborator = ({ match }: IProps) => {
    const [client_id, ] = useState(match.params.client_id)
    const [id_user, ] = useState(match.params.id)
    const [username, setUsername] = useState('')
    const [suivis, setSuivis] = useState<Array<any>>([])

    const getSuiviParCollaborator = async () => {
        /* service
          .getSuiviParCollaborator(this.state.id_user, this.state.client_id, token)
          .then((response) => {
            this.setState({
              suivis: response.data,
              username: response.data[0].user.username
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getSuiviParCollaborator(id_user, client_id, token)
            setSuivis(response.data)
            setUsername(response.data[0].user.username)
            console.log(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        Moment.locale("fr");
        (async () => {
            await getSuiviParCollaborator()
        })()
    })
    
    return (
        <div
          className="content-wrapper"
          style={{
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%"
          }}
        >
          <PageHead title={username} subject="Liste des rapports" name="Rapport" />
          {suivis.map((suivi) => {
            const date = Moment(suivi.createdAt).format("LLL");
            return (
              <div className="card-comment" style={{
                padding: `5px`,
                borderRadius: 5,
                marginTop: `2px`,
                backgroundColor: `white`
              }}>
                <div className="comment-text" style={{
                  margin: `10px`,
                }}>
                  <span className="username">
                    <b>{suivi.user.username}</b>
                    <span className="text-muted float-right">
                      {date}
                    </span>
                  </span>
                  {/* eslint-disable-next-line react/no-danger */}
                  <td dangerouslySetInnerHTML={{ __html: suivi.message }} />
                </div>
              </div>
            );
          })}
        </div>
    )
}

export default SuiviParCollaborator
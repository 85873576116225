import React, { useState, useEffect } from 'react'
import API from '../services/API/API'
import BoxItem from '../components/boxItem';
import PageHead from "../components/pageHead";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}

interface IProps {
    match: IMatch
}

const ClientCandidatList = ({ match }: IProps) => {
    const role = sessionStorage.getItem('role') ?? '' ;
    const [assignments, setAssignments] = useState<Array<any>>([])
    const [favorites, setFavorites] = useState<Array<any>>([])
    const [id_user,] = useState(match.params.id)

    const retrieveFavorites = async () => {
        /* service.getFavorites(id_user, token)
          .then(response => {
            setState({
              favorites: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Favorite.getFavorites(id_user, token)
            setFavorites(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const retrieveAssignments = async () => {
        /* service.getAssignments(id_user, token)
          .then(response => {
            setState({
                assignments: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Assignement.getAssignments(id_user, token)
            setAssignments(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const deleteFavorites = async (id: string, id_candidat: string) => {
        /* service.deleteFavorites(id, id_candidat, token)
          .then(response => {
            retrieveAssignments()
            retrieveFavorites()
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            await API.Favorite.deleteFavorites(id, id_candidat, token)
            await retrieveAssignments()
            await retrieveFavorites()
        } catch (error) {   
            console.log (error)
        }
    }

    const deleteAssignments = async (id: string, id_candidat: string) => {
        /* service.deleteAssignment(id, id_candidat, token)
          .then(response => {
            console.log(response.data);
            deleteFavorites(id, id_candidat)
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            await API.Assignement.deleteAssignment(id, id_candidat, token)
            await deleteFavorites(id, id_candidat)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveAssignments()
            await retrieveFavorites()
        })()
    }, [])

    return (
        <div>
          <div className="content-wrapper" style={{backgroundImage: `url(${background})`,
         backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat"}} >
              <PageHead
  
            title="Liste des candidats proposés"
  
            subject="Liste candidats"
  
            name="Client"
  
          ></PageHead>
  
            <section className="content">
              <div>
                <div className="row">
                  {
                  
                  assignments.map((assignment) => {
                    console.log(assignment)
                    return assignment.customer.map((candidat: any) => (
                        <BoxItem mode="assignment" user={id_user} roleProps={role} candidat={candidat} actionFavorite={deleteFavorites} actionAssignment={deleteAssignments} />
                      ))
                  })}
                </div>
              </div>
              <div className="sep"></div>
  
              <div className="sep"></div>
  
              <div>
  
                  <h1><b style={{color : "white"}}>Ses favoris</b></h1>
  
              </div>
  
              <div>
                <div className="row">
                  {favorites.map((favoris) => favoris.customers.map((candidat: any) => (
                        <BoxItem mode="assignmentClient" user={id_user} roleProps={role} candidat={candidat} actionFavorite={deleteFavorites} />
                      )))}
                </div>
              </div>
            </section>
          </div>
        </div>
      )
}

export default ClientCandidatList
import React, { useState, useEffect, FormEvent } from "react";
import API from '../services/API/API'
/* eslint-disable-next-line import/extensions */
// import defaultAvatar from '../assets/img/default_avatar.jpg'

const Registration = () => {
    const [id, setId] = useState(null)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [note, setNote] = useState('')
    const [poste, setPoste] = useState('')
    const [sousposte, setSousposte] = useState('')
    const [point_fort, setPoint_fort] = useState('')
    const [point_faible, setPoint_faible] = useState('')
    const [evaluation, setEvaluation] = useState('')
    const [disponibilite, setDisponibilite] = useState('')
    const [prix, setPrix] = useState('')
    const [prix_admin,] = useState('')
    const [comment, setComment] = useState('')
    const [selectedFile, setSelectedFile] = useState<File | string>('')
    const [email, setEmail] = useState('')
    const [submitted, setSubmitted] = useState(false)
    const [list, setList] = useState<Array<any>>([])
    const [skills, setSkills] = useState([])
    const [postes, setPostes] = useState([])
    const [isOk, setIsOk] = useState(false)
    const [noteSelected, setNoteSelected] = useState<Array<any>>([])
    const [placeholder, setPlaceholder] = useState('')

    const onChangeValue = (e: FormEvent<HTMLInputElement>, i: number, nom: string) => {
        const l = [...list];
        l[i] = { id_candidat: id, name: nom, note: e.currentTarget.value }
        setList(l)

        const n = [...noteSelected];
        n[i] = e.currentTarget.value
        setNoteSelected(n)
    }

    const onChangeFileCv = (e: FormEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files ? e.currentTarget.files[0] : ''
        // Update the state 
        setSelectedFile(file)
    };

    const onChangeFirstname = (e: FormEvent<HTMLInputElement>) => {
        setFirstname(e.currentTarget.value)
    }

    const onChangeMail = (e: FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)
    }

    const onChangeLastname = (e: FormEvent<HTMLInputElement>) => {
        setLastname(e.currentTarget.value)
    }

    const onChangeNote = (e: FormEvent<HTMLInputElement>) => {
        setNote(e.currentTarget.value)
    }

    const onChangePoste = (e: FormEvent<HTMLSelectElement>) => {
        setPoste(e.currentTarget.value)
        if(poste === "1")
            setPlaceholder('ex : Développeur Java, etc ...')
    }

    const onChangeSousPoste = (e: FormEvent<HTMLInputElement>) => {
        setSousposte(e.currentTarget.value)
    }

    const onChangePrix = (e: FormEvent<HTMLInputElement>) => {
        setPrix(e.currentTarget.value)
    }

    /* const onChangePrixAdmin = (e: FormEvent<HTMLInputElement>) => {
        setPrix_admin(e.currentTarget.value)
    } */

    /* const onChangeEvaluation = (e: FormEvent<HTMLInputElement>) => {
        setEvaluation(e.currentTarget.value)
    } */

    const onChangePointfaible = (e: FormEvent<HTMLInputElement>) => {
        setPoint_faible(e.currentTarget.value)
    }

    const onChangePointFort = (e: FormEvent<HTMLInputElement>) => {
        setPoint_fort(e.currentTarget.value)
    }

    const onChangeDisponibilite = (e: FormEvent<HTMLInputElement>) => {
        setDisponibilite(e.currentTarget.value)
    }

    /* const onChangeComment = (e: FormEvent<HTMLInputElement>) => {
        setComment(e.currentTarget.value)
    } */
    
    const retrieveSkills = async (id_poste: string) => {
        try {
            const response = await API.Post.getSkillsByPoste(id_poste)
            setSkills(response.data)
        }
        catch (error) {
            console.log(error)
        }
    } 

    const fileupload = async (id_candidat: string) => {
        const formData = new FormData();
        formData.append(`file`, selectedFile);
        formData.append('id_candidat', id_candidat);
        try {
            await API.File.uploadFile(formData)
            setSubmitted(true)
            retrieveSkills(poste)
        }
        catch (error) {
            console.log(error)
        }
    }

    const saveCandidat = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            firstname,
            lastname,
            note,
            poste,
            sousposte,
            evaluation,
            disponibilite,
            prix,
            prix_admin,
            point_faible,
            point_fort,
            comment,
            email,
            user : "candidat",
            etat: 'NOT PUBLISHED',
            etat_sousposte : 'NOT PUBLISHED',
        };

        /* service.create(data)
            .then(response => {
                setState({
                    id: response.data.id,
                    firstname: response.data.firstname,
                    lastname: response.data.lastname,
                    note: response.data.note,
                    poste: response.data.poste,
                    evaluation: response.data.evaluation,
                    disponibilite: response.data.disponibilite,
                    prix: response.data.prix,
                    point_faible: response.data.point_faible,
                    point_fort: response.data.point_fort,
                    comment: response.data.comment
                });
                fileupload(response.data.id)
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.Candidate.create(data)
            setId(response.data.id)
            setFirstname(response.data.firstname)
            setLastname(response.data.lastname)
            setNote(response.data.note)
            setPoste(response.data.poste)
            setEvaluation(response.data.evaluation)
            setDisponibilite(response.data.disponibilite)
            setPrix(response.data.prix)
            setPoint_faible(response.data.point_faible)
            setPoint_fort(response.data.point_fort)
            setComment(response.data.comment)
            await fileupload(response.data.id)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    /* const newCandidat = () => {
        setId(null)
        setFirstname('')
        setLastname('')
        setNote('')
        setPoste('')
        setPoint_faible('')
        setPoint_fort('')
        setEvaluation('')
        setDisponibilite('')
        setPrix('')
        setPrix_admin('')
        setComment('')
        setSousposte('')
        setEmail('')
        setSelectedFile('')
        setAvatar('')
        setSubmitted(false)
        setList([])
        setSkills([])
        setPostes([])
        setPosteSelected('')
        setIsOk(false)
        setNoteSelected([])
    } */

    const retrievePostes = async () => {
        try {
            const response = await API.Post.getPostes()
            setPostes(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    
    
    const saveEvaluation = async () => {
        const data = {
          data: list,
        };

        try {
            await API.Evaluation.addEvaluation(data)
            setIsOk(true)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrievePostes()
        })()
    }, [])

    return (
        <div>
            {/* Main content */}
            <section className="content">
            <div>
          {isOk ? (
              <div className="submitted">
              <div style={{textAlign: 'center'}}>
                  <i className="ok fas fa-3x fa-check-circle"></i>
                  <h3><strong>Votre candidature est enregistrée avec succès. Nous vous contacterons ultérieurement. Merci de nous avoir fait confiance.</strong></h3>
                  <h2><strong>Visitez notre page <a href="https://olona-outsourcing.com/">Olona Prod</a></strong></h2>
              </div>
              {/* <button className="btn btn-success" onClick={newCandidat}>
                  Ajouter un autre candidat 
              </button> */}
          </div>
            ) : (
                <div className="submit-form">
                    {submitted ? (
                        <div className="submitted">
                           <div>
               <p>Completez l`évaluation</p>
               <div>
                 <h3>Auto évaluation</h3>
                 {skills.map((skill: any) => (
                   skill.skills.map((skil: any, i: number) => (
                     // eslint-disable-next-line react/no-array-index-key
                     <div className="form-group" key={i}>
                       <label htmlFor={skil.name}>{skil.name}<span className="custom-text-blue">(  {noteSelected[i] != null ? ( `${noteSelected[i] }/10` ) : `${0  }/10`})</span></label>
                       <input
                         type="range" min="0" max="10"
                         className="form-control"
                         id={skil.name}
                         required
                         value={noteSelected[i] != null ? ( noteSelected[i]) : 0}
                         onChange={e => onChangeValue(e, i, skil.name)}
                         name={skil.name}
                       />
                     </div>
                   ))))}

               </div>
               <div className="form-group">
                 <button type="button" onClick={saveEvaluation} className="btn btn-success">
                   Enregistrer
                 </button>
               </div>
             </div>
                        </div>
                    ) : (
                        <form onSubmit={saveCandidat}>
                        <div className="row box-register">
                            <div className="col-12 pt-5 pb-5" style={{padding:"8%"}}>
                                <div className="title-container">
                                    <div className="title">
                                        <h3 style={{fontSize:"3.4em"}}>Veuillez compléter le formulaire</h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="lastname">Prénom(s)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastname"
                                                required
                                                value={lastname}
                                                onChange={onChangeLastname}
                                                name="lastname"
                                                placeholder="ex : John"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <div className="form-group">
                                            <label htmlFor="firstname">Nom</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstname"
                                                required
                                                value={firstname}
                                                onChange={onChangeFirstname}
                                                name="firstname"
                                                placeholder="ex : Smith"
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="poste">Secteur d`activité</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select  required  value={poste} onChange={onChangePoste} placeholder="Choisir Votre Poste">
                                                <option value="" ></option>
                                                {postes.map((item: any) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="email">Poste</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="sousposte"
                                                required
                                                value={sousposte}
                                                onChange={onChangeSousPoste}
                                                name="sousposte"
                                                placeholder={placeholder}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                required
                                                value={email}
                                                onChange={onChangeMail}
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="note">Note <span className="custom-text-blue">( {note} / 10 )</span><i className="fas fa-info-circle" title={`Combien vous notez-vous en temps que ${sousposte}`}></i></label>
                                            <input
                                                type="range" min="0" max="10"
                                                className="form-control"
                                                id="note"
                                                required
                                                value={note}
                                                onChange={onChangeNote}
                                                name="note"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group custom-range">
                                            <label htmlFor="point_fort"><i className="ok fas fa-check-circle"></i> Point fort</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="point_fort"
                                                required
                                                value={point_fort}
                                                onChange={onChangePointFort}
                                                name="point_fort"
                                                placeholder="ex : sérieux"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="point_faible"><i className="oknt fas fa-times-circle"></i> Point faible</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="point_faible"
                                                required
                                                value={point_faible}
                                                onChange={onChangePointfaible}
                                                name="point_faible"
                                                placeholder="ex : retardataire"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="disponibilite">Disponibilité</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="disponibilite"
                                                required
                                                value={disponibilite}
                                                onChange={onChangeDisponibilite}
                                                name="disponibilite"
                                                placeholder="ex : Disponible"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="prix">Prétention salariale<span className="custom-text-blue"> ( Ar / mois )</span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="prix"
                                                required
                                                value={prix}
                                                onChange={onChangePrix}
                                                name="prix"
                                                placeholder="Ar / mois"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="image" className=" control-label">CV</label>
                                            <input required className="form-control-file" type="file" name="profile" onChange={onChangeFileCv} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="w-100 btn-register btn btn-success">Accepter et Suivant</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                    )}
                </div>
                )}
            </div>
            </section>
        </div>
    )
}

export default Registration
import React from 'react';
import moment, { Moment } from 'moment';

interface ICommentateur {
    username: string
    id: string | number
}

interface IComment {
    createdAt: Moment | Date | number | string
    commentateur: ICommentateur
    comment: string
}

interface IProps {
    comment: IComment
}

const Comment = ({ comment }: IProps) => {
    /* const senderId= sessionStorage.id;
    const senderUsername = sessionStorage.username;
    const isthisme =  comment.commentateur.username === senderUsername && comment.commentateur.id === senderId ? "me" : ""; */
    const createdAt = moment(comment.createdAt).format("LLL");
    
    return (
        <tr className="">
            <td className="view-message dont-show"><strong>{comment.commentateur.username}</strong></td>
            <td className="view-message ">{comment.comment}</td>
            <td className="view-message  text-right">{createdAt}</td>
        </tr>
    )
}
export default Comment;
/* eslint-disable react/jsx-no-script-url */
import React from 'react'
import { Link } from 'react-router-dom'
import Button from "react-bootstrap/Button";
// import history from '../services/history/history';

const Header = () => {
    // const username = sessionStorage.getItem('username')

    const logoutOnClick = () => {
        sessionStorage.clear()
        window.location.href = '/Login'
    }

    return (
        <div>
          <nav className="main-header navbar navbar-expand navbar-white navbar-light">
            {/* Left navbar links */}
            <ul className="navbar-nav" style={{marginLeft:"1px"}}>
              <li className="nav-item">
                <a className="nav-link universal-length btn btn-warning btn-block" style={{display:"table"}} data-widget="pushmenu" href="#" role="button"><i  className="fas fa-bars"/></a>
              </li>
              <li className="nav-item">
                {/* eslint-disable-next-line no-script-url */}
                <a className="nav-link universal-length btn btn-primary btn-block" style={{display:"table", marginLeft:"10%"}} href="javascript:history.go(-1)" data-widget="iframe-scrollleft"><i className="fas fa-angle-double-left"> RETOUR </i></a>
              </li>
            </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
              {/* Messages Dropdown Menu */}
              {/* <li className="nav-item" style={{marginRight: "2%"}}>
                <a className="nav-link universal-length btn btn-warning btn-block" style={{display:"table", color:"white", fontWeight:"bold"}} data-widget="control-sidebar" data-toggle="dropdown" data-slide="true" onClick={logoutOnClick} href="#">
                 <i className="fas fa-bell" />
                </a>
                
              </li>
              <li className="nav-item" style={{marginRight: "2%"}}>
                <a className="nav-link universal-length btn btn-primary btn-block" style={{display:"table", color:"white", fontWeight:"bold"}} data-widget="control-sidebar" data-toggle="dropdown" data-slide="true" onClick={logoutOnClick} href="#">
                 <i className="fas fa-comments" /> Chat
                </a>
                
              </li>
              <li className="nav-item" style={{marginRight: "2%"}}>
                <a className="nav-link universal-length btn btn-info btn-block" style={{display:"table", color:"white", fontWeight:"bold"}} data-widget="control-sidebar" data-toggle="dropdown" data-slide="true" onClick={logoutOnClick} href="#">
                 <i className="fas fa-user" /> {username}
                </a>
                
              </li> */}
              <li className="nav-item" style={{marginRight: "2%"}}>
                <a className="nav-link universal-length btn btn-danger btn-block" style={{display:"table", color:"white", fontWeight:"bold"}} data-widget="control-sidebar" data-toggle="dropdown" data-slide="true" onClick={logoutOnClick} href="#">
                 <i className="fas fa-sign-out-alt" /> Se déconnecter
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                  <div className="dropdown-divider" />
                  <Link to="/Login">
                    <Button size="lg" type="submit" className="dropdown-item dropdown-footer" onClick={logoutOnClick}>
                      Deconnecter
                    </Button>
                  </Link>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      )
}

export default Header
import http from '../APIs/olonaTeams'

export default class CollaboratorAPI {
     // collaborator
  public getClientCollaborator = (id_user: string | number, token: any) => http.get(`/clientCollaborateur/${id_user}`, token)

  public getUserCollaborator = (id_client: string | number, token: any) => http.get(`/userCollaborateur/${id_client}`, token)

  public getAllCollaborator = (token: any) => http.get(`/collaborators`, token)

  public addCollaborator = (data: any, token: any) => http.post(`/collaborator`, data, token)

  public getAllCollaboratorClient = (id_client: string | number, token: any) => http.get(`/suiviparcollaboratorclient/${id_client}`, token)

  public searchColaborateur = (id_client: string | number, nom: string, token: any) => http.get(`/searchCollaborateur/${id_client}?nom=${nom}`, token)

  public searchMultipleColaborateur = (id_client: string | number, nom: string, date_start: string | number, date_end: string | number, token: any) => http.get(`/searchMultipleCollaborateur/${id_client}?nom=${nom}&start=${date_start}&end=${date_end}`, token)

  public searchAllColaborateur = (nom: string, token: any) => http.get(`/searchAllCollaborateur?nom=${nom}`, token)

  public searchMultipleAllColaborateur = (nom: string,date_start: string | number, date_end: string | number, token: any) => http.get(`/searchMultipleAllCollaborateur?nom=${nom}&start=${date_start}&end=${date_end}`, token)
  

  // suiviColaborator
  public getSuiviCollaborator = (id_user: string | number, id_client: string | number, token: any) => http.get(`/suivicollaborator/${id_user}/${id_client}`, token)

  public getSuiviParCollaborator = (id_user: string | number, id_client: string | number, token: any) => http.get(`/suiviparcollaborator/${id_user}/${id_client}`, token)

  public getAllSuiviCollaborator = (token: any) => http.get(`/suivicollaborators/`, token)

  public createSuiviCollaborator = (data: any, token: any) => http.post(`/suivicollaborator`, data, token)

  public countSuiviCollaborateur = (id_client: any, token: any) => http.get(`/count/${id_client}`, token)

  public readSuiviCollaborateur = (id_client: any, token: any) => http.get(`/readsuivicollaborator/${id_client}`, token)

  public deleteCollaborateurClient = (id: string | number, client_id: string | number, token: any) => http.delete(`/collaborateurClient/${id}/${client_id}`, token)
}
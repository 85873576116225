import React from 'react'

interface IProps {
    title: string
    name: string
    subject: string
}

const PageHead = ({ title, name, subject }: IProps) => (
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0">{title}</h1>
              </div>
              <div className="col-sm-6" style={{
                verticalAlign: 'middle'
              }}>
                <p className="breadcrumb float-sm-right" style={{ fontSize: 15, color: '#D3D3D3' }}>
                  <span style={{color: "white", fontWeight:"bold"}}>{` ${name} `}</span> / <span style={{color: "white", fontWeight:"bold"}}>{` ${subject} `}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )

export default PageHead
import React, { useEffect, useState } from "react";
import Moment from 'moment';
import API from '../services/API/API'
import SinglePagePDFViewer from "../views/pdf/single-page";
import PageHead from "../components/pageHead";
import defaultAvatar from '../assets/images/default_avatar.jpg'
import '../styles/styles.css'

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')
const URL_CV = "https://www.api.olonateams.com/uploads/"

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}

interface IProps {
    match: IMatch
}

const DetailClientSuivi = ({ match }: IProps) => {
    const [id,] = useState(match.params.id)
    const [users, setUsers] = useState<Array<any>>([])   

    const getDetailUser = async () => {
        /* service.getDetailClient(this.state.id, token)
            .then(response => {
                this.setState({
                    users: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.Client.getDetailClient(id, token)
            setUsers(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await getDetailUser()
        })()
    }, [])

    return (
        <div >
            {users.map((user) => (
                <div className="content-wrapper" style={{backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%"}} >
                    <PageHead title={user.company} subject="Fiche client" name="Client"/>
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div>
                            {/* Small boxes (Stat box) */}
                            <div className="row">
                                {/* USER INFO */}
                                <div className="user-info-container col-md-5 col-lg-5 mt-2 col-xs-12">
                                    <div className="box-item detail">
                                        {/* small box */}
                                        <div className="">
                                            <div className="row r-1">
                                                <div className="col-12 text-al">
                                                    <div className="image image-olona detail">
                                                        <img src={defaultAvatar} className="" alt="User" />
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3 general-infos text-center">
                                                    <p className="name text-black"><strong>{user.company}</strong></p>
                                                    <p className="poste"><strong>Date de debut : {Moment(user.date_start).format("LLL")} </strong></p>
                                                    <div className="sep"></div>
                                                    <p className="poste"><strong>Durée du contrat : {user.duration}</strong></p>

                                                </div>
                                            </div>
                                            <div className="sep"></div>
                                            <div className="sep"></div>
                                            <hr />
                                            <div className="row r-2">
                                                <div className="col-12">
                                                    {user.tacite ?
                                                        <div>
                                                        <h4>Tacite de reconduction<i className="ok fas fa-check-circle"></i></h4>
                                                        <p className="text-black">OUI</p>
                                                        </div>
                                                        :
                                                        <div>
                                                        <h4>Tacite de reconduction<i className="oknt fas fa-times-circle"></i></h4>
                                                        <p className="text-black">NON</p>
                                                        </div>
                                                    }
                                                    {/* <div class="sep"></div> */}
                                                </div>
                                            </div>
                                            <div className="row r-2 r-4">
                                                <div className="col-12">
                                                    <h4>Contacts</h4>
                                                    <div className="sep"></div>
                                                    <div className="table-responsive">
                                                    <table className="table">
                                                        <thead className="thead-dark">
                                                            <tr>
                                                                <th scope="col">Nom</th>
                                                                <th scope="col">Prénom</th>
                                                                <th scope="col">Status</th>
                                                                <th scope="col">Phone</th>
                                                                <th scope="col">email</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {user.contacts.map((contact: any) => (
                                                                <tr>
                                                                    <td><strong>{contact.name}</strong></td>
                                                                    <td>{contact.lastname}</td>
                                                                    <td>{contact.status}</td>
                                                                    <td>{contact.phone}</td>
                                                                    <td>{contact.mail}</td>
                                                                </tr>

                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sep"></div>
                                        </div>
                                    </div>
                                    {/* <div className="image">
                  <img width="160px" height="160px" src={'http://localhost:8080/uploads/' + candidat.files[1].name} className="img-circle elevation-2" alt="User Image" />
                </div>
                <div className="inner">
                  <p>{candidat.firstname}/{candidat.postes.name}</p>
                  <p>Commentaire : {candidat.comment}</p>
                </div>

                <p className="small-box-footer">{candidat.postes.name}</p> */}
                                    {/* {role !== 'user'
                  ?
                  <div>
                    <div>
                      <p>Affecter à :</p>
                      <select value={this.state.selectedValue} onChange={this.onChangeSelected}>
                        <option value=""></option>
                        {users.map((user) => (
                          <option value={user.id}>{user.username}</option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <button onClick={() => this.addAssignments(this.state.selectedValue, candidat.id)} className="btn btn-success">
                        Assigner
                      </button>
                    </div>
                  </div>
                  : null} */}
                                </div>

                                {/* PDF */}
                                <div className="user-cv-container col-md-7 col-lg-7 col-xs-12 mt-2">
                                    {/* show PDF as image here */}
                                    <div className="App">
                                        <SinglePagePDFViewer pdf={URL_CV + user.files[0].name} />
                                        <hr />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            ))}
        </div>
    )
}

export default DetailClientSuivi
import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import API from '../services/API/API'
import PageHead from "../components/pageHead";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}

interface IProps {
    match: IMatch
}

const PresenceModuleAdmin = ({ match }: IProps) => {
    const [clients, setClients] = useState<Array<any>>([])

    const retrieveClients = async () => {
        try {
            const response = await API.Collaborator.getClientCollaborator(match.params.id, token)
            setClients(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveClients()
        })()
    }, [])

    return (
        <div>
          <div className="content-wrapper" style={{backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"}}>
            <PageHead
            title="Client(s)"
            subject="Liste des clients"
            name="Client"
          />
            <section className="content">
            <div className="row userList">
              <div className="col-12">
                <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Société</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                  {clients.map((client) => client.collaborators.map((cli: any) => (
                          <tr>
                          <td></td>
                          <td>{cli.company}</td>
                          <td>
                            <Link to={`/DoPresenceAdmin/${  cli.id }/${match.params.id}` } >
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a className="small-box-footer">Faire une présence <i className="fas fa-arrow-circle-right" /></a>
                            </Link>
                          </td>
                        </tr>   
                      )))}
                   </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      )
}

export default PresenceModuleAdmin
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import moment, { Moment } from 'moment';
import ShowMoreText from "react-show-more-text";
import Item from "./Item";

interface IUser {
    username: string   
}

interface IMail {
    createdAt: string | Date | Moment
    user: IUser
    message: string
}

interface IProps {
    data: Array<IMail>
}

const Mailbox = ({ data }: IProps) => {
    const suivi = data;
    const [show, setShow] = useState(false);
    const [currentMail, setCurrentMail] = useState<IMail | null>(null);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = (mail: any) => {
        setShow(true);
        setCurrentMail(mail);
    }
    return (
        
        <div className="mailbox-container">
            
            <table className="table table-inbox table-hover">
                <thead className="thead-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Client</th>
                      <th scope="col">Message</th>
                      <th scope="col" style={{textAlign: "end"}}>Date de création</th>
                    </tr>
                  </thead>
                <tbody>
                    {suivi.map((mail) => (
                        <Item key={`${mail.createdAt}`} clickEvent={() => handleShow(mail)} mail={mail} unread={false} />
                    ))}
                </tbody>
            </table>
            {currentMail != null &&
                <Modal className="my-modal mail" animation={false} show={show} onHide={handleClose}>
                    <button type="button" onClick={handleClose} className="close">
                        <i className="fas fa-times"></i>
                        <span className="sr-only">Close</span>
                    </button>
                    <Modal.Body>
                        <div className="row mail-details">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="title">
                                            <h3 className="sender"><strong>{currentMail.user.username}</strong></h3>
                                            <i className="primary far fa-2x">{moment(currentMail.createdAt).format("LLL")}</i>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="content">
                                            <h3 >
                                            <ShowMoreText
                                                /* Default options */
                                                lines={5}
                                                more="voir plus"
                                                less="voir moins"
                                                anchorClass="my-anchor-css-class"
                                                // onClick={this.executeOnClick}
                                                expanded={false}
                                                width={300}
                                            >
                                            {/* eslint-disable-next-line react/no-danger */}
                                            <p  dangerouslySetInnerHTML={{__html: currentMail.message}}/>
                                            </ShowMoreText>
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </div>
    );
}

export default Mailbox;

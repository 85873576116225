import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import API from '../services/API/API'
import PageHead from "../components/pageHead";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

const ClientCollaboratorList = () => {
    const [clients, setClients] = useState<Array<any>>([])
    const [id_user,] = useState(sessionStorage.getItem('id') ?? '')

    const retrieveClients = async () => {
        /* service.getClientCollaborator(this.state.id_user, token)
          .then(response => {
            this.setState({
                clients: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getClientCollaborator(id_user, token)
            setClients(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveClients()
        })()
    }, [])

    return (
        <div>
          <div className="content-wrapper" style={{backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"}}>
            <PageHead
            title="Mes Clients"
            subject="Liste des clients"
            name="Client"
          />
            <section className="content">
            <div className="row userList">
              <div className="col-12">
                <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Société</th>
                        <th scope="col" colSpan={2} style={{textAlign : "center"}}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                  {clients.map((client: any) => client.collaborators.map((cli: any) => (
                          <tr>
                          <td></td>
                          <td style={{textAlign : "center", verticalAlign : "middle"}}>{cli.company}</td>
                          <td style={{textAlign : "center"}}>
                            <Link to={`/SuiviCollaborator/${  cli.id}`} >
                              <p className="small-box-footer">Faire rapport quotidien <i className="fas fa-arrow-circle-right" /></p>
                            </Link>
                          </td>
                          <td style={{textAlign : "center"}}>
                            <Link to={`/DoPresence/${  cli.id}`} >
                              <p className="small-box-footer">Faire pésence quotidien <i className="fas fa-arrow-circle-right" /></p>
                            </Link>
                          </td>
                        </tr>   
                      )))}
                   </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
        </div>
      )
}

export default ClientCollaboratorList
import React from 'react';
import moment, { Moment } from 'moment';
import 'moment/locale/fr';
import ShowMoreText from "react-show-more-text";

interface IUser {
    username: string   
}

interface IMail {
    createdAt: string | Date | Moment
    user: IUser
    message: string
}

type clickEventFunction = () => void

interface IProps {
    mail: IMail
    // eslint-disable-next-line react/require-default-props
    unread?: boolean
    clickEvent: clickEventFunction
}

// eslint-disable-next-line no-unused-vars
const Item = ({ mail, unread, clickEvent }: IProps) => {
    const  createdAt = moment(mail.createdAt).format("LLL");

    return(
        <tr className="" onClick={clickEvent}>
            <td className="inbox-small-cells"><i className="fa fa-star"></i></td>
            <td className="view-message dont-show"><strong>{mail.user.username}</strong></td>
            <td className="view-message ">
                {/* mail.message */}
                <ShowMoreText
                    /* Default options */
                    lines={5}
                    more="voir plus"
                    less="voir moins"
                    anchorClass="my-anchor-css-class"
                    // onClick={this.executeOnClick}
                    expanded={false}
                    width={300}
                >
                {/* eslint-disable-next-line react/no-danger */}
                <p  dangerouslySetInnerHTML={{__html: mail.message}}/>
                </ShowMoreText>
            </td>
            <td className="view-message  text-right">{createdAt}</td>
        </tr>
    );
}

export default Item;
import React, { useState, FormEvent } from "react";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

/* eslint-disable no-unused-vars */
type actionSearchFunction = (nom: string, mois: string) => void
type actionSearchTwoDateFunction = (nom: string, date_start: Date, date_end: Date) => void
/* eslint-enable no-unused-vars */

interface ICol {
    username: string
}

interface ICollaborator {
    username: string
    collaborators: Array<ICol>
}

interface IProps {
    // eslint-disable-next-line react/require-default-props
    suivi?: string
    collaborator: Array<ICollaborator>
    actionSearch: any
    actionSearchTwoDate: any
}

const BoxSearchPresence = ({ suivi, collaborator, actionSearch, actionSearchTwoDate }: IProps) => {
    // const [id, setId] = useState(null)
    const [username, setUsername] = useState('')
    const [date_start, setDate_start] = useState(new Date())
    const [date_end, setDate_end] = useState(new Date())
    const [mois, setMois] = useState('')
    const [etat, setEtat] = useState('mois')
    const [etatShow, setEtatShow] = useState(false)
    const [rowmois] = useState([{"id":"1", "name":"Janvier"},{"id":"2", "name":"Février"},{"id":"3", "name":"Mars"},
    {"id":"4", "name":"Avril"},{"id":"5", "name":"Mai"},{"id":"6", "name":"Juin"},
    {"id":"7", "name":"Juillet"},{"id":"8", "name":"Aout"},{"id":"9", "name":"Septembre"},
    {"id":"10", "name":"Octobre"},{"id":"11", "name":"Novembre"},{"id":"12", "name":"Décembre"}])

    const onChangeEtat = (e: FormEvent<HTMLInputElement>) => {
        setEtat(e.currentTarget.value)
        if(etat === "mois")
            setEtatShow(false)
        else
            setEtatShow(true)
    }

    const handleSearch = (nom: string, month: string) => {
        actionSearch(nom, month);
        // setId(null)
        setUsername('')
        setDate_start(new Date())
        setDate_end(new Date())
        setMois('')
    }

    const handleSearchTwoDate = (nom: string, start: Date, end: Date) => {
        actionSearchTwoDate(nom, start, end);
        // setId(null)
        setUsername('')
        setDate_start(new Date())
        setDate_end(new Date())
        setMois('')
    }
    
    const onChangeFirstname = (e: FormEvent<HTMLSelectElement>) => {
        setUsername(e.currentTarget.value)
    }

    const onChangeMois = (e: FormEvent<HTMLSelectElement>) => {
        setMois(e.currentTarget.value)
    }
    
    const onChangeDateStart = (date: Date | [Date | null, Date | null ] | null) => {
        if (date) {
            const d = date as Date
            d.setHours(23, 59, 0, 0);
            setDate_start(d)
        }
    }
    
    const onChangeDateEnd = (date: Date | [Date | null, Date | null ] | null) => {
        if (date) {
            const d = date as Date
            d.setHours(23, 59, 0, 0);
            setDate_end(d)
        }
    }

    return (
        <div>
          {/* Main content */}
          <section className="content">
            <div>
              <div className="row box-register">
                <div className="col-10 offset-1 pt-5 pb-5">
                  <div className="title-container">
                      <h2>
                        Recherche par :
                      </h2>
                  </div>
                  <div className="row">
                            <div className="col-3">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="mois"
                                    checked={etat === "mois"}
                                    onChange={onChangeEtat}
                                  />
                                  Mois
                                </label>
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="deux"
                                    checked={etat === "deux"}
                                    onChange={onChangeEtat}
                                  />
                                  Deux date
                                </label>
                              </div>
                            </div>
                            </div>
                  <div className="row">
                      <div className="col-6">
                          <div className="form-group">
                              <label htmlFor="lastname">Nom</label>
                              <select  required style={{ width: '50%'}} value={username} onChange={onChangeFirstname} placeholder="Choisir un collaborateur">
                                  <option value="" ></option>
                                  {collaborator.map((col) => {
                                      if(suivi !== "client"){
                                      return (<option value={col.username}>{col.username}</option>)
                                      }
                                      
                                          const colok = col.collaborators
                                          return (
                                          colok.map((el) => (
                                              <option value={el.username}>{el.username}</option>
                                          )))
                                      
                                  }
                                  )}
                              </select>
                          </div>
                      </div>
                      { etatShow ? null :
                      <div className="col-6">
                          <div className="form-group">
                              <label htmlFor="firstname">Mois</label>
                              <select  required style={{ width: '50%'}} value={mois} onChange={onChangeMois} placeholder="Choisir Votre Présence">
                                  <option value="" ></option>
                                  {rowmois.map((el) => (
                                      <option value={el.name}>{el.name}</option>
                                  ))}
                              </select>
                          </div>
                      </div>
                      }
                  </div>
                  { !etatShow ? null :
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group custom-range">
                        <label htmlFor="date_start">
                           Du
                        </label>
                        <DatePicker
                          selected={new Date(date_start.setHours(0,0,0,0))}
                          className="form-control"
                          onChange={(date) => onChangeDateStart(date)}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label htmlFor="date_end">
                          Au :
                        </label>
                        <DatePicker
                          selected={new Date(date_end.setHours(23,59,0,0))}
                          className="form-control"
                          onChange={(date) => onChangeDateEnd(date)}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                  </div>
                    }
                    { !etatShow ?
                  <div className="row">
                    <div className="col-3">
                      <Button
                        className="universal-length mbtn"
                        onClick={() =>
                          handleSearch(
                            username,
                            mois
                          )
                        }
                      >
                        <strong>
                          <i className="fas fa-search"></i> Chercher
                        </strong>
                      </Button>
                    </div>
                  </div>
                  :
                  <div className="row">
                    <div className="col-3">
                      <Button
                        className="universal-length mbtn"
                        onClick={() =>
                          handleSearchTwoDate(
                            username,
                            date_start,
                            date_end
                          )
                        }
                      >
                        <strong>
                          <i className="fas fa-search"></i> Chercher
                        </strong>
                      </Button>
                    </div>
                  </div>
                  }
                </div>
              </div>
            </div>
          </section>
        </div>
      )
}

export default BoxSearchPresence
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, FormEvent } from "react";
import { Modal } from "react-bootstrap";
import API from '../services/API/API'
import PageHead from "../components/pageHead";

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

const ClientListSocialeModule = () => {
    const [, setClients] = useState<Array<any>>([])
    const [id_user,] = useState(sessionStorage.getItem('id') ?? '')
    const [show, setShow] = useState(false)
    const [showActus, setShowActus] = useState(false)
    const [showStats, setShowStats] = useState(false)
    const [, setDateReport] = useState('')

    const selectDateReports = (e: FormEvent<HTMLSelectElement>) => {
        setDateReport(e.currentTarget.value)
    }

    const handleShow = () => {
        setShow(true)
    }
    
    const handleClose = () => {
        setShow(false)
    }
    
    const handleShowActus = () => {
        setShowActus(true)
    }
    
    const handleCloseActus = () => {
        setShowActus(false)
    }
    
    const handleShowStats = () => {
        setShowStats(true)
    }
    
    const handleCloseStats = () => {
        setShowStats(false)
    }
    
    const retrieveClients = async () => {
        /* service
          .getClientCollaborator(id_user, token)
          .then((response) => {
            setState({
              clients: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getClientCollaborator(id_user, token)
            setClients(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveClients()
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            <PageHead
              title="Gestion des réseaux sociaux"
              subject="Tabelau de bord"
              name="GRS"
            ></PageHead>
            <section className="content">
              <div
                className="content  d-flex flex-column flex-column-fluid"
                id="Sb_content"
              >
                <div className="d-flex flex-column-fluid">
                  <div className=" container-fluid ">
                    <div className="row">
                      <div
                        className="col-sm-12"
                        style={{ paddingBottom: "16px", display: "flex" }}
                      >
                        <div
                          className="col-sm-3 u-border-1 u-border-grey-75 u-container-layout u-valign-middle u-container-layout-1"
                          style={{
                            display: "flex",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <p className="texticon2agrs u-custom-font u-font-montserrat u-text u-text-default u-text-1">
                            <button
                              type="button"
                              className="btngrid2agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-3 u-btn-1 btn font-weight-bolder font-size-h6 px-8 py-4 my-3 col-12"
                              onClick={handleShow}
                            >
                              <span className="u-icon u-icon-1">
                                <i
                                  className="fas fa-users fa-fw"
                                  style={{ fontSize: "18px" }}
                                ></i>{" "}
                                Choisir un compte
                              </span>
                            </button>
                          </p>
                        </div>
                        <div
                          className="col-sm-3 u-border-1 u-border-grey-75 u-container-layout u-valign-middle u-container-layout-1"
                          style={{
                            display: "flex",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <p className="texticon2agrs u-custom-font u-font-montserrat u-text u-text-default u-text-1">
                            <a
                              href="/socialmodule"
                              className="btngrid2agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-3 u-btn-1 btn font-weight-bolder font-size-h6 px-8 py-4 my-3 col-12"
                            >
                              <span className="u-icon u-icon-1">
                                <i
                                  className="fas fa-tachometer-alt text-info fa-fw"
                                  style={{ fontSize: "18px" }}
                                ></i>{" "}
                                Tableau de bord
                              </span>
                            </a>
                          </p>
                        </div>
                        <div
                          className="col-sm-3 u-border-1 u-border-grey-75 u-container-layout u-valign-middle u-container-layout-1"
                          style={{
                            display: "flex",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <p className="texticon2agrs u-custom-font u-font-montserrat u-text u-text-default u-text-1">
                            <button
                              type="button"
                              className="btngrid2agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-3 u-btn-1 btn font-weight-bolder font-size-h6 px-8 py-4 my-3 col-12"
                              onClick={handleShowActus}
                            >
                              <span className="u-icon u-icon-1">
                                <i
                                  className="fas fa-newspaper text-primary"
                                  style={{ fontSize: "18px" }}
                                ></i>{" "}
                                Fils d`actualités
                              </span>
                            </button>
                          </p>
                        </div>
                        <div
                          className="col-sm-3 u-border-1 u-border-grey-75 u-container-layout u-valign-middle u-container-layout-1"
                          style={{
                            display: "flex",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "5px",
                          }}
                        >
                          <p className="texticon2agrs u-custom-font u-font-montserrat u-text u-text-default u-text-1">
                            <button
                              type="button"
                              className="btngrid2agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-3 u-btn-1 btn font-weight-bolder font-size-h6 px-8 py-4 my-3 col-12"
                              onClick={handleShowStats}
                            >
                              <span className="u-icon u-icon-1">
                                <i
                                  className="fas fa-chart-bar text-danger"
                                  style={{ fontSize: "18px" }}
                                ></i>{" "}
                                Voir statistiques
                              </span>
                            </button>
                          </p>
                        </div>
                      </div>
  
                      <div className="col-xl-4 col-sm-12 box-card-padding">
                        <a
                          href="/publish"
                          className="card-custom gutter-b card-stretch"
                        >
                          <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                            <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                              <span className="svg-icon svg-icon-lg svg-icon-success u-icon u-icon-circle u-icon-1 icon-padding-top">
                                <i className="far fa-calendar-check text-success fa-4x"></i>
                              </span>
                              <h3 className="h3list3agrs u-text u-text-1">
                                <a
                                  className="btnlist3agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-4 u-btn-1"
                                  href="#"
                                >
                                  Créer une publication
                                </a>
                              </h3>
                              <p className="textlist3agrs u-text u-text-2">
                                <span className="" style={{ fontWeight: "700" }}>
                                  Retenez ceci :{" "}
                                </span>{" "}
                                {"\n"}
                                <p>
                                  Une personne passionée est souvent passionante !
                                </p>
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
  
                      <div className="col-xl-4 col-sm-12 box-card-padding">
                        <a
                          href="/publish"
                          className="card-custom gutter-b card-stretch"
                        >
                          <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                            <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                              <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                <i className="far fa-calendar-alt text-warning fa-4x picto1grs"></i>
                              </span>
                              <h3 className="h3list3agrs u-text u-text-1">
                                <a
                                  className="btnlist3agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-4 u-btn-1"
                                  href="#"
                                >
                                  Voir le calendrier
                                </a>
                              </h3>
                              <p className="textlist3agrs u-text u-text-2">
                                <span className="" style={{ fontWeight: "700" }}>
                                  Plannifez :{" "}
                                </span>{" "}
                                {"\n"}
                                <p>
                                  Reflechir &amp;&nbsp; Programmer&nbsp; à
                                  l`avance vous fera gagner du temps !
                                </p>
                              </p>
                            </div>
                          </div>
                        </a>
                      </div>
  
                      <div className="col-xl-4 col-sm-12 box-card-padding">
                        <button
                          type="button"
                          onClick={handleShowStats}
                          className="card-custom gutter-b card-stretch"
                        >
                          <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                            <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                              <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                <i className="fas fa-chart-line text-danger fa-4x picto1grs"></i>
                              </span>
                              <h3 className="h3list3agrs u-text u-text-1">
                                <button
                                  type="button"
                                  className="btnlist3agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-4 u-btn-1"
                                  onClick={handleShowStats}
                                >
                                  Voir les statistiques
                                </button>
                              </h3>
                              <p className="textlist3agrs u-text u-text-2">
                                <span className="" style={{ fontWeight: "700" }}>
                                  Suivez vos actions !:{" "}
                                </span>
                                <p>
                                  Bons ou mauvais, les statistiques sont là pour
                                  vous aider à progresser.
                                </p>
                              </p>
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
  
                    <div className="row">
                      <div className="col-xl-12 col-sm-12" style={{ padding: "0" }}>
                        <div className="col-xl-2 col-sm-2 box-card-padding">
                          <a
                            href="/publish"
                            className="card-custom gutter-b card-stretch"
                          >
                            <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                  <i className="fas fa-share-alt text-primary fa-4x picto1grs"></i>
                                </span>
                                <h3 className="h4list4agrs u-text u-text-1">50x&nbsp; Diffusions
                                </h3>
                                <p className="textlist4agrs u-custom-item u-text u-text-custom-color-1 u-text-2"><b>+ 17%&nbsp;</b>
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
  
                        <div className="col-xl-2 col-sm-2 box-card-padding">
                          <a
                            href="/publish"
                            className="card-custom gutter-b card-stretch"
                          >
                            <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-custom-color-4 u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                  <i className="fas fa-comments text-danger fa-4x picto1grs"></i>
                                </span>
                                <h3 className="h4list4agrs u-text u-text-3">500x&nbsp;Réactions
                                </h3>
                                <p className="textlist4bgrs u-custom-item u-text u-text-4"><b>+200 %&nbsp;</b>
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
  
  
                        <div className="col-xl-2 col-sm-2 box-card-padding">
                          <a
                            href="/publish"
                            className="card-custom gutter-b card-stretch"
                          >
                            <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                  <i className="fas fa-edit text-warning fa-4x picto1grs"></i>
                                </span>
                                <h3 className="h4list4agrs u-text u-text-5">12&nbsp;Publications
                                </h3>
                                <p className="textlist4agrs u-custom-item u-text u-text-custom-color-1 u-text-6"><b>+50 %&nbsp;</b>
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
  
                        <div className="col-xl-2 col-sm-2 box-card-padding">
                          <a
                            href="/publish"
                            className="card-custom gutter-b card-stretch"
                          >
                            <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-custom-color-3 u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                  <i className="fas fa-users text-white fa-4x picto1grs"></i>
                                </span>
                                <h3 className="h4list4agrs u-text u-text-7">3000&nbsp;audiences
                                </h3>
                                <p className="textlist4bgrs u-custom-item u-text u-text-8"><b>+ 145 %</b>
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
  
                        <div className="col-xl-2 col-sm-2 box-card-padding">
                          <a
                            href="/publish"
                            className="card-custom gutter-b card-stretch"
                          >
                            <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                  <i className="fas fa-users text-primary fa-4x picto1grs"></i>
                                </span>
                                <h3 className="h4list4agrs u-text u-text-9">+120 Nouveaux abonnées</h3>
                                <p className="textlist4agrs u-custom-item u-text u-text-custom-color-1 u-text-10"><b>+ 300 %</b>
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
  
                        <div className="col-xl-2 col-sm-2 box-card-padding">
                          <a
                            href="/publish"
                            className="card-custom gutter-b card-stretch"
                          >
                            <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-custom-color-2 u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                              <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                  <i className="fas fa-comment-alt text-white fa-4x picto1grs"></i>
                                </span>
                                <h3 className="h4list4agrs u-text u-text-11">+250 Commentaires</h3>
                                <p className="textlist4bgrs u-custom-item u-text u-text-12"><b>+ 121 %</b></p>
                              </div>
                            </div>
                          </a>
                        </div>
  
  
  
  
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6">
                        <div
                          className="card card-custom card-stretch gutter-b"
                          id="ss-reportsDiv"
                        >
                          <div className="card-header border-0 pt-5">
                            <div className="card-title">
                              <div className="card-label">
                                <div className="font-weight-bolder">
                                  Rapport de l`équipe
                                </div>
                                <div className="font-size-sm text-muted mt-2">
                                  Nom de l`équipe
                                </div>
                              </div>
                            </div>
                            <div className="card-toolbar flex-nowrap">
                              <select
                                className="form-control selectpicker"
                                onChange={(e) => selectDateReports(e)}
                              >
                                <option value="3">Les 7 derniers jours</option>
                                <option value="1">Aujourd`hui</option>
                                <option value="2">Hier</option>
                                <option value="4">Ce mois-ci</option>
                                <option value="6">Le mois dernier</option>
                              </select>
                              <div
                                id="addToCart"
                                className="btn btn-icon text-hover-info btn-sm  ml-5 px-5"
                                title="Add to custom Reports"
                              >
                                +
                                <span
                                  node-id="ss-reportsDiv_md4"
                                  className="ss addtcartclose"
                                ></span>
                              </div>
                              <span
                                className="spinner spinner-primary spinner-center"
                                id="ss-reportsDiv_md4"
                                style={{ display: "none" }}
                              ></span>
                            </div>
                          </div>
  
                          <div className="card-body d-flex flex-column">
                            <div className="flex-grow-1">
                              <div
                                id="team-report"
                                style={{ height: "200px" }}
                              ></div>
                            </div>
  
                            <div className="mt-10 mb-5">
                              <div className="row row-paddingless mb-10">
                                <div className="col">
                                  <div className="d-flex align-items-center mr-2">
                                    <div className="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                                      <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                                          <i className="fas fa-chart-pie fa-2x text-danger"></i>
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        className="font-size-h4 font-weight-bolder"
                                        id="socialProfileCount"
                                      ></div>
                                      <div className="font-size-sm font-weight-bold mt-1">
                                        Nombre de profils sociaux
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="d-flex align-items-center mr-2">
                                    <div className="symbol symbol-45 symbol-light-warning mr-4 flex-shrink-0">
                                      <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-warning">
                                          <i className="far fa-clock text-warning fa-2x"></i>
                                        </span>
                                      </div>
                                    </div>
  
                                    <div>
                                      <div
                                        className="font-size-h4 font-weight-bolder"
                                        id="Scheduled"
                                      ></div>
                                      <div className="font-size-sm font-weight-bold mt-1">
                                        Programmer la publication
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
  
                              <div className="row row-paddingless">
                                <div className="col">
                                  <div className="d-flex align-items-center mr-2">
                                    <div className="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
                                      <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-success">
                                          <i className="far fa-calendar-check text-success fa-2x"></i>
                                        </span>
                                      </div>
                                    </div>
  
                                    <div>
                                      <div
                                        className="font-size-h4 font-weight-bolder"
                                        id="published"
                                      ></div>
                                      <div className="font-size-sm font-weight-bold mt-1">
                                        Publier
                                      </div>
                                    </div>
                                  </div>
                                </div>
  
                                <div className="col">
                                  <div className="d-flex align-items-center mr-2">
                                    <div className="symbol symbol-45 symbol-light-primary mr-4 flex-shrink-0">
                                      <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-primary">
                                          <i className="far fa-id-card fa-2x text-primary"></i>
                                        </span>
                                      </div>
                                    </div>
  
                                    <div>
                                      <div
                                        className="font-size-h4 font-weight-bolder"
                                        id="totalPostCount"
                                      ></div>
                                      <div className="font-size-sm font-weight-bold mt-1">
                                        Nombre total de publications
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div
                          className="card card-custom card-stretch gutter-b"
                          id="ss-reportsDiv"
                        >
                          <div className="card-header border-0 pt-5">
                            <div className="card-title">
                              <div className="card-label">
                                <div className="font-weight-bolder">
                                  Rapport de l`équipe
                                </div>
                                <div className="font-size-sm text-muted mt-2">
                                  Nom de l`équipe
                                </div>
                              </div>
                            </div>
                            <div className="card-toolbar flex-nowrap">
                              <select
                                className="form-control selectpicker"
                                onChange={(e) => selectDateReports(e)}
                              >
                                <option value="3">Les 7 derniers jours</option>
                                <option value="1">Aujourd`hui</option>
                                <option value="2">Hier</option>
                                <option value="4">Ce mois-ci</option>
                                <option value="6">Le mois dernier</option>
                              </select>
                              <div
                                id="addToCart"
                                className="btn btn-icon text-hover-info btn-sm  ml-5 px-5"
                                title="Add to custom Reports"
                              >
                                +
                                <span
                                  node-id="ss-reportsDiv_md4"
                                  className="ss addtcartclose"
                                ></span>
                              </div>
                              <span
                                className="spinner spinner-primary spinner-center"
                                id="ss-reportsDiv_md4"
                                style={{ display: "none" }}
                              ></span>
                            </div>
                          </div>
  
                          <div className="card-body d-flex flex-column">
                            <div className="flex-grow-1">
                              <div
                                id="team-report"
                                style={{ height: "200px" }}
                              ></div>
                            </div>
  
                            <div className="mt-10 mb-5">
                              <div className="row row-paddingless mb-10">
                                <div className="col">
                                  <div className="d-flex align-items-center mr-2">
                                    <div className="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                                      <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-danger">
                                          <i className="fas fa-chart-pie fa-2x text-danger"></i>
                                        </span>
                                      </div>
                                    </div>
                                    <div>
                                      <div
                                        className="font-size-h4 font-weight-bolder"
                                        id="socialProfileCount"
                                      ></div>
                                      <div className="font-size-sm font-weight-bold mt-1">
                                        Nombre de profils sociaux
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col">
                                  <div className="d-flex align-items-center mr-2">
                                    <div className="symbol symbol-45 symbol-light-warning mr-4 flex-shrink-0">
                                      <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-warning">
                                          <i className="far fa-clock text-warning fa-2x"></i>
                                        </span>
                                      </div>
                                    </div>
  
                                    <div>
                                      <div
                                        className="font-size-h4 font-weight-bolder"
                                        id="Scheduled"
                                      ></div>
                                      <div className="font-size-sm font-weight-bold mt-1">
                                        Programmer la publication
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
  
                              <div className="row row-paddingless">
                                <div className="col">
                                  <div className="d-flex align-items-center mr-2">
                                    <div className="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
                                      <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-success">
                                          <i className="far fa-calendar-check text-success fa-2x"></i>
                                        </span>
                                      </div>
                                    </div>
  
                                    <div>
                                      <div
                                        className="font-size-h4 font-weight-bolder"
                                        id="published"
                                      ></div>
                                      <div className="font-size-sm font-weight-bold mt-1">
                                        Publier
                                      </div>
                                    </div>
                                  </div>
                                </div>
  
                                <div className="col">
                                  <div className="d-flex align-items-center mr-2">
                                    <div className="symbol symbol-45 symbol-light-primary mr-4 flex-shrink-0">
                                      <div className="symbol-label">
                                        <span className="svg-icon svg-icon-lg svg-icon-primary">
                                          <i className="far fa-id-card fa-2x text-primary"></i>
                                        </span>
                                      </div>
                                    </div>
  
                                    <div>
                                      <div
                                        className="font-size-h4 font-weight-bolder"
                                        id="totalPostCount"
                                      ></div>
                                      <div className="font-size-sm font-weight-bold mt-1">
                                        Nombre total de publications
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
  
                    </div>
                  </div>
                </div>
              </div>
            </section>
  
          </div>
          <Modal className="my-modal" show={showActus} onHide={handleCloseActus} style={{ width: "40%", padding: "10px", margin: "0 auto" }}>
            <button type="button" onClick={handleCloseActus} className="close" style={{ position: "initial" }}>
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div>
                <div>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="addAccountsModalLabel"
                      >
                        Actualités
                      </h5>
                    </div>
                    <div className="content  d-flex flex-column flex-column-fluid" id="Sb_content">
                      <div className="d-flex flex-column-fluid">
                        <div className=" container-fluid ">
                          <div className="row">
                            <div className="col-xl-12 card card-custom card-stretch gutter-b">
                              <div className="u-clearfix u-sheet u-sheet-1 card-header border-0 pt-5">
                                <div className="grid5agrs u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                                  <div className="u-gutter-0 u-layout">
                                    <div className="u-layout-row">
                                      <div className="u-size-30 u-size-60-md">
                                        <div className="u-layout-col" style={{ width: "210%" }}>
                                          <div className="u-container-style u-layout-cell u-radius-6 u-size-60 u-white u-layout-cell-1">
                                            <div className="u-container-layout u-container-layout-1">
                                              <h3 className="h3list3agrs u-text u-text-1">
                                                <a
                                                  className="btnlist3agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-4 u-btn-1"
                                                  href="#"
                                                >
                                                  Titre actualités
                                                </a>
                                              </h3>
                                              <p className="textlist3agrs u-text u-text-2">
                                                <span className="" style={{ fontWeight: "700" }}>
                                                Lorem ipsum dolor sit amet !:{" "}
                                                </span>
                                                <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat. Duis semper.
                                                </p>
                                              </p>
  
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
  
  
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            <div className="col-xl-12 card card-custom card-stretch gutter-b">
                              <div className="u-clearfix u-sheet u-sheet-1 card-header border-0 pt-5">
                                <div className="grid5agrs u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                                  <div className="u-gutter-0 u-layout">
                                    <div className="u-layout-row">
                                      <div className="u-size-30 u-size-60-md">
                                        <div className="u-layout-col" style={{ width: "210%" }}>
                                          <div className="u-container-style u-layout-cell u-radius-6 u-size-60 u-white u-layout-cell-1">
                                            <div className="u-container-layout u-container-layout-1">
                                              <h3 className="h3list3agrs u-text u-text-1">
                                                <a
                                                  className="btnlist3agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-4 u-btn-1"
                                                  href="#"
                                                >
                                                  Titre actualités
                                                </a>
                                              </h3>
                                              <p className="textlist3agrs u-text u-text-2">
                                                <span className="" style={{ fontWeight: "700" }}>
                                                Lorem ipsum dolor sit amet !:{" "}
                                                </span>
                                                <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat. Duis semper.
                                                </p>
                                              </p>
  
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
  
  
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            <div className="col-xl-12 card card-custom card-stretch gutter-b">
                              <div className="u-clearfix u-sheet u-sheet-1 card-header border-0 pt-5">
                                <div className="grid5agrs u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                                  <div className="u-gutter-0 u-layout">
                                    <div className="u-layout-row">
                                      <div className="u-size-30 u-size-60-md">
                                        <div className="u-layout-col" style={{ width: "210%" }}>
                                          <div className="u-container-style u-layout-cell u-radius-6 u-size-60 u-white u-layout-cell-1">
                                            <div className="u-container-layout u-container-layout-1">
                                              <h3 className="h3list3agrs u-text u-text-1">
                                                <a
                                                  className="btnlist3agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-4 u-btn-1"
                                                  href="#"
                                                >
                                                  Titre actualités
                                                </a>
                                              </h3>
                                              <p className="textlist3agrs u-text u-text-2">
                                                <span className="" style={{ fontWeight: "700" }}>
                                                Lorem ipsum dolor sit amet !:{" "}
                                                </span>
                                                <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat. Duis semper.
                                                </p>
                                              </p>
  
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
  
  
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            <div className="col-xl-12 card card-custom card-stretch gutter-b">
                              <div className="u-clearfix u-sheet u-sheet-1 card-header border-0 pt-5">
                                <div className="grid5agrs u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                                  <div className="u-gutter-0 u-layout">
                                    <div className="u-layout-row">
                                      <div className="u-size-30 u-size-60-md">
                                        <div className="u-layout-col" style={{ width: "210%" }}>
                                          <div className="u-container-style u-layout-cell u-radius-6 u-size-60 u-white u-layout-cell-1">
                                            <div className="u-container-layout u-container-layout-1">
                                              <h3 className="h3list3agrs u-text u-text-1">
                                                <a
                                                  className="btnlist3agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-4 u-btn-1"
                                                  href="#"
                                                >
                                                  Titre actualités
                                                </a>
                                              </h3>
                                              <p className="textlist3agrs u-text u-text-2">
                                                <span className="" style={{ fontWeight: "700" }}>
                                                Lorem ipsum dolor sit amet !:{" "}
                                                </span>
                                                <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat. Duis semper.
                                                </p>
                                              </p>
  
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
  
  
                                  </div>
                                </div>
                              </div>
                            </div>
  
                            <div className="col-xl-12 card card-custom card-stretch gutter-b">
                              <div className="u-clearfix u-sheet u-sheet-1 card-header border-0 pt-5">
                                <div className="grid5agrs u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                                  <div className="u-gutter-0 u-layout">
                                    <div className="u-layout-row">
                                      <div className="u-size-30 u-size-60-md">
                                        <div className="u-layout-col" style={{ width: "210%" }}>
                                          <div className="u-container-style u-layout-cell u-radius-6 u-size-60 u-white u-layout-cell-1">
                                            <div className="u-container-layout u-container-layout-1">
                                              <h3 className="h3list3agrs u-text u-text-1">
                                                <a
                                                  className="btnlist3agrs u-active-none u-border-none u-btn u-button-link u-button-style u-hover-none u-none u-text-body-color u-text-hover-custom-color-4 u-btn-1"
                                                  href="#"
                                                >
                                                  Titre actualités
                                                </a>
                                              </h3>
                                              <p className="textlist3agrs u-text u-text-2">
                                                <span className="" style={{ fontWeight: "700" }}>
                                                Lorem ipsum dolor sit amet !:{" "}
                                                </span>
                                                <p>
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed non risus. Suspendisse lectus tortor, dignissim sit amet, adipiscing nec, ultricies sed, dolor. Cras elementum ultrices diam. Maecenas ligula massa, varius a, semper congue, euismod non, mi. Proin porttitor, orci nec nonummy molestie, enim est eleifend mi, non fermentum diam nisl sit amet erat. Duis semper.
                                                </p>
                                              </p>
  
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
  
  
                                  </div>
                                </div>
                              </div>
                            </div>
  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
  
          <Modal className="my-modal" show={showStats} onHide={handleCloseStats} style={{ width: "80%", padding: "10px", margin: "0 auto" }}>
            <button type="button" onClick={handleCloseStats} className="close" style={{ position: "initial" }}>
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div>
                <div>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="addAccountsModalLabel"
                      >
                        Statistiques
                      </h5>
                    </div>
                    <div className="content  d-flex flex-column flex-column-fluid" id="Sb_content">
                      <div className="d-flex flex-column-fluid">
                        <div className=" container-fluid ">
                          <div className="row">
                            <div className="col-xl-12 col-sm-12" style={{ padding: "0" }}>
                              <div className="col-xl-2 col-sm-2 box-card-padding">
                                <a
                                  href="/publish"
                                  className="card-custom gutter-b card-stretch"
                                >
                                  <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                                    <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                      <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                        <i className="fas fa-share-alt text-primary fa-4x picto1grs"></i>
                                      </span>
                                      <h3 className="h4list4agrs u-text u-text-1">50x&nbsp; Diffusions
                                      </h3>
                                      <p className="textlist4agrs u-custom-item u-text u-text-custom-color-1 u-text-2"><b>+ 17%&nbsp;</b>
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
  
                              <div className="col-xl-2 col-sm-2 box-card-padding">
                                <a
                                  href="/publish"
                                  className="card-custom gutter-b card-stretch"
                                >
                                  <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-custom-color-4 u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                                    <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                      <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                        <i className="fas fa-comments text-danger fa-4x picto1grs"></i>
                                      </span>
                                      <h3 className="h4list4agrs u-text u-text-3">500x&nbsp;Réactions
                                      </h3>
                                      <p className="textlist4bgrs u-custom-item u-text u-text-4"><b>+200 %&nbsp;</b>
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
  
  
                              <div className="col-xl-2 col-sm-2 box-card-padding">
                                <a
                                  href="/publish"
                                  className="card-custom gutter-b card-stretch"
                                >
                                  <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                                    <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                      <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                        <i className="fas fa-edit text-warning fa-4x picto1grs"></i>
                                      </span>
                                      <h3 className="h4list4agrs u-text u-text-5">12&nbsp;Publications
                                      </h3>
                                      <p className="textlist4agrs u-custom-item u-text u-text-custom-color-1 u-text-6"><b>+50 %&nbsp;</b>
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
  
                              <div className="col-xl-2 col-sm-2 box-card-padding">
                                <a
                                  href="/publish"
                                  className="card-custom gutter-b card-stretch"
                                >
                                  <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-custom-color-3 u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                                    <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                      <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                        <i className="fas fa-users text-white fa-4x picto1grs"></i>
                                      </span>
                                      <h3 className="h4list4agrs u-text u-text-7">3000&nbsp;audiences
                                      </h3>
                                      <p className="textlist4bgrs u-custom-item u-text u-text-8"><b>+ 145 %</b>
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
  
                              <div className="col-xl-2 col-sm-2 box-card-padding">
                                <a
                                  href="/publish"
                                  className="card-custom gutter-b card-stretch"
                                >
                                  <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                                    <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                      <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                        <i className="fas fa-users text-primary fa-4x picto1grs"></i>
                                      </span>
                                      <h3 className="h4list4agrs u-text u-text-9">+120 Nouveaux abonnées</h3>
                                      <p className="textlist4agrs u-custom-item u-text u-text-custom-color-1 u-text-10"><b>+ 300 %</b>
                                      </p>
                                    </div>
                                  </div>
                                </a>
                              </div>
  
                              <div className="col-xl-2 col-sm-2 box-card-padding">
                                <a
                                  href="/publish"
                                  className="card-custom gutter-b card-stretch"
                                >
                                  <div className="u-align-center u-border-1 u-border-grey-75 u-container-style u-custom-color-2 u-list-item u-radius-6 u-repeater-item u-shape-round u-white" style={{ minHeight: "160px" }}>
                                    <div className="u-container-layout u-similar-container u-valign-middle u-container-layout-1">
                                      <span className="u-icon u-icon-circle u-icon-1 icon-padding-top">
                                        <i className="fas fa-comment-alt text-white fa-4x picto1grs"></i>
                                      </span>
                                      <h3 className="h4list4agrs u-text u-text-11">+250 Commentaires</h3>
                                      <p className="textlist4bgrs u-custom-item u-text u-text-12"><b>+ 121 %</b></p>
                                    </div>
                                  </div>
                                </a>
                              </div>
  
  
  
  
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xl-6 card card-custom card-stretch gutter-b">
                              <div className="u-clearfix u-sheet u-sheet-1 card-header border-0 pt-5">
                                <div className="grid5agrs u-clearfix u-expanded-width u-gutter-10 u-layout-wrap u-layout-wrap-1">
                                  <div className="u-gutter-0 u-layout">
                                    <div className="u-layout-row">
                                      <div className="u-size-30 u-size-60-md">
                                        <div className="u-layout-col" style={{ width: "210%" }}>
                                          <div className="u-container-style u-layout-cell u-radius-6 u-size-60 u-white u-layout-cell-1">
                                            <div className="u-container-layout u-container-layout-1">
                                              <h3 className="h3grid5agrs u-text u-text-default u-text-1">
                                                Performance globale
                                              </h3>
                                              <img className="imggrid5agrs u-align-center u-image u-image-default u-preserve-proportions u-image-1" src="/dist/img/c7036e716e0d0043ad963b1991db10d955a6bb904ddb4d12e0826cd52fcb73b27acd81775cd59780003a1e23d7505d9dcb6dc1e25c591387020491_1280.png" alt="" data-image-width="150"
                                                data-image-height="79" style={{ width: "-webkit-fill-available" }}></img>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
  
  
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-6">
                              <div
                                className="card card-custom card-stretch gutter-b"
                                id="ss-reportsDiv"
                              >
                                <div className="card-header border-0 pt-5">
                                  <div className="card-title">
                                    <div className="card-label">
                                      <div className="font-weight-bolder">
                                        Rapport de l`équipe
                                      </div>
                                      <div className="font-size-sm text-muted mt-2">
                                        Nom de l`équipe
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card-toolbar flex-nowrap">
                                    <select
                                      className="form-control selectpicker"
                                      onChange={(e) => selectDateReports(e)}
                                    >
                                      <option value="3">Les 7 derniers jours</option>
                                      <option value="1">Aujourd`hui</option>
                                      <option value="2">Hier</option>
                                      <option value="4">Ce mois-ci</option>
                                      <option value="6">Le mois dernier</option>
                                    </select>
                                    <div
                                      id="addToCart"
                                      className="btn btn-icon text-hover-info btn-sm  ml-5 px-5"
                                      title="Add to custom Reports"
                                    >
                                      +
                                      <span
                                        node-id="ss-reportsDiv_md4"
                                        className="ss addtcartclose"
                                      ></span>
                                    </div>
                                    <span
                                      className="spinner spinner-primary spinner-center"
                                      id="ss-reportsDiv_md4"
                                      style={{ display: "none" }}
                                    ></span>
                                  </div>
                                </div>
  
                                <div className="card-body d-flex flex-column">
                                  <div className="flex-grow-1">
                                    <div
                                      id="team-report"
                                      style={{ height: "200px" }}
                                    ></div>
                                  </div>
  
                                  <div className="mt-10 mb-5">
                                    <div className="row row-paddingless mb-10">
                                      <div className="col">
                                        <div className="d-flex align-items-center mr-2">
                                          <div className="symbol symbol-45 symbol-light-danger mr-4 flex-shrink-0">
                                            <div className="symbol-label">
                                              <span className="svg-icon svg-icon-lg svg-icon-danger">
                                                <i className="fas fa-chart-pie fa-2x text-danger"></i>
                                              </span>
                                            </div>
                                          </div>
                                          <div>
                                            <div
                                              className="font-size-h4 font-weight-bolder"
                                              id="socialProfileCount"
                                            ></div>
                                            <div className="font-size-sm font-weight-bold mt-1">
                                              Nombre de profils sociaux
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="d-flex align-items-center mr-2">
                                          <div className="symbol symbol-45 symbol-light-warning mr-4 flex-shrink-0">
                                            <div className="symbol-label">
                                              <span className="svg-icon svg-icon-lg svg-icon-warning">
                                                <i className="far fa-clock text-warning fa-2x"></i>
                                              </span>
                                            </div>
                                          </div>
  
                                          <div>
                                            <div
                                              className="font-size-h4 font-weight-bolder"
                                              id="Scheduled"
                                            ></div>
                                            <div className="font-size-sm font-weight-bold mt-1">
                                              Programmer la publication
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
  
                                    <div className="row row-paddingless">
                                      <div className="col">
                                        <div className="d-flex align-items-center mr-2">
                                          <div className="symbol symbol-45 symbol-light-success mr-4 flex-shrink-0">
                                            <div className="symbol-label">
                                              <span className="svg-icon svg-icon-lg svg-icon-success">
                                                <i className="far fa-calendar-check text-success fa-2x"></i>
                                              </span>
                                            </div>
                                          </div>
  
                                          <div>
                                            <div
                                              className="font-size-h4 font-weight-bolder"
                                              id="published"
                                            ></div>
                                            <div className="font-size-sm font-weight-bold mt-1">
                                              Publier
                                            </div>
                                          </div>
                                        </div>
                                      </div>
  
                                      <div className="col">
                                        <div className="d-flex align-items-center mr-2">
                                          <div className="symbol symbol-45 symbol-light-primary mr-4 flex-shrink-0">
                                            <div className="symbol-label">
                                              <span className="svg-icon svg-icon-lg svg-icon-primary">
                                                <i className="far fa-id-card fa-2x text-primary"></i>
                                              </span>
                                            </div>
                                          </div>
  
                                          <div>
                                            <div
                                              className="font-size-h4 font-weight-bolder"
                                              id="totalPostCount"
                                            ></div>
                                            <div className="font-size-sm font-weight-bold mt-1">
                                              Nombre total de publications
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
  
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
  
  
  
          <Modal className="my-modal" show={show} onHide={handleClose} style={{ width: "60%", padding: "10px", margin: "0 auto" }}>
            <button type="button" onClick={handleClose} className="close" style={{ position: "initial" }}>
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div>
                <div>
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="addAccountsModalLabel"
                      >
                        Ajouter des comptes
                      </h5>
                    </div>
                    <div className="modal-body">
                      <div className="">
                        <ul
                          className="nav justify-content-center nav-pills"
                          id="AddAccountsTab"
                          role="tablist"
                        >
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="facebook-tab-accounts"
                              data-toggle="tab"
                              href="#facebook-add-accounts"
                            >
                              <span className="nav-text">
                                <i className="fab fa-facebook fa-2x"></i>
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="twitter-tab-accounts"
                              data-toggle="tab"
                              href="#twitter-add-accounts"
                              aria-controls="twitter"
                            >
                              <span className="nav-text">
                                <i className="fab fa-twitter fa-2x"></i>
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="instagram-tab-accounts"
                              data-toggle="tab"
                              href="#instagram-add-accounts"
                              aria-controls="instagram"
                            >
                              <span className="nav-text">
                                <i className="fab fa-instagram fa-2x"></i>
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="linkedin-tab-accounts"
                              data-toggle="tab"
                              href="#linkedin-add-accounts"
                              aria-controls="linkedin"
                            >
                              <span className="nav-text">
                                <i className="fab fa-linkedin fa-2x"></i>
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="youtube-tab-accounts"
                              data-toggle="tab"
                              href="#youtube-add-accounts"
                              aria-controls="youtube"
                            >
                              <span className="nav-text">
                                <i className="fab fa-youtube fa-2x"></i>
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="pinterest-tab-accounts"
                              data-toggle="tab"
                              href="#pinterest-add-accounts"
                              aria-controls="pinterest"
                            >
                              <span className="nav-text">
                                <i className="fab fa-pinterest fa-2x"></i>
                              </span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              id="tumblr-tab-accounts"
                              data-toggle="tab"
                              href="#tumblr-add-accounts"
                              aria-controls="tumblr"
                            >
                              <span className="nav-text">
                                <i className="fab fa-tumblr fa-2x"></i>
                              </span>
                            </a>
                          </li>
                        </ul>
                        <div
                          className="tab-content mt-5"
                          id="AddAccountsTabContent"
                        >
                          <div
                            className="tab-pane show active"
                            id="facebook-add-accounts"
                            role="tabpanel"
                            aria-labelledby="facebook-tab-accounts"
                          >
                            <p>
                              Socioboard a besoin d`une autorisation
                              pour accéder et publier du contenu sur
                              Facebook en votre nom. Pour accorder
                              l`autorisation, vous devez être un
                              administrateur de la page.
                            </p>
                            <div className="d-flex justify-content-center">
                              <a
                                href="http://localhost:1000/v1/social-login?network=Facebook"
                                type="button"
                                className="btn btn-facebook font-weight-bolder font-size-h6 px-4 py-4 mr-3 my-3"
                              >
                                Ajouter un profil Facebook
                              </a>
                              <a
                                href="http://localhost:1000/add-accounts/FacebookPage"
                                type="button"
                                className="btn btn-facebook fb_page_btn font-weight-bolder font-size-h6 px-4 py-4 mr-3 my-3"
                              >
                                Ajouter une page de fans Facebook
                              </a>
                            </div>
                            <div
                              className="mt-3 fb_page_div"
                              style={{ display: "none" }}
                            >
                              <span>
                                Choisissez les pages Facebook pour
                                la publication
                              </span>
                              <div
                                className="scroll scroll-pull"
                                data-scroll="true"
                                data-wheel-propagation="true"
                                style={{
                                  height: "200px",
                                  overflowY: "scroll",
                                }}
                              >
                                <div className="d-flex align-items-center flex-grow-1">
                                  <div className="symbol symbol-45 symbol-light mr-5">
                                    <span className="symbol-label">
                                      <img
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
                                    <div className="d-flex flex-column align-items-cente py-2 w-75">
                                      <span className="text-muted font-weight-bold"></span>
                                    </div>
                                  </div>
  
                                  <div
                                    className="custom-control custom-checkbox"
                                    id="checkboxes"
                                  >
                                    <label
                                      className="checkbox checkbox-lg checkbox-lg flex-shrink-0 mr-4"
                                      htmlFor="{{session()->get('pages')[$i]->pageId}}"
                                    >
                                      <input
                                        type="checkbox"
                                        id="{{session()->get('pages')[$i]->pageId}}"
                                        name="{{session()->get('pages')[$i]->pageName}}"
                                      />
                                      <span></span>
                                    </label>
                                  </div>
                                </div>
                              </div>
  
                              <div className="d-flex justify-content-center">
                                <a
                                  type="button"
                                  id="checkedPages"
                                  className="btn btn-facebook font-weight-bolder font-size-h6 px-4 py-4 mr-3 my-3"
                                >
                                  Envoyer pour ajouter des pages
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )
}

export default ClientListSocialeModule
import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Moment from 'moment';
import API from '../services/API/API';
import PageHead from "../components/pageHead";
import EvaluationComponent from "../components/evaluation";
import BoxSearchEvaluationComponent from "../components/boxSearchEvaluation";
import 'moment/locale/fr';

const token = {
    headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

const Evaluation = () => {
    let username = "";
    let date = "";
    const role = sessionStorage.getItem("role");
    const [evaluations, setEvaluations] = useState<Array<any>>([])
    const [show, setShow] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [id_user, ] = useState(sessionStorage.getItem('client_id') ?? '')
    const [currentCollaborateur, ] = useState('')

    const onSearchEvaluation = async (trimestre: string, collaborator: string, annee: string) => {
        if(sessionStorage.getItem("role")  === "user"){
            /* service.findEvaluationColaborateur(sessionStorage.getItem('client_id'), trimestre, collaborator, annee, token)
            .then(response => {
                setState({
                    evaluations: response.data,
                    showSearch: false
                });
                console.log("search result: "+response.data)
            })
            .catch(e => {
                console.log(e);
            }); */
            try {
                const response = await API.CollaboratorEvaluation.findEvaluationColaborateur(sessionStorage.getItem('client_id') ?? '', trimestre, collaborator, annee, token)
                setEvaluations(response.data)
                setShowSearch(false)
            }
            catch (error) {
                console.log(error)
            }
        }
        else{
            /* service.findAllEvaluationColaborateur(trimestre, collaborator, annee, token)
            .then(response => {
                setState({
                    evaluations: response.data,
                    showSearch: false
                });
                console.log("search result: "+response.data)
            })
            .catch(e => {
                console.log(e);
            }); */
            try {
                const response = await API.CollaboratorEvaluation.findAllEvaluationColaborateur(trimestre, collaborator, annee, token)
                setEvaluations(response.data)
                setShowSearch(false)
            }
            catch (error) {
                console.log(error)
            }
        }

    }

    const handleClose = () => {
        setShow(false)
    }

    const handleCloseSearch = () => {
        setShowSearch(false)
    }

    const handleSearch = () => {
        setShowSearch(true)
    }


    useEffect(() => {
        if (evaluations.length > 0) {
            username = evaluations[0].username;
            date = Moment(evaluations[0].createdAt).format("LLL");
        }
    }, [])

    return (
        <div>
            <div
                className="content-wrapper"
                style={{
                    backgroundImage: `url(${background})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "100% 100%"
                }}
            >
                {/* TITLE & BREADCRUMBS */}
                {role !== "collaborator" ? (
                    <PageHead title="Evaluation mensuelle " subject="Evaluation mensuelle" name="Evaluation" />

                ) : null}

                {/* SEARCH FORM */}
                    <div className="action-container">
                        <div className="searchbar-container">
                            <div className="form-inline">
                                <div className="button-action">
                                    <Button onClick={handleSearch} className="universal-length mbtn" >
                                        <i className="fas fa-search"></i> <strong>Rechercher</strong>
                                    </Button>
                                </div>

                            </div>
                        </div>

                    </div>

                {/* END SEARCH FORM */}
                <section className="content">
                    <div>
                            <div>
                                <div className="clientInfo col-6">
                                    <h1> </h1>
                                </div>
                                <div className="collaboratorInfo col-6" style={{ backgroundColor: "white", padding: "15px", borderRadius: "5px", marginBottom: "15px" }}>
                                    <h1>  Nom/Prénom : {username} </h1>
                                    <h1>  Cellule : </h1>
                                    <h1>  Date de l`évaluation : {date}</h1>
                                </div>
                                <table className="table table-curved">
                                    <thead >
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Items</th>
                                            <th scope="col">Définition</th>
                                            <th scope="col">Note</th>
                                            <th scope="col">Observation</th>
                                            <th scope="col" style={{ width: "5%" }} >Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {evaluations.map((evaluation) => (
                                        <tr>
                                            <td>  </td>
                                            <td title={evaluation.categorie}> {evaluation.item} </td>
                                            <td> {evaluation.definition} </td>
                                            <td> {evaluation.note} </td>
                                            <td> {evaluation.observation} </td>
                                            <td>  </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>

            
                    </div>
                </section>

                <Modal
                    className="my-modal"
                    animation={false}
                    show={show}
                    onHide={handleClose}
                >

                    <button type="button" onClick={handleClose} className="close">
                        <i className="fas fa-times"></i>
                        <span className="sr-only">Close</span>
                    </button>
                    <Modal.Body>
                        <div className="row">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <EvaluationComponent client={id_user} collaborateur={currentCollaborateur} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    className="my-modal"
                    animation={false}
                    show={showSearch}
                    onHide={handleCloseSearch}
                >

                    <button type="button" onClick={handleCloseSearch} className="close">
                        <i className="fas fa-times"></i>
                        <span className="sr-only">Close</span>
                    </button>
                    <Modal.Body>
                        <div className="row">

                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <BoxSearchEvaluationComponent actionSearch={onSearchEvaluation} client={sessionStorage.getItem('client_id') ?? ''} />
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

            </div>
        </div>
    )
}

export default Evaluation
import React, { useEffect, useState, FormEvent } from "react";
import {Button} from 'react-bootstrap';
import API from '../services/API/API';

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

type actionCloseFunction = () => void

interface IProps {
    actionClose: actionCloseFunction
}

const ItemComponent = ({ actionClose }: IProps) => {
    const [categorie, setCategorie] = useState('')
    const [categories, setCategories] = useState<Array<any>>([])
    const [libelle, setLibelle] = useState('')
    const [isOk, setIsOk] = useState(false)

    /* onChangeValue(e, i, nom) {
        let list = [...list];
        list[i] = { id_candidat: id, name: nom, note: e.target.value }
        setState({ list });
        let note = [...noteSelected];
        note[i] = e.target.value
        setState({
          noteSelected : note
        });
      } */

    

    /* handleChange(e) {
        setState({
            avatar: URL.createObjectURL(e.target.files[0]),
            selectedAvatar: e.target.files[0],
        })
    } */

    const onChangeLibelle = (e: FormEvent<HTMLInputElement>) => {
        setLibelle(e.currentTarget.value)
    }

    const onChangeCategorie = (e: FormEvent<HTMLSelectElement>) => {
        setCategorie(e.currentTarget.value)
    }

    const saveItem = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = {
            categorie_id: categorie,
            libelle
        };

        /* service.createItem(data, token)
            .then(response => {
                //console.log(data);
                setState({
                    item_id: response.data.item_id,
                    categorie_id: response.data.categorie_id,
                    libelle: response.data.libelle
                });
                handleClose()
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.CollaboratorEvaluation.createItem(data, token)
            setLibelle(response.data.libelle)
            setIsOk(true)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const newItem = () => {
        setLibelle('')
        setIsOk(false)
    }

    const retrieveCategories = async () => {
        /* service.getAllCategories()
          .then(response => {
            setState({
              categories: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.CollaboratorEvaluation.getAllCategories(token)
            setCategories(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    // eslint-disable-next-line no-unused-vars
    const handleClose = () => {
        actionClose()
    }

    useEffect(() => {
        (async () => {
            await retrieveCategories()
        })()
    }, [])

    return (
        <div>
            {/* Main content */}
            <section className="content">
            <div>
          {isOk ? (
              <div className="submitted">
              <div style={{textAlign: 'center'}}>
                  <i className="ok fas fa-3x fa-check-circle"></i>
                  <h2><strong>Enregistrement terminé avec succès</strong></h2>
                  <Button onClick={newItem} className="universal-length mbtn">
                      <i className="fas fa-plus-circle"></i> <strong>Ajouter un autre Item</strong>
                  </Button>
              </div>
          </div>
            ) : (
                <div className="submit-form">
                    
                        <form onSubmit={saveItem}>
                        <div className="row box-register">
                            <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="title-container">
                                    <div className="title">
                                        <h2>Ajouter Item</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="libelle">Libelle</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="libelle"
                                                required
                                                value={libelle}
                                                onChange={onChangeLibelle}
                                                name="libelle"
                                                placeholder=""
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="item">Catégorie</label>
                                            <select  required style={{ width: '100%'}} value={categorie} onChange={onChangeCategorie} placeholder="Choisir categorie">
                                                <option value=""></option>
                                                {categories.map((item: any) => (
                                                    <option value={item.categorie_id}>{item.libelle}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    
                                    
                                </div>
                               
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-save-information"> Enregistrer </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                </div>
                )}
            </div>
            </section>
        </div >
    )
}

export default ItemComponent
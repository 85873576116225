import http from '../APIs/olonaTeams'

export default class AssignementAPI {
    // candidat assignment
  public getAssignments = (id: string | number, token: any) => http.get(`/assignments?id=${id}`, token)

  public deleteAssignment = (id: string | number, id_candidat: string | number, token: any) => http.delete(`/assignement/${id}/${id_candidat}`, token)

  public addAssignment = (data: any, token: any) => http.post(`/addAssignment`, data, token)

  public addAssignmentPoste = (data: any, token: any) => http.post(`/addAssignmentPoste`, data, token)

  public getAssignmentsPoste = (id: string | number, poste: string | number, token: any) => http.get(`/assignmentsPoste?id=${id}&poste=${poste}`, token)
}

import React, { useEffect, useState, FormEvent } from "react";
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import draftToHtml from 'draftjs-to-html';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import API from '../services/API/API';
import "react-datepicker/dist/react-datepicker.css";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

interface IFiche {
    message: string
    title: string
    id: string | number
}

interface IProps {
    clientId: string | number,
    mode: string,
    fiches: IFiche
}

const AddFiche = ({ clientId, mode, fiches }: IProps) => {
    const [, setId] = useState(null)
    const [fiche, setFiche] = useState( mode === 'update' ? fiches.message : '' )
    const [title, setTitle] = useState( mode === 'update' ? fiches.title : '' )
    const [editorState, setEditorState] = useState( EditorState.createEmpty() )

    const onChangeFichePoste = (editorStates: EditorState) => {
        setFiche(draftToHtml(convertToRaw(editorStates.getCurrentContent())))
    }

    const onChangeTitle = (e: FormEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value)
    }

    const saveFiche = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        /* var data = {
            id: this.props.id,
            fiche: this.state.fiche,
            title: this.state.title,
        };
        service.createFiche(data, token)
            .then(response => {
                this.setState({
                    id: response.data.id
                });
                window.location.href = "/DetailClient/" + this.props.id;
            })
            .catch(e => {
                alert(e)
                console.log(e);
            }); */
        try {
            const data = {
                id: clientId,
                fiche,
                title
            }

            const response = await API.Form.createFiche(data, token)
            setId(response.data.id)
            window.location.href = `/DetailClient/${clientId}`
        }
        catch (error) {
            console.log(error)
        }
    }

    const updateFiche = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        /* var data = {
            fiche: this.state.fiche,
            title: this.state.title,
        };
        service.updateFiche(this.props.fiche.id, data, token)
            .then(response => {
                this.setState({
                    id: response.data.id
                });
                window.location.href = "/DetailClient/" + this.props.id;
            })
            .catch(e => {
                alert(e)
                console.log(e);
            }); */

        try {
            const data = {
                fiche,
                title
            }
            const response = await API.Form.updateFiche(fiches.id, data, token)
            setId(response.data.id)
            window.location.href = `/DetailClient/${clientId}`
        }
        catch (error) {
            console.log(error)
        }
    }

    /* const onEditorStateChange = async (editorStates: EditorState) => {
        setEditorState(editorStates)
    } */

    const editorFiche = () => {
        const html = fiches.message;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentStates = ContentState.createFromBlockArray(contentBlock.contentBlocks)
            const editorStates = EditorState.createWithContent(contentStates)
            setEditorState(editorStates)
            setFiche(draftToHtml(convertToRaw(editorStates.getCurrentContent())))
        }
    }

    useEffect(() => {
        if( mode === 'update'){
            editorFiche();
        }
    }, [])

    return (
        <div>
                <div className="content">
                    {/* /.content-header */}
                    {/* Main content */}
                    <section className="content">
                        <div>
                            {/* Small boxes (Stat box) */}
                            <form onSubmit={ mode === "update" ? updateFiche : saveFiche}>
                            <div className="row box-register">
                                <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="email">Titre</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="title"
                                                    required
                                                    value={title}
                                                    onChange={onChangeTitle}
                                                    name="title"
                                                    placeholder="Ajouter ici le titre de votre fiche"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label htmlFor="comment" className="control-label">Fiche de poste</label>
                                                <Editor
                                                  editorState={editorState}
                                                  editorStyle={{ border: "1px solid #C0C0C0", minHeight: "75px", fontFamily: "'Montserrat', sans-serif;"}}
                                                  onEditorStateChange={onChangeFichePoste}
                                                />
                                                {/* <textarea required className="form-control" type="text" id="comment" name="comment" value={this.state.fiche}
                                                    onChange={this.onChangeFichePoste} accept="" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <button type="submit" className="w-100 btn-register btn btn-success"> Enregistrer</button>
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </section>
                </div>
            </div>
    )
}

export default AddFiche
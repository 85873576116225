import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from 'react-bootstrap';
import Moment from 'moment';
import ShowMoreText from "react-show-more-text";
import API from "../services/API/API";
import BoxSearchSuivi from "../components/boxSearchSuivi";
import PageHead from "../components/pageHead";
import "../styles/styles.css";
import 'moment/locale/fr';

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

const SuiviCollaboratorPerDay = () => {
    const [suivis, setSuivis] = useState<Array<any>>([])
    const [searchTitle, setSearchTitle] = useState('')
    const [collaborators, setCollaborators] = useState<Array<any>>([])
    const [show, setShow] = useState(false)

    const getSuiviAllCollaborator = async () => {
        /* service.getAllSuiviCollaborator(token)
          .then(response => {
            setState({
              suivis: response.data,
              show: false
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getAllSuiviCollaborator(token)
            setSuivis(response.data)
            setShow(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const onSearchSuivi = async (nom: string, date_start: string, date_end: string) => {
        if (nom === '' && date_start === '' && date_end === '') {
            await getSuiviAllCollaborator();
        }
        else {
          /* service.searchMultipleAllColaborateur(nom, date_start, date_end, token)
            .then(response => {
              setState({
                suivis: response.data,
                show: false
              });
            })
            .catch(e => {
              console.log(e);
            }); */
            try {
                const response = await API.Collaborator.searchMultipleAllColaborateur(nom, date_start, date_end, token)
                setSuivis(response.data)
                setShow(false)
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value)
        if (e.currentTarget.value === "") {
            await getSuiviAllCollaborator();
        }
        else {
            /* service.searchAllColaborateur(searchTitle, token)
            .then(response => {
              setState({
                suivis: response.data
              });
              console.log(response.data);
            })
            .catch(e => {
              console.log(e);
            }); */
            try {
                const response = await API.Collaborator.searchAllColaborateur(searchTitle, token)
                setSuivis(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const handleClose = () => {
        setShow(false)
    }
    
    const handleShow = () => {
        setShow(true)
    }
      
    const getAllCollaborator = async () => {
        /* service.getAllCollaborator(token)
          .then(response => {
            setState({
              collaborators: response.data,
            });
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getAllCollaborator(token)
            setCollaborators(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        Moment.locale('fr');
        (async () => {
            await getAllCollaborator()
            await getSuiviAllCollaborator()
        })()
    }, [])
    
    return (
        <div className="content-wrapper bg-white" style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%"
        }} >
          <PageHead title="Rapport Collaborateur" subject="Liste des rapports" name="Rapport" />
          <div className="searchbar-container">
            <div className="form-inline">
              <div className="input-group input-group-sm">
                <span className="universal-length icon-r">
                  <i className="fas fa-search"></i>
                </span>{" "}
                <input
                  className="form-control form-control-navbar universal-length recherche col-10"
                  value={searchTitle}
                  onChange={onChangeSearchTitle}
                  type="search"
                  placeholder="Rechercher"
                  aria-label="Search"
                />
              </div>
              <div className="button-action">
                {/* <Link to="/Registration"> */}
                {/* <Button className="universal-length mbtn" block type="submit">
                      <i class="fas fa-plus-circle"></i> <strong>Ajouter un candidat</strong>
                    </Button> */}
                <Button onClick={handleShow} className="universal-length mbtn" >
                  <i className="fas fa-search"></i> <strong>Filtres</strong>
                </Button>
                {/* </Link> */}
              </div>
            </div>
  
          </div>
          <section className="content">
              <div>
              <table className="table table-curved">
                      <thead >
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Collaborateur</th>
                          <th scope="col">Message</th>
                          <th scope="col">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                      {suivis.map((suivi, i) => (
                        <tr>
                          <td> {i+1} </td>
                        <td> {suivi.user.username} </td>
                        <td> 
                        <ShowMoreText
                              /* Default options */
                              lines={3}
                              more="voir plus"
                              less="voir moins"
                              anchorClass="my-anchor-css-class"
                              // onClick={executeOnClick}
                              expanded={false}
                              width={300}
                            >
                              <p
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                  __html: suivi.message,
                                }}
                              />
                            </ShowMoreText>
                        </td>
                        <td> {Moment(suivi.createdAt).format('LLL')} </td>
                        </tr>
                      ))}
                      </tbody>
                    </table>
              </div>
          </section>
          
          <Modal className="my-modal" animation={false} show={show} onHide={handleClose}>
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}
  
            <button type="button" onClick={handleClose} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <BoxSearchSuivi actionSearch={onSearchSuivi} collaborator={collaborators} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
  
      )
}

export default SuiviCollaboratorPerDay
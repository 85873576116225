import React from "react";
import Registration from '../components/registrationSpontannee';

const background = '/dist/img/EvaluationTete.png'
const background2 = '/dist/img/Evaluationbas.png'

const RegistrationSpontannee = () => (
    <div>
        <div className="row" style={{
            minHeight: "auto",
            height: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize : "100% auto"}}>
            <img src={background} alt="fond" width="100%" height="100%"/>
        </div>
           
        <div className="row" style={{backgroundImage: `url(${background2})` ,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%"}}>
            <div className="test-evaluation offset-lg-2 col-lg-8 col-xs-10 offset-xs-1">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <Registration />
                </div>
            </div>
        </div>
    </div>
)

export default RegistrationSpontannee
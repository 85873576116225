import React, { useState, FormEvent, useEffect } from "react";
import StarRatings from 'react-star-ratings';
import API from '../services/API/API';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

type actionCloseFunction = () => void

interface IProps {
    collaborateur: string
    client: string
    actionClose: actionCloseFunction
}

const EvaluationMoisComponent = ({ collaborateur, client, actionClose }: IProps) => {
    const [annee, setAnnee] = useState('2021')
    const [mois, setMois] = useState('')
    const [list, setList] = useState<Array<any>>([])
    const [noteSelected, setNoteSelected] = useState<Array<any>>([])
    const [commentSelected, setCommentSelected] = useState<Array<any>>([])
    const [definitions, setDefinitions] = useState<Array<any>>([])
    const [res, setRes] = useState<Array<any>>([])
    const [SumValue, setSumValue] = useState(0)
    const [isOk] = useState(false)

    const onChangeValue = (newRating: number, i: number, definition_id: string) => {
        const l = [...list];
        l[i] = { 
            mois,
            annee,
            id_candidat: collaborateur, 
            definition_id, 
            user_id: client,
            observation: commentSelected[i],
            note: newRating 
        }

        setList(l)

        const note = [...noteSelected];
        note[i] = newRating
        setNoteSelected(note)
    }

    const handleClose = () => {
        actionClose()
    }

    /* findSumNote(){
        const somme = noteSelected.reduce((somme,{price}) => somme+price,0)
        setState({
            noteSomme: somme
        });
    } */
      
    /* addItem(e) {
        if (_inputNote.value !== "") {
            var newItem = {
                user_id: props.client,
                observation: _inputObservation.value,
                definition_id: _inputDefinition.value,
                id_candidat: props.collaborateur,
                note: _inputNote.value,
                mois: _inputMois.value,
                annee: _inputAnnee.value,
            };

            setState((prevState) => {
                return {
                    items: previtems.concat(newItem)
                };
            });

            _inputObservation.value = "";
            _inputDefinition.value = "";
            _inputNote.value = "";
            _inputMois.value = "";
            _inputAnnee.value = "";
        }


    } */

    /* onChangeObservation(editorState) {
        setState({
            editorState,
            observation: draftToHtml(convertToRaw(editorgetCurrentContent()))
        });
    } */

    /* onChangeDefinition(e) {
        setState({
            definition: e.target.value
        });
    } */

    /* onChangeNote(e) {
        setState({
            note: e.target.value
        });
    } */

    const onChangeComment = (e: FormEvent<HTMLTextAreaElement>, i: number, definition_id: string) => {
        const l = [...list];
        l[i] = { 
            mois,
            annee,
            id_candidat: collaborateur, 
            definition_id, 
            user_id: client,
            observation: e.currentTarget.value,
            note: noteSelected[i]  
        }
        
        setList(l)

        const comment = [...commentSelected];
        comment[i] = e.currentTarget.value
        
        setCommentSelected(comment)
    }

    const onChangeMonth = (e: FormEvent<HTMLSelectElement>) => {
        setMois(e.currentTarget.value)
    }

    const onChangeYear = (e: FormEvent<HTMLInputElement>) => {
        setAnnee(e.currentTarget.value)
    }

    const saveEvaluation = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const data = {
            data: list,
        }

        /* service.createEvaluation(data, token)
            .then(response => {
                handleClose()
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            await API.CollaboratorEvaluation.createEvaluation(data, token)
            handleClose()
        }
        catch (error) {
            console.log(error)
        }
    }

    /* newDefinition() {
        setState({
            definition_id: null,
            items: [],
            libelle: ""
        });
    } */

    const retrieveDefinitions = async () => {
        /* service.getAllDefinition2()
            .then(response => {
                setState({
                    definitions: response.data
                });
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.CollaboratorEvaluation.getAllDefinition2(token)
            setDefinitions(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    /* retrieveNotes() {
        service.getAllNotes()
            .then(response => {
                setState({
                    notes: response.data
                });
            })
            .catch(e => {
                console.log(e);
            });
    } */

    useEffect(() => {
        (async () => {
            await retrieveDefinitions()
        })()

        setSumValue(noteSelected && noteSelected.reduce((a, v) => a + (v || 0), 0))
        
        let groups: any
        for (const i = 0; i < definitions.length; i + 1) {
            const groupName = definitions[i].categorie;
            if (!groups[groupName]) {
                groups[groupName] = [];
            }
            groups[groupName].push({"item": definitions[i].item, "id" : definitions[i].id,
            "categorie_id": definitions[i].categorie_id,
            "createdAt": definitions[i].createdAt,
            "definition": definitions[i].definition,
            "definition_id": definitions[i].definition_id,
            "item_id": definitions[i].item_id,
            "updatedAt": definitions[i].updatedAt})
        }
        const ev =[]

        // eslint-disable-next-line no-restricted-syntax
        for (const groupName in groups) {
            ev.push({categorie: groupName, items: groups[groupName]});
        }
        
        setRes(ev)
    }, [])

    return (
        <div>
            {/* Main content */}
            <section className="content">
                <div>
                    {isOk ? (
                        <div className="submitted">
                            <div style={{textAlign: 'center'}}>
                                <i className="ok fas fa-3x fa-check-circle"></i>
                                <h2><strong>Enregistrement terminé avec succès</strong></h2>
                            </div>
                        </div>
                    ) : (
                        <form onSubmit={saveEvaluation}>
                        <div className="submit-form">
                            <div className="row box-register">
                                <div className="col-10 offset-1 pt-5 pb-5">
                                    <div className="title-container">
                                        <div className="title">
                                            <h2>Evaluation</h2>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="mois">Mois</label>
                                                <select required style={{ width: '100%' }} value={mois} onChange={onChangeMonth} placeholder="Choisir un mois">
                                                    <option value=""></option>
                                                    <option value="1">Janvier</option>
                                                    <option value="2">Fevrier</option>
                                                    <option value="3">Mars</option>
                                                    <option value="4">Avril</option>
                                                    <option value="5">Mai</option>
                                                    <option value="6">Juin</option>
                                                    <option value="7">Juillet</option>
                                                    <option value="8">Août</option>
                                                    <option value="9">Septembre</option>
                                                    <option value="10">Octobre</option>
                                                    <option value="11">Novembre</option>
                                                    <option value="12">Décembre</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="annee">Année</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="annee"
                                                    required
                                                    value ={annee}
                                                    onChange={onChangeYear}
                                                    name="annee"
                                                    placeholder=""
                                                />
                                            </div>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            {res.map((def) => {
                                                const definition = def.items
                                                
                                                return (
                                                <div>
                                                    <div className="row col-12" style={{fontWeight:"bold", fontSize:"x-large"}}>{def.categorie}</div>
                                                    {definition.map((item: any) => 
                                            
                                                (
                                                    <div className="form-group" key={item.id}>
                                                    <div className="row">
                                                    <div className="col-5">
                                                    <label htmlFor={item.definition_id}><abbr title={item.definition}>{item.item}</abbr><span className="custom-text-blue">(  {noteSelected[item.id] != null ? (`${noteSelected[item.id]  }/5`) : `${0  }/5`})</span></label>
                                                    </div>
                                                    <div className="col-4">
                                                    <StarRatings
                                                        rating={noteSelected[item.id] != null ? (noteSelected[item.id]) : 0.0}
                                                        starRatedColor="blue"
                                                        changeRating={newRating => onChangeValue(newRating, item.id, item.definition_id)}
                                                        numberOfStars={5}
                                                        name={item.definition_id}
                                                        starDimension="30px"
                                                        starSpacing="15px"
                                                        />  
                                                    </div>
                                                    <div className="col-3">
                                                        <textarea
                                                            placeholder="Commentaire"
                                                            className="form-control"
                                                            id={item.definition_id}
                                                            value={commentSelected[item.id] != null ? (commentSelected[item.id]) : ""}
                                                            onChange={e  => onChangeComment(e, item.id, item.definition_id)}
                                                            name={item.definition_id}
                                                        />
                                                    </div>
                                                    </div>
                                                </div>
                                            ))}

                                                </div>
                                              )
                                            })}
                                            
                                        </div>

                                    </div>
                                    <div className="row" style={{display:"flex"}}>
                                    <div className="col-5"></div>
                                    <div className="col-4" style={{fontSize:"2em", backgroundColor:"#929092a3", padding:"5px"}}>Total : {SumValue} / 40</div>
                                    <div className="col-3"></div>
                                    </div>

                                    <div className="row">
                                        <div className="col-12">
                                            <button  type="submit" className="btn btn-save-information"> Enregistrer </button>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        </form>
                    )}
                </div>
            </section>
        </div>
    )
}

export default EvaluationMoisComponent
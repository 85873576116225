import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { EditorState, convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import API from "../services/API/API";
import PageHead from "../components/pageHead";
import Mailbox from "../components/mailbox/Mailbox";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../styles/styles.css";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}
interface IProps {
    match: IMatch
}

const SuiviClient = ({ match }: IProps) => {
    const role = sessionStorage.getItem("role");
    const [message, setMessage] = useState('')
    const [client_id,] = useState(match.params.id ?? '')
    const [id_user,] = useState(sessionStorage.getItem('id') ?? '')
    const [suivis, setSuivis] = useState<Array<any>>([])
    const [show, setShow] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }
    
    /* const onChangeMsg = (e: FormEvent<HTMLInputElement>) => {
        setMessage(e.currentTarget.value)
    } */

    const getSuiviClient = async () => {
        /* service.getSuiviClient(client_id, token)
            .then(response => {
                setState({
                    suivis: response.data
                });
                console.log(response.data);
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.Client.getSuiviClient(client_id, token)
            setSuivis(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const addSuivi = async (client_idP: string, id_userP: string) => {
        const data = {
            client_id: client_idP,
            id_user: id_userP,
            message
        };

        /* service.createSuiviClient(data, token)
            .then(response => {
                setState({
                    message: "",
                    show: false
                });
                getSuiviClient();
                // window.location.href = "/SuiviClient/" + client_id;
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            await API.Client.createSuiviClient(data, token)
            setMessage('')
            setShow(false)
            await getSuiviClient()
        }
        catch (error) {
            console.log(error)
        }
    }

    /* const onEditorStateChange = (editorStateP: EditorState) => {
        setEditorState(editorStateP)
    } */

    const onChangeFichePoste = (editorStateP: EditorState) => {
        setEditorState(editorStateP)
        setMessage(draftToHtml(convertToRaw(editorStateP.getCurrentContent())))
    }

    useEffect(() => {
        (async () => {
            await getSuiviClient()
        })()
    }, [])

    return (
        <div>
            <div
            className="content-wrapper"
            style={{backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%"}}
            >
    <PageHead title="Rapport Client" subject="Liste des rapports" name="Rapport" />
            {/* SEARCH FORM */}
      <div className="action-container">
        {role !== "user" ? (
          <div className="searchbar-container">
             <div className="button-action">
            <Button
              onClick={handleShow}
              className="universal-length mbtn"
              type="submit"
            >
              <i className="fas fa-plus-circle"></i>{" "}
              <strong>Ajouter une remarque</strong>
            </Button>
          </div>
          </div>
        ) : null}
        {/*
          role !== 'user' ?
            <div classname="button-action">
              <Link to="/Register">
                <Button className="universal-length mbtn" block type="submit">
                  <i class="fas fa-plus-circle"></i> <strong>Ajouter un client</strong>
                </Button>
              </Link>
            </div>
            : null
            */}
      </div>
            <Mailbox data={suivis}/>
        </div>
        <Modal
      className="my-modal"
      animation={false}
      show={show}
      onHide={handleClose}
    >
      {/* <Modal.Header closeButton> */}
      {/* <Modal.Title></Modal.Title> */}
      {/* </Modal.Header> */}

      <button type="button" onClick={handleClose} className="close">
        <i className="fas fa-times"></i>
        <span className="sr-only">Close</span>
      </button>
      <Modal.Body>
        <div className="row">
          
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div>
            <div className="content">
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div>
                        
                        {/* Small boxes (Stat box) */}
                        <div className="row box-register">
                            <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="comment" className="control-label">Commentaire</label>
                                            <Editor
                                              editorState={editorState}
                                              editorStyle={{ border: "1px solid #C0C0C0", minHeight: "75px", fontFamily: "'Montserrat', sans-serif;"}}
                                              onEditorStateChange={onChangeFichePoste} 
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                    <Button className="universal-length mbtn" onClick={() => addSuivi(client_id, id_user)}>
                                            <strong><i className="fas fa-succes"></i> Enregister</strong>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
    </div>
    );
}

export default SuiviClient
import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import API from '../services/API/API'
import PageHead from "../components/pageHead";
import ItemComponent from "../components/item";

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");
const limitPage = "25";

const Item = () => {
    const role = sessionStorage.getItem("role");
    const [items, setItems] = useState<Array<any>>([])
    const [searchTitle, setSearchTitle] = useState('')
    const [show, setShow] = useState(false)
    const [, setShowSearch] = useState(false)
    const [showUpdate, setShowUpdate] = useState(false)
    const [libelle, setLibelle] = useState('')
    const [categorie, setCategorie] = useState('')
    const [categories, setCategories] = useState<Array<any>>([])
    const [activePage, setActivePage] = useState(1)
    const [totalCustomers, setTotalCustomers] = useState(0)
    const [search, setSearch] = useState(false)
    const [currentItem, setCurrentItem] = useState<any>()

    const onChangeLibelle = (e: FormEvent<HTMLInputElement>) => {
        setLibelle(e.currentTarget.value)
    }
    
    const onChangeCategorie = (e: FormEvent<HTMLSelectElement>) => {
        setCategorie(e.currentTarget.value)
    }

    const handleShowUpdate = (item: any) => {
        setShowUpdate(true)
        setCurrentItem(item)
        setLibelle(item.libelle)
        setCategorie(item.categorie_id)
    }

    const handleCloseUpdate = () => {
        setShowUpdate(false)
    }

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }
    
    // eslint-disable-next-line no-unused-vars
    const retrieveItems = async (pageNumber: number) => {
        /* service
          .getAllItems(token)
          .then((response) => {
            setState({
              items: response.data,
              totalCustomers: response.data.totalCustomers,
              showSearch: false,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.CollaboratorEvaluation.getAllItems(token)
            setItems(response.data)
            setTotalCustomers(response.data.totalCustomers)
            setSearch(false)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value)
        if (e.currentTarget.value === "") {
            await retrieveItems(activePage);
        } else {
          /* service
            .findItem(searchTitle, token)
            .then((response) => {
              setState({
                items: response.data,
                totalCustomers: response.data.totalCustomers,
              });
              console.log(response.data);
            })
            .catch((e) => {
              console.log(e);
            }); */
            try {
                const response = await API.CollaboratorEvaluation.findItem(searchTitle, token)
                setItems(response.data)
                setTotalCustomers(response.data.totalCustomers)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    
    // eslint-disable-next-line no-unused-vars
    const onSearchDefinition = async (nom: string, prenom: string, note: string, prix: string, point_faible: string, point_fort: string, poste: string, sousposte: string) => {
        if (
          nom === "" &&
          prenom === "" &&
          note === "" &&
          prix === "" &&
          point_faible === "" &&
          point_fort === "" &&
          poste === ""
        ) {
            await retrieveItems(activePage);
        } else {
          /* service
            .searchCustomer(
              nom,
              prenom,
              note,
              prix,
              point_faible,
              point_fort,
              poste,
              sousposte,
              activePage,
              limitPage,
              token
            )
            .then((response) => {
              setState({
                items: response.data.results,
                totalCustomers: response.data.totalCustomers,
                showSearch: false,
                search: true,
              });
            })
            .catch((e) => {
              console.log(e);
            }); */
            try {
                const response = await API.Candidate.searchCustomer(
                    nom,
                    prenom,
                    note,
                    prix,
                    point_faible,
                    point_fort,
                    poste,
                    sousposte,
                    activePage,
                    limitPage,
                    token
                )
                setItems(response.data.results)
                setTotalCustomers(response.data.totalCustomers)
                setShowSearch(false)
                setSearch(true)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    
    const handlePageChange = async (pageNumber: number) => {
        setActivePage(pageNumber)
        if (!search) await retrieveItems(pageNumber)
    }
    
    const retrieveCategories = async () => {
        /* service.getAllCategories()
          .then(response => {
            setState({
              categories: response.data
            });
            //console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.CollaboratorEvaluation.getAllCategories(token)
            setCategories(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const updateItem = async (id: string, libelleP: string, categorieP: string) => {
        const data = {
          libelle: libelleP,
          categorie_id: categorieP
        };
        /* service
          .updateItem(id, data, token)
          .then((response) => {
            setState({
              id: response.data.id,
              showUpdate: false,
              libelle: ""
            });
            retrieveItems(activePage);
            retrieveCategories();
          })
          .catch((e) => {
            alert(e);
            console.log(e);
          }); */
        try {
            await API.CollaboratorEvaluation.updateItem(id, data, token)
            setShowUpdate(false)
            setLibelle('')
            await retrieveItems(activePage)
            await retrieveCategories()
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveItems(activePage)
            await retrieveCategories()
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%"
            }}
          >
            {/* TITLE & BREADCRUMBS */}
            {role !== "user" && role !== "collaborator" ? (
              <PageHead title="Items" subject="Liste items" name="Items" />
  
            ) : null}
  
            {/* SEARCH FORM */}
            {role !== "user" && role !== "collaborator" ? (
              <div className="action-container">
                <div className="searchbar-container">
                  <div className="form-inline">
                    <div className="input-group input-group-sm" title="Vous pouvez faire des recherche par nom, premon, note, disponibilté ou status">
                      <span className="universal-length icon-r">
                        <i className="fas fa-search"></i>
                      </span>{" "}
                      <input
                        className="form-control form-control-navbar universal-length recherche"
                        value={searchTitle}
                        onChange={onChangeSearchTitle}
                        type="search"
                        placeholder="Rechercher"
                        aria-label="Search"
                      />
                    </div>
  
                  </div>
                </div>
                <div className="button-action" title="Vous pouvez ajouter un candidat">
                  {/* <Link to="/Registration"> */}
                  {/* <Button className="universal-length mbtn" block type="submit">
                      <i class="fas fa-plus-circle"></i> <strong>Ajouter un candidat</strong>
                    </Button> */}
                  <Button
                    onClick={handleShow}
                    className="universal-length mbtn"
                    type="submit"
                  >
                    <i className="fas fa-plus-circle"></i>{" "}
                    <strong>Ajouter un item</strong>
                  </Button>
                  {/* </Link> */}
                </div>
              </div>
            ) : null}
  
            {/* END SEARCH FORM */}
            <section className="content">
              <div>
                {role !== "user" && role !== "collaborator" ? (
                  <div>
                    <table className="table table-curved" style={{ backgroundColor: "#f8f9fa" }}>
                      <thead >
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Categorie</th>
                          <th scope="col">Libelle</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.map((item) => (
                          <tr>
                            <td> {item.item_id} </td>
                            <td> {item.categorie.libelle} </td>
                            <td> {item.libelle} </td>
                            <td>
                              <div className="row r-favori">
                                <div className="col-12" title="Modifier">
                                  <Button
                                    className="universal-length mbtn btn-favori"
                                    onClick={() => handleShowUpdate(item)}
                                  >
                                    <strong>
                                      <i className="fas fa-edit"></i>{" "}
                                    </strong>
                                  </Button>
                                </div>
  
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
  
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={items.length}
                      totalItemsCount={totalCustomers}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
  
                ) : (
                  <div>
                    <h1>Bienvenue sur notre plateforme</h1>
                  </div>
                )}
              </div>
            </section>
  
            <Modal
              className="my-modal"
              animation={false}
              show={show}
              onHide={handleClose}
            >
  
              <button type="button" onClick={handleClose} className="close">
                <i className="fas fa-times"></i>
                <span className="sr-only">Close</span>
              </button>
              <Modal.Body>
                <div className="row">
  
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <ItemComponent actionClose={handleClose} />
                  </div>
                </div>
              </Modal.Body>
            </Modal>
  
            <Modal className="my-modal" id="0" animation={false} show={showUpdate} onHide={handleCloseUpdate} style={{width:"60%", padding:"10px"}}>
              <button type="button" onClick={handleCloseUpdate} className="close" style={{position:"initial"}}>
                <i className="fas fa-times"></i>
                <span className="sr-only">Close</span>
              </button>
              <Modal.Body>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{padding:"5%"}}>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="libelle">Libelle</label>
                        <input
                          type="text"
                          className="form-control"
                          id="libelle"
                          required
                          value={libelle}
                          onChange={onChangeLibelle}
                          name="libelle"
                          style={{height:"35px"}}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group">
                        <label htmlFor="categorie">Categorie</label>
                        <select  required style={{ width: '100%', minHeight:"35px"}} value={categorie} onChange={onChangeCategorie} placeholder="Choisir un Item">
                            <option value=""></option>
                            {categories.map((el: any) => 
                            el.categorie_id === categorie ?
                            (
                                <option value={el.categorie_id} selected>{el.libelle}</option>
                            ):
                            <option value={el.categorie_id}>{el.libelle}</option>
                            )}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <button className="w-100 btn-register btn btn-success" onClick={() => updateItem(currentItem.item_id, libelle, categorie)} type="submit">
                    <strong><i className="fas fa-edit"></i> Modifier </strong>
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )
}

export default Item
import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Multiselect } from 'multiselect-react-dropdown';
import ShowMoreText from "react-show-more-text";
// eslint-disable-next-line no-irregular-whitespace
import swal from 'sweetalert';
import API from "../services/API/API";
import Register from "../components/registerClient";
import PageHead from "../components/pageHead";

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

const UserList = () => {
    const role = sessionStorage.getItem("role");
    const [users, setUsers] = useState<Array<any>>([])
    const [searchTitle, setSearchTitle] = useState('')
    const [searchTitlePoste, setSearchTitlePoste] = useState('')
    const [show, setShow] = useState(false)
    const [showDetail, setShowDetail] = useState(false)
    const [client_id, setClient_id] = useState('')
    const [, setClient_user_id] = useState('')
    const [company, setCompany] = useState('')
    const [activite, setActivite] = useState('')
    const [fiche, setFiche] = useState('')
    const [interest, setInterest] = useState('')
    const [options, ] = useState(['Tout', 'Faible', 'Moyen', 'Fort', 'Sortie'])

    const handleClose = () => {
        setShow(false)
    }
    
    const handleShow = () => {
        setShow(true)
    }

    const handleCloseDetail = () => {
        setShowDetail(false)
    }
    
    const handleShowDetail = () => {
        setShowDetail(true)
    }

    const details = (user: any) => {
        setClient_id(user.id)
        setCompany(user.company)
        setFiche(user.fiche)
        setActivite(user.activite)
        setInterest(user.interest)
        setClient_user_id(user.users[0].id)
        handleShowDetail();
    }
      
    const retrieveUsers = async () => {
        try {
            const response = await API.Client.getClientNotOk(token)
            setUsers(response.data.reverse())
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const onSelect = async (selectedList: Array<any>, selectedItem: string) => {
        let search = selectedList
        if(selectedItem === "Tout")
            search = ['Faible','Moyen','Fort','Sortie']

        const data = {
          nom : search
        }
        try {
            const response = await API.Client.searchClientProspectbyinterset(data, token)
            console.log(response.data)
            setUsers(response.data)
        }   
        catch (error) {
            console.log(error)
        } 
    }

    
    
    const onRemove = async (selectedList: Array<any>) => {
        if(selectedList.length <= 0){
            await retrieveUsers();
        }
        else {
            const data = {
                nom : selectedList
            }
            /* service
            .searchClientProspectbyinterset(data, token)
            .then((response) => {
            setState({
                users: response.data,
            });
            //console.log(response.data);
            })
            .catch((e) => {
            console.log(e);
            }); */
            try {
                const response = await API.Client.searchClientProspectbyinterset(data, token)
                setUsers(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    
    }

    const deleteClient = async (user_idP: string, client_idP: string) => {
        try {
            await API.Client.deleteClient(user_idP, client_idP, token)
            swal("Pouf ! Votre fichier a été supprimé !", {
                icon: "success",
            });
            await retrieveUsers()
        }
        catch (error) {
            console.log(error)
        }
    }

    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value)
        /* service
          .searchClientProspect(searchTitle, token)
          .then((response) => {
            setState({
              users: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Client.searchClientProspect(e.currentTarget.value, token)
            setUsers(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const onChangeSearchTitlePoste = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitlePoste(e.currentTarget.value)
        /* service
          .searchClientProspectbytitle(searchTitlePoste, token)
          .then((response) => {
            setState({
              users: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Client.searchClientProspectbytitle(searchTitlePoste, token)
            setUsers(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const onSuccess = async () => {
        await retrieveUsers()
        setTimeout(() => {
          handleClose()
          handleCloseDetail()
        }, 1500)
    }

    useEffect(() => {
        (async () => {
            await retrieveUsers()
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
              <PageHead title="Les Clients prospects" subject="Liste client prospect" name="Clients" />
            {/* SEARCH FORM */}
            <div className="action-container">
              {role !== "user" ? (
                <div className="searchbar-container">
                  <div className="form-inline">
                    <div className="input-group input-group-sm">
                      <span className="universal-length icon-r">
                        <i className="fas fa-search"></i>
                      </span>{" "}
                      <input
                        className="form-control form-control-navbar universal-length recherche  col-9"
                        value={searchTitle}
                        onChange={onChangeSearchTitle}
                        type="search"
                        placeholder="Rechercher par nom et activité"
                        aria-label="Search"
                      />
                    </div>
                    <div className="input-group input-group-sm">
                      <span className="universal-length icon-r">
                        <i className="fas fa-search"></i>
                      </span>{" "}
                      <input
                        className="form-control form-control-navbar universal-length recherche col-9"
                        value={searchTitlePoste}
                        onChange={onChangeSearchTitlePoste}
                        type="search"
                        placeholder="Rechercher par titre poste"
                        aria-label="Search"
                      />
                    </div>
                    <div className="button-action col-7">
                  <Button
                    onClick={handleShow}
                    className="universal-length mbtn"
                    type="submit"
                  >
                    <i className="fas fa-plus-circle"></i>{" "}
                    <strong>Ajouter un client prospect</strong>
                  </Button>
                </div>
                  </div>
                  <div className="sep"></div>
                  <Multiselect
                  options={options} // Options to display in the dropdown
                  onSelect={onSelect} // Function will trigger on select event
                  onRemove={onRemove} // Function will trigger on remove event
                  isObject={false}
                  placeholder = "Rechecher par intérêt"
                  showCheckbox
                  style={{searchBox: {border: "1",
                  background : "white",
                    fontSize: "10px",
                    minHeigth: "50px",
                    width : "60%"
                  }}}
                  emptyRecordMsg = "Il n'y a plus d'options possible"
                  />
                </div>
              ) : null}
              {/*
                role !== 'user' ?
                  <div classname="button-action">
                    <Link to="/Register">
                      <Button className="universal-length mbtn" block type="submit">
                        <i class="fas fa-plus-circle"></i> <strong>Ajouter un client</strong>
                      </Button>
                    </Link>
                  </div>
                  : null
                  */}
            </div>
            <section className="content">
              <div className="row userList">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Société</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Intérêt</th>
                        <th scope="col" style={{width:"40%"}}>Fiche de poste</th>
                        <th className="text-center" colSpan={3} scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => (
                        <tr>
                          <th scope="row">
                            <Link to={`/DetailClient/${  user.id}`}>
                              <i className="fas fa-info-circle"></i>
                            </Link>
                          </th>
                          <td>{user.company}</td>
                          <td
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: user.activite }}
                          />
                          <td>{user.interest}</td>
                          <td>
                          <b>Principale</b>
                          <ShowMoreText
                            /* Default options */
                            lines={1}
                            more="voir plus"
                            less="voir moins"
                            anchorClass="my-anchor-css-class"
                            // onClick={executeOnClick}
                            expanded={false}
                            width={400}
                          >
                            <p
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{ __html: user.fiche }}
                            />
                            <div className="sep"></div>
                            <div className="sep"></div>
                          </ShowMoreText>
                          {user.fiches.map((item: any) => (   
                            <div>
                            <b>{item.title}</b>
                            <ShowMoreText
                            /* Default options */
                            lines={1}
                            more="voir plus"
                            less="voir moins"
                            anchorClass="my-anchor-css-class"
                            // onClick={executeOnClick}
                            expanded={false}
                            width={500}
                          >
                            {/* eslint-disable-next-line react/no-danger */}
                            <p  dangerouslySetInnerHTML={{__html: item.message}}/> 
                          </ShowMoreText>                     
                          </div>
                          ))}
                          </td>
                          <td>
                            <Link to={`/CandidatList/${  user.users[0].id}`}>
                              <a className="small-box-footer">
                                Voir candidat assigné{" "}
                                <i className="fas fa-arrow-circle-right" />
                              </a>
                            </Link>
                          </td>
                          <td className="decision accept" align="right">
                              <button type="button" onClick={() => details(user)}>
                              <i className="fas fa-edit"></i> Modifier
                              </button>
                            </td>
                            <td className="decision deny" align="right">
                              <button type="button" onClick={() => deleteClient(user.users[0].id, user.id)}>
                              <i className="fas fa-trash-alt"></i> supprimmer
                              </button>
                            </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
            <Modal
            className="my-modal"
            animation={false}
            show={showDetail}
            onHide={handleCloseDetail}
          >
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}
  
            <button type="button" onClick={handleCloseDetail} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 left-image-register">
                  <div className="text">
                    <p>Olona</p>
                    <p>digital . outsourcing . data</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <Register mode="update" onSuccess={onSuccess} client_id={client_id} activite={activite} company={company} fiche={fiche} interest={interest}/>
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="my-modal"
            animation={false}
            show={show}
            onHide={handleClose}
          >
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}
  
            <button type="button" onClick={handleClose} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 left-image-register">
                  <div className="text">
                    <p>Olona</p>
                    <p>digital . outsourcing . data</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <Register mode="register" onSuccess={onSuccess} client_id={client_id} activite={activite} company={company} fiche={fiche} interest={interest} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>    
    )
}

export default UserList
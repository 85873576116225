import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-irregular-whitespace
import swal from 'sweetalert';
import Moment from 'moment'
import API from "../services/API/API";
import Register from "../components/register";
import PageHead from "../components/pageHead";
import 'moment/locale/fr';

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

const SuiviClientList = () => {
    const role = sessionStorage.getItem("role");
    const [users, setUsers] = useState<Array<any>>([])
    const [searchTitle, setSearchTitle] = useState('')
    const [show, setShow] = useState(false)

    const handleClose = () => {
        setShow(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    const retrieveUsers = async () => {
        /* service
          .getClientOk(token)
          .then((response) => {
            setState({
              users: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Client.getClientOk(token)
            setUsers(response.data)
            console.log(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const deleteClient = async (user_id: string, client_id: string) => {
        /* service
        .deleteClient(user_id, client_id, token)
        .then((response) => {
          //SEND MAIL NOT OK
          // sendMyMail(candidat.mail, candidat.firstname+" "+candidat.lastname, "vous êtes refusé");
          swal("Pouf ! Votre fichier a été supprimé !", {
            icon: "success",
          });
          retrieveUsers();
        })
        .catch((e) => {
          console.log(e);
        }); */
        try { 
            await API.Client.deleteClient(user_id, client_id, token)
            swal("Pouf ! Votre fichier a été supprimé !", {
                icon: "success",
            });
            await retrieveUsers();
        }
        catch (error) {
            console.log(error)
        }
    }

    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value);
        /* service.searchClient(searchTitle, token)
        .then(response => {
          setState({
            users: response.data
          });
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        }); */
        try {
            const response = await API.Client.searchClient(e.currentTarget.value, token)
            setUsers(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        Moment.locale('fr');
        (async () => {
            await retrieveUsers();
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{backgroundImage: `url(${background})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100% 100%"}}
          >
            <PageHead title="Les Clients" subject="Liste des clients" name="Clients" />
            {/* SEARCH FORM */}
            <div className="action-container">
              {role !== "user" ? (
                <div className="searchbar-container">
                  <div className="form-inline">
                    <div className="input-group input-group-sm">
                      <span className="universal-length icon-r">
                        <i className="fas fa-search"></i>
                      </span>{" "}
                      <input
                        className="form-control form-control-navbar universal-length recherche col-9"
                        value={searchTitle}
                        onChange={onChangeSearchTitle}
                        type="search"
                        placeholder="Rechercher"
                        aria-label="Search"
                      />
                    </div>
                    {role === "admin" ? (
                <div className="button-action">
                  <Button
                    onClick={handleShow}
                    className="universal-length mbtn"
                    type="submit"
                  >
                    <i className="fas fa-plus-circle"></i>{" "}
                    <strong>Ajouter un client</strong>
                  </Button>
                </div>
              ) : null}
                  </div>
                </div>
              ) : null}
            
              {/*
                role !== 'user' ?
                  <div classname="button-action">
                    <Link to="/Register">
                      <Button className="universal-length mbtn" block type="submit">
                        <i class="fas fa-plus-circle"></i> <strong>Ajouter un client</strong>
                      </Button>
                    </Link>
                  </div>
                  : null
                  */}
            </div>
            <section className="content">
              <div className="row userList">
                <div className="table-responsive">
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Société</th>
                        <th scope="col">Activité</th>
                        <th scope="col">Date du dernier rapport</th>
                        <th className="text-center" colSpan={3} scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {users.map((user) => {
                        const last = user.suivi_clients;
                        let lastRapport = "";
                        last.forEach((rank: any, i: number) => {
                          if (last.length - 1 === i) {
                            const cts = rank.createdAt;
                            lastRapport = Moment(cts).format('LLL')
                          } else {
                            lastRapport = ""
                          }
                        });
                        return (
                          <tr>
                            <th scope="row">
                              <Link to={`/DetailClientSuivi/${user.id}`}>
                                <i className="fas fa-info-circle"></i>
                              </Link>
                            </th>
                            <td>{user.company}</td>
                            {/* eslint-disable-next-line react/no-danger */}
                            <td dangerouslySetInnerHTML={{__html: user.activite}} />
                            <td>{lastRapport}</td>
                            <td>
                              <Link to={`/SuiviClient/${user.id}`}>
                                <a className="small-box-footer">
                                  Voir le suivi{" "}
                                  <i className="fas fa-arrow-circle-right" />
                                </a>
                              </Link>
                            </td>
                            <td>
                              <Link to={`/CollaboratorList/${  user.id}`}>
                                <a className="small-box-footer">
                                  Voir collaborateur assigné{" "}
                                  <i className="fas fa-arrow-circle-right" />
                                </a>
                              </Link>
                            </td>
                            <td className="decision deny" align="right">
                              <button type="button" onClick={() => deleteClient(user.users[0].id, user.id)}>
                              <i className="fas fa-trash-alt"></i>supprimmer
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </section>
          </div>
          <Modal
            className="my-modal"
            animation={false}
            show={show}
            onHide={handleClose}
          >
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}
  
            <button type="button" onClick={handleClose} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 left-image-register">
                  <div className="text">
                    <p>Olona</p>
                    <p>digital . outsourcing . data</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <Register />
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      );
}

export default SuiviClientList
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, FormEvent } from "react";
import Moment from "moment";
import "moment/locale/fr";
import { Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import API from "../services/API/API";
import "react-datepicker/dist/react-datepicker.css";
import "../styles/styles.css";

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

interface IParams {
  id: string
}

interface IMatch {
  params: IParams
}

interface IProps {
  match: IMatch
}

const DoPresence = ({ match }: IProps) => {
    const today =  new Date();
    const presenceDay = Moment(today).format("LL");
    // const [message, setMessage] = useState('')
    const [client_id, ] = useState(match.params.id ?? '')
    const [id_user, ] = useState(sessionStorage.getItem("id") ?? '')
    const [date_start, setDate_start] = useState(new Date())
    const [date_end, setDate_end] = useState(new Date())
    const [suivis, setSuivis] = useState([])
    const [mypresences, setMyPresences] = useState([])
    // eslint-disable-next-line no-unused-vars
    const presences = [
        { name: "Présent" },
        { name: "Absent" },
        { name: "Absent non justifier" },
        { name: "Malade" },
        { name: "Congé" },
    ]
    const rowmois = [
        { id: "1", name: "Janvier" },
        { id: "2", name: "Février" },
        { id: "3", name: "Mars" },
        { id: "4", name: "Avril" },
        { id: "5", name: "Mai" },
        { id: "6", name: "Juin" },
        { id: "7", name: "Juillet" },
        { id: "8", name: "Aout" },
        { id: "9", name: "Septembre" },
        { id: "10", name: "Octobre" },
        { id: "11", name: "Novembre" },
        { id: "12", name: "Décembre" },
    ]
    const [mois, setMois] = useState('')
    const [etat, setEtat] = useState('Présent')
    const [etatsearch, setEtatsearch] = useState('mois')
    const [showetatsearch, setShowetatsearch] = useState(false)

    const onChangeDateStart = (date: Date | [Date | null, Date | null ] | null) => {
        if (date) {
          const d = date as Date
          d.setHours(0, 0, 0, 0);
          setDate_start(d)
        }
    }
    
    const handleSearchTwoDate = async (dateStart: Date, dateEnd: Date) => {
        /* service
            .getMyPresenceTwoDate(
              id_user,
              client_id,
              date_start,
              date_end,
              token
            )
            .then((response) => {
              setState({
                mypresences: response.data,
              });
            })
            .catch((e) => {
              console.log(e);
            }); */
        try {
            const response = await API.Prestance.getMyPresenceTwoDate(id_user, client_id, dateStart, dateEnd, token)
            setMyPresences(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const onChangeDateEnd = (date: Date | [Date | null, Date | null ] | null) => {
        if (date) {
          const d = date as Date
          d.setHours(23, 59, 0, 0);
          setDate_end(d)
        }
    }
    
    const onChangeEtat = (e: FormEvent<HTMLInputElement>) => {
        setEtat(e.currentTarget.value)
    }
    
    const onChangeEtatSearch = (e: FormEvent<HTMLInputElement>) => {
        setEtatsearch(e.currentTarget.value)
        
        if(e.currentTarget.value === 'mois')
          setShowetatsearch(false)
        else
          setShowetatsearch(true)
    }

    const getPresenceCollaborator = async () => {
      /* service
        .getPresenceCollaborator(id_user, client_id, token)
        .then((response) => {
          setState({
            suivis: response.data,
          });
  
        })
        .catch((e) => {
          console.log(e);
        }); */
        try {
          const response = await API.Prestance.getPresenceCollaborator(id_user, client_id, token)
          setSuivis(response.data)
        }
        catch (error) {
          console.log(error)
        }
    }

    const getMyPresenceCollaborator = async () => {
      /* service
        .getMyPresenceCollaborator(
          id_user,
          client_id,
          token
        )
        .then((response) => {
          setState({
            mypresences: response.data,
          });
        })
        .catch((e) => {
          console.log(e);
        }); */
        try {
          const response = await API.Prestance.getMyPresenceCollaborator(id_user, client_id, token)
          setMyPresences(response.data)
        }
        catch (error) {
          console.log(error)
        }
    }

    const onChangeMois = async (e: FormEvent<HTMLSelectElement>) => {
        setMois(e.currentTarget.value)
    }
    
      const addPresence = async () => {
        const data = {
          client_id,
          id_user,
          etat,
        };
        /* service
          .addPresence(data, token)
          .then((response) => {
            setState({});
            getPresenceCollaborator();
            getMyPresenceCollaborator();
          })
          .catch((e) => {
            console.log(e);
          }); */
          try {
            await API.Prestance.addPresence(data, token)
            await getPresenceCollaborator()
            await getMyPresenceCollaborator()
          }
          catch (error) {
            console.log(error)
          }
      }
      const updatePresence = async (id_presence: string, periode: string) => {
        /* service
          .updatePresence(id_presence, periode, token)
          .then((response) => {
            setState({});
            getPresenceCollaborator();
            getMyPresenceCollaborator();
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
          await API.Prestance.updatePresence(id_presence, periode, token)
          await getPresenceCollaborator()
          await getMyPresenceCollaborator()
        }
        catch (error) {
          console.log(error)
        }
      }

      const hoursStringToDecimal = (hoursString: string) => {
        const [hoursPart, minutesPart] = hoursString.split(":");
        return Number(hoursPart) + Number(minutesPart) / 60;
      }

      const decimalHoursToString = (hoursDecimal: number) => {
        const numHours = Math.floor(hoursDecimal);
        const numMinutes = Math.round((hoursDecimal - numHours) * 60);
        return `${numHours < 10 ? "0" : ""}${numHours}:${
          numMinutes < 10 ? "0" : ""
        }${numMinutes}`;
      }

      useEffect(() => {
        (async () => {
          if (mois === '') {
            await getMyPresenceCollaborator();
          } else {
              try {
                const response = await API.Prestance.getMyPresenceParMois(id_user, client_id, mois, token)
                setMyPresences(response.data)
                
              }
              catch (error) {
                console.log(error)
              }
          }
        })()
      }, [mois])
    
      useEffect(() => {
        Moment.locale("fr");
        (async () => {
          await getPresenceCollaborator()
          await getMyPresenceCollaborator()
        })()
      }, [])

      return (
        <div>
          <div
            className="content-wrapper"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            {/* /.content-header */}
            {/* Main content */}
  
            <section className="content">
              <div>
                {/* Small boxes (Stat box) */}
                <div className="row">
                  {/* USER INFO */}
                  <div className="box-item detail">
                    {/* small box */}
                    <div className="">
                      {!suivis.length ? (
                        <div>
                          <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="poste">Présence du {presenceDay}</label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Présent"
                                    checked={etat === "Présent"}
                                    onChange={onChangeEtat}
                                  />
                                  Présent
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Absent"
                                    checked={etat === "Absent"}
                                    onChange={onChangeEtat}
                                  />
                                  Absent
                                </label>
                              </div>
                            </div>
                            <div className="col-7">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Absent non justifier"
                                    checked={
                                      etat === "Absent non justifier"
                                    }
                                    onChange={onChangeEtat}
                                  />
                                  Absent non justifier
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Malade"
                                    checked={etat === "Malade"}
                                    onChange={onChangeEtat}
                                  />
                                  Maladie
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label>
                                  <input
                                    type="radio"
                                    value="Congé"
                                    checked={etat === "Congé"}
                                    onChange={onChangeEtat}
                                  />
                                  Congé
                                </label>
                              </div>
                            </div>
                          </div>
  
                          <div className="row">
                            <div className="col-12">
                              <button
                                type="button"
                                onClick={addPresence}
                                className="w-20 btn btn-success"
                              >
                                Enregistrer
                              </button>
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="sep"></div>
                      <div className="sep"></div>
                      <div className="row userList">
                        <div className="table-responsive">
                          <table className="table">
                            <thead className="thead-dark">
                              <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Matricule</th>
                                <th scope="col">Nom</th>
                                <th scope="col">Etat</th>
                                <th scope="col">Entrée</th>
                                <th scope="col">Sortie</th>
                                <th scope="col">Entrée</th>
                                <th scope="col">Sortie</th>
                                <th scope="col">Total heure</th>
                                <th scope="col">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {suivis.map((suivi: any) => suivi.presences.map((present: any) => {
                                  const cts = present.createdAt;
                                  const lastRapport = Moment(cts).format("LL");
                                  const {debut} = present;
                                  const finmidi = present.fin_midi;
                                  const debutHour =
                                    hoursStringToDecimal(debut);
                                  const finhour =
                                    hoursStringToDecimal(finmidi);
                                  const debutmidi = present.debut_midi;
                                  const {fin} = present;
                                  const debutmidiHour =
                                    hoursStringToDecimal(debutmidi);
                                  const finmidihour =
                                    hoursStringToDecimal(fin);
                                  const sommematinHoure = finhour - debutHour;
                                  const sommeMidiHoure =
                                    finmidihour - debutmidiHour;
                                  const sommeTotalHour =
                                    sommematinHoure + sommeMidiHoure;
                                  let TotalHour = "0.0";
                                  if (finmidi === "00:00") {
                                    TotalHour = decimalHoursToString(0.0);
                                  } else if (fin === "00:00") {
                                    TotalHour =
                                      decimalHoursToString(sommematinHoure);
                                  } else {
                                    TotalHour =
                                      decimalHoursToString(sommeTotalHour);
                                  }
                                  return (
                                    <tr>
                                      <td>{lastRapport}</td>
                                      <td>{suivi.matricule}</td>
                                      <td>{suivi.username}</td>
                                      <td>{present.etat}</td>
                                      <td>{present.debut}</td>
                                      <td>{present.fin_midi}</td>
                                      <td>{present.debut_midi}</td>
                                      <td>{present.fin}</td>
                                      <td>{TotalHour}</td>
                                      {present.etat === "Malade" ||
                                      present.etat === "Congé" ||
                                      present.etat === "Absent" ||
                                      present.etat === "Absent non justifier" ? (
                                        <td>{present.etat}</td>
                                      ) : (
                                        <td>
                                          {present.fin_midi === "00:00" ? (
                                            <td>
                                              {" "}
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  updatePresence(
                                                    present.id,
                                                    "fin_midi"
                                                  )
                                                }
                                              >
                                                Marquez la sortie midi
                                              </button>
                                            </td>
                                          ) : present.debut_midi === "00:00" ? (
                                            <td>
                                              {" "}
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  updatePresence(
                                                    present.id,
                                                    "debut_midi"
                                                  )
                                                }
                                              >
                                                Marquez l`entrée midi
                                              </button>
                                            </td>
                                          ) : present.fin === "00:00" ? (
                                            <td>
                                              {" "}
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  updatePresence(
                                                    present.id,
                                                    "fin"
                                                  )
                                                }
                                              >
                                                Marquez la sortie
                                              </button>
                                            </td>
                                          ) : null}
                                        </td>
                                      )}
                                    </tr>
                                  );
                                }))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sep"></div>
                <h3 style={{textAlign: 'center'}}>
                  <b style={{ color: "white" }}>Mes présences</b>
                </h3>
                  <div className="row">
                            <div className="col-12">
                              <div className="form-group">
                                <label htmlFor="poste" style={{ color: "white" }}>Reherche par :</label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label style={{ color: "white" }}>
                                  <input
                                    type="radio"
                                    value="mois"
                                    checked={etatsearch === "mois"}
                                    onChange={onChangeEtatSearch}
                                  />
                                  Mois
                                </label>
                              </div>
                            </div>
                            <div className="col-2">
                              <div className="form-group">
                                <label style={{ color: "white" }}>
                                  <input
                                    type="radio"
                                    value="deux"
                                    checked={etatsearch === "deux"}
                                    onChange={onChangeEtatSearch}
                                  />
                                  Deux dates
                                </label>
                              </div>
                            </div>
                           
                </div>
                {!showetatsearch ?
                <div className="col-12">
                  <div className="form-group ">
                    <label htmlFor="mois">
                      <b style={{ color: "white" }}> Mois : </b>
                    </label>
                    <select
                      required
                      className="col-4"
                      style={{ width: "15%" }}
                      value={mois}
                      onChange={onChangeMois}
                      placeholder="Choisir Votre Présence"
                    >
                      <option value=""></option>
                      {rowmois.map((month: any) => (
                        <option value={month.name}>{month.name}</option>
                      ))}
                    </select>
                  </div>
                </div>
                :
                <div className="row">
                    <div className="col-2">
                      <div className="form-group custom-range">
                        <label htmlFor="date_start" style={{ color: "white" }}>
                           Du
                        </label>
                        <DatePicker
                          selected={new Date(date_start.setHours(0,0,0,0))}
                          className="form-control"
                          onChange={(date) => onChangeDateStart(date)}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="col-3">
                      <div className="form-group">
                        <label htmlFor="date_end" style={{ color: "white" }}>
                          Au :
                        </label>
                        <DatePicker
                          selected={new Date(date_end.setHours(23,59,0,0))}
                          className="form-control"
                          onChange={(date) => onChangeDateEnd(date)}
                          dateFormat="dd/MM/yyyy"
                        />
                      </div>
                    </div>
                    <div className="col-2">
                      <div className="form-group custom-range">
                      <label htmlFor="date_end" style={{ color: "white" }}>
                      <strong>
                                <i className="fas fa-search"></i>
                              </strong>
                        </label>
                            <Button
                              onClick={() =>
                                handleSearchTwoDate(
                                  date_start,
                                  date_end
                                )
                              }
                            >
                              <strong>
                                Recherche
                              </strong>
                            </Button>
                            </div>
                            </div>
                  </div>
                }
                <div className="row userList">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">Date</th>
                          <th scope="col">Matricule</th>
                          <th scope="col">Nom</th>
                          <th scope="col">Etat</th>
                          <th scope="col">Entrée</th>
                          <th scope="col">Sortie Midi</th>
                          <th scope="col">Entrée Midi</th>
                          <th scope="col">Sortie</th>
                          <th scope="col">Total heure</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mypresences.map((suivi: any) => suivi.presences.map((present: any) => {
                            const cts = present.createdAt;
                            const lastRapport = Moment(cts).format("LL");
                            const {debut} = present;
                            const finmidi = present.fin_midi;
                            const debutHour = hoursStringToDecimal(debut);
                            const finhour = hoursStringToDecimal(finmidi);
                            const debutmidi = present.debut_midi;
                            const {fin} = present;
                            const debutmidiHour =
                              hoursStringToDecimal(debutmidi);
                            const finmidihour = hoursStringToDecimal(fin);
                            const sommematinHoure = finhour - debutHour;
                            const sommeMidiHoure = finmidihour - debutmidiHour;
                            const sommeTotalHour = sommematinHoure + sommeMidiHoure;
                            let TotalHour = "0.0";
                            if (finmidi === "00:00") {
                              TotalHour = decimalHoursToString(0.0);
                            } else if (fin === "00:00") {
                              TotalHour =
                                decimalHoursToString(sommematinHoure);
                            } else {
                              TotalHour =
                                decimalHoursToString(sommeTotalHour);
                            }
                            return (
                              <tr>
                                <td>{lastRapport}</td>
                                <td>{suivi.matricule}</td>
                                <td>{suivi.username}</td>
                                <td>{present.etat}</td>
                                <td>{present.debut}</td>
                                <td>{present.fin_midi}</td>
                                <td>{present.debut_midi}</td>
                                <td>{present.fin}</td>
                                <td>{TotalHour}</td>
                              </tr>
                            );
                          }))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      )
}

export default DoPresence
import React from 'react'

const Footer = () => (
        <div>
        <footer className="main-footer">
          <strong>Copyright © 2021 <a href="https://olona-outsourcing.com/"> Olona </a>. </strong>
           All rights reserved.
          <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.0.0
          </div>
        </footer>
      </div>
    )

export default Footer
import http from '../APIs/olonaTeams'

export default class FileAPI {
    // file upload

  public uploadFile = (data: any) => http.post(`/file/upload`, data)

  public uploadFileSpontanne = (data: any) => http.post(`/file/uploadSpontanneeFile`, data)

  public uploadFileConfidential = (data: any, token: any) => http.post(`/file/uploadConfidential`, data, token)
}
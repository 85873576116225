import React from 'react'

interface IContact {
    name: string
    lastname: string
    status: string
    phone: string
    mail: string
}

interface IProps {
    entries: Array<IContact>
}

const ContactList = ({ entries }: IProps) => (
    <div >
    {/* small box */}
        <table className="table">
            <thead className="thead-dark">
                <tr>
                    <th scope="col">Nom</th>
                    <th scope="col">Prénom</th>
                    <th scope="col">Statut</th>
                    <th scope="col">Phone</th>
                    <th scope="col">email</th>
                </tr>
            </thead>
            <tbody>
                {entries.map((contact) => (
                    <tr>
                        <td><strong>{contact.name}</strong></td>
                        <td>{contact.lastname}</td>
                        <td>{contact.status}</td>
                        <td>{contact.phone}</td>
                        <td>{contact.mail}</td>
                    </tr>

                ))}
            </tbody>
        </table>
    </div>
)

export default ContactList
import React, { useState, useEffect } from 'react'
import Moment from 'moment'
import { Button, Modal } from 'react-bootstrap';
import API from '../services/API/API'
import PageHead from "../components/pageHead";
import BoxSearchPresence from '../components/boxSearchPresence';
import 'moment/locale/fr';

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')

const PresenceCollaborateurClient = () => {
    let sumHour = 0.0
    let nbrPresent  = 0
    let nbrMaladie = 0
    let nbrConge = 0
    const [suivis, setSuivis] = useState<Array<any>>([])
    const [collaborateurs, setCollaborateurs] = useState<Array<any>>([])
    const [id_user, ] = useState(sessionStorage.getItem('client_id') ?? '')
    const [show, setShow] = useState(false)
    const [shownbr, setShownbr] = useState(false)

    const retrievePresenceCollaborators = async () => {
        try {
            const response = await API.Prestance.getPresenceAllCollaboratorClient(id_user, token)
            console.log(response)
            setSuivis(response.data)
            setShownbr(false)
        }
        catch (error) {
            console.log(error)
        }
    }
      
    const retrieveUserCollaborators = async () => {
        try {
            const response = await API.Collaborator.getUserCollaborator(id_user, token)
            setCollaborateurs(response.data)
            setShownbr(false)
            if(response.data[0].collaborators.length === 1) {
                try {
                    const result = await API.Prestance.searchMultiplePresenceColaborateur(id_user, response.data[0].collaborators[0].username, '', token)
                    console.log(result)
                    setSuivis(result.data)
                }
                catch (error) {
                    console.log(error)
                }
            }
            else {
                await retrievePresenceCollaborators()
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const onSearchTwoDate = async (nom: string, date_start: string, date_end: string) => {
        if(nom === "" && date_start === "" && date_end === "")
            retrievePresenceCollaborators();
        else if(nom !== "" && date_start !== "" && date_end !== ""){
            try {
                const response = await API.Prestance.searchMultipleTwoDateClientColaborateur(id_user, nom, date_start, date_end, token)
                setSuivis(response.data)
                setShow(false)
                setShownbr(true)
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const response = await API.Prestance.searchMultipleTwoDateClientColaborateur(id_user, nom, date_start, date_end, token)
                setSuivis(response.data)
                setShow(false)
                setShownbr(false)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    
    const onSearchSuivi = async (nom: string, mois: string) => {
        if(nom === "" && mois === "" )
            await retrievePresenceCollaborators();
        else if(nom !== "" && mois !== "" ){
            try {
                const response = await API.Prestance.searchMultiplePresenceColaborateur(id_user, nom, mois, token)
                setSuivis(response.data)
                setShow(false)
                setShownbr(true)
            }
            catch (error) {
                console.log(error)
            }
        }
        else {
            try {
                const response = await API.Prestance.searchMultiplePresenceColaborateur(id_user, nom, mois, token)
                setSuivis(response.data)
                setShow(false)
                setShownbr(false)
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const handleClose = () => {
        setShow(false)
    }
    
    const handleShow = () => {
        setShow(true)
    }
      
    
    const hoursStringToDecimal = (hoursString: string) => {
        const [hoursPart, minutesPart] = hoursString.split(":");
        return Number(hoursPart) + Number(minutesPart) / 60;
    }

    const decimalHoursToString = (hoursDecimal: number) => {
        const numHours = Math.floor(hoursDecimal);
        const numMinutes = Math.round((hoursDecimal - numHours) * 60);
        return `${numHours < 10 ? "0" : ""}${numHours}:${numMinutes < 10 ? "0" : ""}${numMinutes}`;
    }
     

    useEffect(() => {
        (async () => {
            await retrieveUserCollaborators()
        })()
        Moment.locale('fr');
    }, [])
    
    return (
        <div className="content-wrapper bg-white" style={{backgroundImage: `url(${background})`,
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat"}} >
            <PageHead title="Présence Collaborateur" subject="Liste des présences" name="Présence" />
            <div className="searchbar-container">
                <div className="form-inline">
                    <div className="button-action">
                        {/* <Link to="/Registration"> */}
                        {/* <Button className="universal-length mbtn" block type="submit">
                            <i class="fas fa-plus-circle"></i> <strong>Ajouter un candidat</strong>
                        </Button> */}
                        <Button onClick={handleShow} className="universal-length mbtn" >
                        <i className="fas fa-search"></i> <strong>Filtres</strong>
                        </Button>
                        {/* </Link> */}
                    </div>
                </div>
            </div>
            <div className="sep"></div>
            <div className="sep"></div>
            <div className="row userList">
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Nom</th>
                      <th scope="col">Etat</th>
                      <th scope="col">Entrée</th>
                      <th scope="col">Sortie</th>
                      <th scope="col">Entrée</th>
                      <th scope="col">Sortie</th>
                      <th scope="col">Total heure</th>
                    </tr>
                  </thead>
                  <tbody>
                  {suivis.map((present) => { 
                          const cts = present.createdAt;
                          const lastRapport = Moment(cts).format('LL')
                          // present 
                          if(present.etat === "Présent"){
                            nbrPresent += 1
                          }
                          // maladie
                          if(present.etat === "Malade"){
                            nbrMaladie += 1
                          }
                          // maladie
                          if(present.etat === "Congé"){
                            nbrConge += 1
                          }
                          const {debut} = present
                          const finmidi = present.fin_midi
                          const debutHour = hoursStringToDecimal(debut)
                          const finhour = hoursStringToDecimal(finmidi)
                          const debutmidi = present.debut_midi
                          const {fin} = present
                          const debutmidiHour = hoursStringToDecimal(debutmidi)
                          const finmidihour = hoursStringToDecimal(fin)
                          const sommematinHoure = finhour - debutHour
                          const sommeMidiHoure = finmidihour - debutmidiHour
                          let sommeTotalHour = 0.0
                          let TotalHour = '0.0'
                          if(finmidi === "00:00"){
                            TotalHour = decimalHoursToString(0.0)
                            sommeTotalHour = 0.0
                          }
                          else if(fin === "00:00"){
                            sommeTotalHour = sommematinHoure
                            TotalHour = decimalHoursToString(sommematinHoure)
                          }
                          else{
                            sommeTotalHour = sommematinHoure + sommeMidiHoure
                            TotalHour = decimalHoursToString(sommeTotalHour)
                          }
                          sumHour += sommeTotalHour;
                        return (
                          <tr>
                            <td>{lastRapport}</td>
                            <td>{present.user.username}</td>
                            <td>{present.etat}</td>
                            <td>{present.debut}</td>
                            <td>{present.fin_midi}</td>
                            <td>{present.debut_midi}</td>
                            <td>{present.fin}</td>
                            <td>{TotalHour}</td>
                          </tr>
                        );
                      })}
                      {shownbr ?
                      <tr>
                      <tr>
                        <th>Total heures du travail</th>
                        <td>{decimalHoursToString(sumHour)}</td>
                      </tr>
                      <tr>
                        <th>Nombre de jours de présences</th>
                        <td>{nbrPresent}</td>
                      </tr>
                      <tr>
                        <th>Nombre de jours de Maladie</th>
                        <td>{nbrMaladie}</td>
                      </tr>
                      <tr>
                        <th>Nombre de jours de congé</th>
                        <td>{nbrConge}</td>
                      </tr>
                      </tr>
                      : null
                      }
                  </tbody>
                </table>
              </div>
            </div>
              <Modal className="my-modal" animation={false} show={show} onHide={handleClose}>
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}

            <button type="button" onClick={handleClose} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <BoxSearchPresence actionSearch ={onSearchSuivi} suivi="client" collaborator={collaborateurs} actionSearchTwoDate={onSearchTwoDate}/>
                </div>
              </div>
            </Modal.Body>
            </Modal>
            </div>
    )
}

export default PresenceCollaborateurClient
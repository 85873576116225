import React, { useEffect, useState } from "react";
import API from '../services/API/API'
import PageHead from "../components/pageHead";
import "emojionearea/dist/emojionearea.min.css"

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

const CreatePostSocialeModule = () => {
    const [, setClients] = useState<Array<any>>([])
    const [id_user, ] = useState(sessionStorage.getItem('id') ?? '')

    const retrieveClients = async () => {
        /* service
          .getClientCollaborator(id_user, token)
          .then((response) => {
            setState({
              clients: response.data,
            });
            console.log(response.data);
          })
          .catch((e) => {
            console.log(e);
          }); */
        try {
            const response = await API.Collaborator.getClientCollaborator(id_user, token)
            setClients(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveClients()
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{
              backgroundImage: `url(${background})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
            }}
          >
            <PageHead
              title="Gestion des réseaux sociaux"
              subject="Tabelau de bord"
              name="GRS"
            ></PageHead>
            <section className="content">
              <div id="resocioModal" tabIndex={-1} role="dialog" aria-labelledby="resocioModalLabel" aria-hidden="true">
                <div role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="resocioModalLabel">Re-Socio</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <i aria-hidden="true" className="ki ki-close"></i>
                      </button>
                    </div>
                    <form action="{{ route('publish_content.share') }}" id="publishContentForm" method="POST">
                      <div className="modal-body">
                        <div className="form-group">
                          <textarea className="form-control border border-light h-auto py-4 rounded-lg font-size-h6" id="normal_post_area" name="content" rows={3} placeholder="Écrire quelque chose !" ></textarea>
  
                          <span id="error-content" className="error-message form-text text-danger"></span>
                        </div>
                        <div className="form-group">
                          <div className="input-icon">
                            <input className="form-control form-control-solid h-auto py-4 rounded-lg font-size-h6" id="outgoing_url" type="text" name="outgoingUrl" autoComplete="off" placeholder="Saisissez l'URL de sortie"
                               />
                            <span><i className="fas fa-link"></i></span>
                          </div>
                          <span id="error-outgoingUrl" className="error-message form-text text-danger"></span>
                        </div>
  
                        <div className="row">
                          <div className="col-12" id="">
                            <ul id="media-list" className="clearfix">
                              <li>
  
                                <img src="" style={{ objectFit: "contain" }} alt="test" />
  
                              </li>
                              <li className="myupload">
                                <span>
                                  <i className="fa fa-plus" aria-hidden="true"></i>
                                  <input type="file" click-type="type2" id="picupload" className="picupload" name="file[]" multiple accept="video/*,.jpg, .png, .jpeg" style={{ width: "100px" }} title="Cliquez pour ajouter un fichier" />
  
                                  <input type="hidden" name="mediaUrl" id="media_url"  />
  
                                </span>
                              </li>
                            </ul>
                            <span id="error-file" className="error-message form-text text-danger"></span>
                          </div>
                        </div>
  
                        <ul className="nav justify-content-center nav-pills" id="AddAccountsTab" role="tablist">
  
                          <li className="nav-item">
                            <a className="nav-link" id="{{$key}}-tab-accounts" data-toggle="tab" href="#{{$key}}-add-accounts">
                              <span className="nav-text"><i className="fab fa-{{$key}} fa-2x"></i></span>
                            </a>
                          </li>
                        </ul>
                        <span id="error-socialAccount" className="error-message form-text text-danger text-center"></span>
  
                      </div>
  
                      <div className="modal-footer">
                        <button type="button" name="status" value="0" className="publishContentSubmit btn font-weight-bolder font-size-h6 px-4 py-4 mr-3 my-3 ">Brouillon</button>
                        <button type="button" name="status" value="1" className="publishContentSubmit btn font-weight-bolder font-size-h6 px-4 py-4 mr-3 my-3 ">Publier</button>
                      </div>
                    </form>
                  </div>
                </div>
  
              </div>
            </section>
          </div>
        </div>
      )
}

export default CreatePostSocialeModule
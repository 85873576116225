import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
// eslint-disable-next-line import/no-extraneous-dependencies
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

interface IProps {
  pdf: any
}

interface IDoc {
  numPages: number
}

const SinglePage = ({ pdf }: IProps) => {
  const [numPage, setNumPage] = useState(0);
  const [pageNumber, setPageNumber] = useState(1); 

  const onDocumentLoadSuccess = ({ numPages }: IDoc) => {
    setNumPage(numPages);
    setPageNumber(1);
  }

  const changePage = (offset: number) => {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  const previousPage = () => {
    changePage(-1);
  }

  const nextPage = () => {
    changePage(1);
  }

  return (
    <div>
      <Document
        file={pdf}
        // options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div>
        <p>
          Page {pageNumber || (numPage ? 1 : "--")} sur {numPage || "--"}
        </p>
        <button type="button" disabled={pageNumber <= 1} onClick={previousPage}>
          Précédant
        </button>
        <button
          type="button"
          disabled={pageNumber >= numPage}
          onClick={nextPage}
        >
          Suivant
        </button>
      </div>
    </div>
  );
}

export default SinglePage

import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
// eslint-disable-next-line no-irregular-whitespace
import swal from 'sweetalert';
import API from '../services/API/API'
import PageHead from "../components/pageHead";
import Registration from "../components/registration";
import BoxSearch from "../components/boxSearch";

const token = {
    headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");
const limitPage = "25";

interface IProps {
    // eslint-disable-next-line react/require-default-props
    actionFavorite?: any
}

const defaultValue = { 
  firstname: '',
  lastname: '',
  note: '',
  poste: '',
  point_fort: '',
  point_faible: '',
  prix: '',
  sousposteValue: '',
}


const Dashboard = ({ actionFavorite }: IProps) => {
    const [role,] = useState(sessionStorage.getItem('role'))
    const [candidats, setCandidats] = useState<Array<any>>([])
    const [searchTitle, setSearchTitle] = useState('')
    const [show, setShow] = useState(false)
    const [showPrix, setShowPrix] = useState(false)
    const [showSearch, setShowSearch] = useState(false)
    const [activePage, setActivePage] = useState(1)
    const [totalCustomers, setTotalCustomers] = useState(0)
    const [search, setSearch] = useState(false)
    const [prix_admin, setPrix_admin] = useState('')
    const [currentCandidat, setCurrentCandidat] = useState<any>()
    const [candidatUpdate, setCandidatUpdate] = useState<any>({})
    const [isCheck, setIsCheck] = useState('create')
    const [id_user,] = useState(sessionStorage.getItem("id") ?? '')
    const [selected, setSelected] = useState(defaultValue)

    const onChangePrixAdmin = (e: FormEvent<HTMLInputElement>) => {
        setPrix_admin(e.currentTarget.value)
    }

    const retrieveCandidats = async (pageNumber: number) => {
        try {
            const response = await API.Candidate.getAll(pageNumber, limitPage, token)
            setCandidats(response.data.results)
            setTotalCustomers(response.data.totalCustomers)
            setShowSearch(false)
        }
        catch (error) {
            console.log(error)
        }
    }

    const deleteCandidat = async (id: string) => {
        try {
            await API.Candidate.delete(id, token)
            swal("Pouf ! Votre fichier a été supprimé !", {
                icon: "success",
            });
            await retrieveCandidats(activePage)
        }
        catch (error) {
            console.log(error)
        }
    }

    const confirmDetlete = async (id: string) => {
        try {
            const willDelete = await swal({
                title: "Etes-vous sûr?",
                text: "Une fois supprimé, vous ne pourrez plus récupérer ce fichier!",
                icon: "warning",
                buttons: ["Non", "Oui"],
                dangerMode: true,
            })

            if (willDelete) await deleteCandidat(id)
            else swal('Votre fichier est en sécurité !')
        }
        catch (error) {
            console.log(error)
        }
    }

    const updatePrix = async (id: string, prix_adminP: string) => {
        const data = {
            prix_admin: prix_adminP,
        };
        try {
            await API.Candidate.updatePrixCustomer(id, data, token)
            // setId(response.data.id)
            setShowPrix(false)
            setPrix_admin('')

            await retrieveCandidats(activePage)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleClosePrix = () => {
        setShowPrix(false)
    }

    const handleShowPrix = ( candidat: any) => {
        setShowPrix(true)
        setCurrentCandidat(candidat)
    }

    const handleClose = async () => {
        setShow(false)
        await retrieveCandidats(activePage);
    }

    
    // eslint-disable-next-line no-unused-vars
    const handleFavorites = (user_id: string, candidat_id: string) => {
        actionFavorite(user_id, candidat_id);
    }

    const handleShow = () => {
        setShow(true)
        setIsCheck('create')
    }

    const handleUpdate = (candidat: any) => {
        setShow(true)
        setCandidatUpdate(candidat)
        setIsCheck('update')
    }

    const handleCloseSearch = () => {
        setShowSearch(false)
    }

    const handleShowSearch = () => {
        setShowSearch(true)
    }

    const addFavorites = async (userId: string, candidatId: string) => {
        const data = {
          id_user: userId,
          id_candidat: candidatId,
        };
        try {
            await API.Favorite.addFavorite(data, token)
            swal("Candidat ajouté aux favoris", {
              icon: "success",
            })
        }
        catch (error) {
          console.log(error)
        }
    }

    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
      setSearchTitle(e.currentTarget.value)
      if(e.currentTarget.value === '')
          await retrieveCandidats(activePage);
      else{
        try {
          const response = await API.Candidate.findByNom(e.currentTarget.value, activePage, limitPage, token)
          setCandidats(response.data.results)
          setTotalCustomers(response.data.totalCustomers)
        }
        catch (error) {
          console.log(error)
        }
      }
    }

    const onSearchCandidat = async (nom: string, prenom: string, note: string, prix: string, point_faible: string, point_fort: string, poste: string, sousposte: string) => {
      setSelected({
        firstname: nom,
        lastname: prenom,
        note,
        poste,
        point_fort,
        point_faible,
        prix,
        sousposteValue: sousposte,
      })
      if (
        nom === "" &&
        prenom === "" &&
        note === "" &&
        prix === "" &&
        point_faible === "" &&
        point_fort === "" &&
        poste === ""
      ) 
        await retrieveCandidats(activePage);
      else {
        try {
          const response = await API.Candidate.searchCustomer(
            nom,
            prenom,
            note,
            prix,
            point_faible,
            point_fort,
            poste,
            sousposte,
            activePage,
            limitPage,
            token
          )
          setCandidats(response.data.results)
          setTotalCustomers(response.data.totalCustomers)
          setShowSearch(false)
          setSearch(true)
        }
        catch (error) {
          console.log(error)
        }
      }
    }

    const handlePageChange = async (pageNumber: number) => {
      setActivePage(pageNumber)
      if (!search)
        await retrieveCandidats(pageNumber);
    }

    useEffect(() => {
        (async () => {
            await retrieveCandidats(activePage)
        })()
    }, [])

    return (
      <div>
        <div
          className="content-wrapper"
          style={{backgroundImage: `url(${background})`,
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat"}}
        >
          {/* TITLE & BREADCRUMBS */}
          {role !== "user" && role !== "collaborator" ? (
           <PageHead title="Les talents" subject="Liste des talents" name="Talents"/>

          ) : null}

          {/* SEARCH FORM */}
          {role !== "user" && role !== "collaborator" ? (
            <div className="action-container">
                <div className="form-inline">
                  <div className="input-group input-group-sm" title="Vous pouvez faire un recherche par nom, premon, note, disponibilté ou status">
                    <span className="universal-length icon-r">
                      <i className="fas fa-search"></i>
                    </span>{" "}
                    <input
                      className="form-control form-control-navbar universal-length recherche col-10"
                      value={searchTitle}
                      onChange={onChangeSearchTitle}
                      type="search"
                      placeholder="Rechercher"
                      aria-label="Search"
                    />
                  </div>
                  <div title="Vous pouvez faire des recherches multiple par nom, premon, note, poste, prétention salariale, point faible et point fort">
                    <Button
                      onClick={handleShowSearch}
                      className="universal-length mbtn"
                    >
                      <i className="fas fa-search"></i>Filtres
                    </Button>
                  </div>
                  <div className="button-action" title="Vous pouvez faire un ajout des candidats">
                {/* <Link to="/Registration"> */}
                {/* <Button className="universal-length mbtn" block type="submit">
                    <i class="fas fa-plus-circle"></i> <strong>Ajouter un candidat</strong>
                  </Button> */}
                <Button
                  onClick={handleShow}
                  className="universal-length mbtn"
                  type="submit"
                >
                  <i className="fas fa-plus-circle"></i>{" "}
                  <strong>Ajouter un candidat</strong>
                </Button>
                {/* </Link> */}
              </div>
                </div>
              </div>
          ) : null}

          {/* END SEARCH FORM */}
          <section className="content">
            <div>
              {role !== "user" && role !== "collaborator" ? (
                <div className="table-responsive">
                  <table className="table table-curved">
                    <thead >
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nom & Prénom(s)</th>
                        <th scope="col">Secteur</th>
                        <th scope="col">Poste</th>
                        <th scope="col">Etat</th>
                        <th scope="col" colSpan={2}>
                          Note(s)
                        </th>
                        <th scope="col">Prétention/Forfait</th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {candidats.map((candidat: any) => (
                        <tr>
                          <td >
                            <Link to={`/Detail/${candidat.id}`}>
                              Plus d`infos<i className="fas fa-info-circle"></i>
                            </Link>
                          </td>
                          <td width="150px">
                            <strong>
                              {candidat.lastname} {candidat.firstname}
                            </strong>
                          </td>
                          <td>
                              <p className="poste">
                                <strong>{candidat.postes.name}</strong>
                              </p>
                          </td>
                          <td>
                              <p className="poste">
                                <strong>{candidat.poste_precis}</strong>
                              </p>
                          </td>
                          <td>
                            {candidat.status != null ? (
                              <span className="dispo">
                                <strong>{candidat.status}</strong>
                              </span>
                            ) : (
                              <span className="dispo">
                                <strong>aucune info</strong>
                              </span>
                            )}
                          </td>
                          <td>
                            <h5>
                              Note{" "}
                              <span className="text-black">
                                ( {candidat.note} / 10 )
                              </span>
                            </h5>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                style={{ width: `${candidat.note * 10}%` }}
                                role="progressbar"
                                aria-valuenow={candidat.note}
                                aria-valuemin={0}
                                aria-valuemax={10}
                              ></div>
                            </div>
                          </td>
                          <td>

                              {candidat.evaluations.map((evaluation: any) =>
                                evaluation.name === "Langue Francais parlé " ? (
                                  <div >
                                    <h5>
                                      Niveau de français 
                                      <span className="text-black">
                                        {" "}
                                        ( {evaluation.note} / 10 )
                                      </span>
                                    </h5>
                                    <div className="progress">
                                      <div
                                        className="progress-bar"
                                        style={{
                                          width: `${evaluation.note * 10}%`,
                                        }}
                                        role="progressbar"
                                        aria-valuenow={evaluation.note}
                                        aria-valuemin={0}
                                        aria-valuemax={10}
                                      ></div>
                                    </div>
                                  </div>
                                ) : null
                              )}
                          </td>
                          <td>
                              <div >
                                <div >
                                  <h5>Prétention salariale</h5>
                                  <p>
                                    {" "}
                                    Ar {candidat.prix.toLocaleString()} / Mois
                                  </p>
                                </div>
                                {role === "admin" ? (
                                  <div >
                                    <h5 className="text-rigth igrey">
                                      Forfait Olona
                                    </h5>
                                    <p>
                                      {" "}
                                      € <span> {candidat.prix_admin.toLocaleString()}</span> /
                                      Mois
                                    </p>
                                    <button
                                      type="button"
                                      onClick={() => handleShowPrix(candidat)}
                                      className="btn btn-success"
                                    >
                                      <i className="fas fa-edit">Modifier</i>
                                    </button>
                                    
                                  </div>
                                ) : null}
                              </div>
                          </td>
                          <td>
                            <div className="row r-favori">
                                <div className="col-12" title="Ajouter aux favoris">
                                        <Button
                                          className="universal-length mbtn btn-favori"
                                          onClick={() =>
                                            addFavorites(
                                              id_user,
                                              candidat.id
                                            )
                                          }
                                        >
                                          <strong>
                                            <i className="fas fa-star"></i>{" "}
                                          </strong>
                                        </Button>
                                </div>
                                <div className="col-12" title="modifier le candidat">
                                <Button className="universal-length mbtn" onClick={() => handleUpdate(candidat)}  type="submit">
                                    <strong><i className="fas fa-edit"></i> {" "}</strong>
                                </Button>
                                </div>
                                  <div className="col-12" title="supprimmer le candidat">
                                        <Button
                                          className="universal-length mbtn btn-favori delete"
                                          onClick={() =>
                                            confirmDetlete(
                                              candidat.id
                                            )
                                          }
                                        >
                                          <strong>
                                            <i className="fas fa-user-times"></i>{" "}
                                          </strong>
                                        </Button>
                                </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={candidats.length}
                    totalItemsCount={totalCustomers}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                   <Modal className="my-modal"  id={
                      currentCandidat != null
                        ? currentCandidat.id
                        : "0"
                    } animation={false} show={showPrix} onHide={handleClosePrix}>
                        {/* <Modal.Header closeButton> */}
                        {/* <Modal.Title></Modal.Title> */}
                        {/* </Modal.Header> */}

                        <button type="button" onClick={handleClosePrix} className="close">
                            <i className="fas fa-times"></i>
                            <span className="sr-only">Close</span>
                        </button>
                        <Modal.Body>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="prix_admin">Forfait Olona <span className="custom-text-blue">( € / mois )</span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="prix_admin"
                                                required
                                                value={prix_admin}
                                                onChange={onChangePrixAdmin}
                                                name="prix_admin"
                                                placeholder="€ / mois"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                            <button className="w-100 btn-register btn btn-success" onClick={() => updatePrix(currentCandidat.id, prix_admin)}  type="submit">
                                    <strong><i className="fas fa-edit"></i> Modifier le forfait</strong>
                            </button>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
               
              ) : (
                <div>
                  <h1>Bienvenue sur notre plateforme</h1>
                </div>
              )}
            </div>
          </section>


          <Modal
            className="my-modal"
            animation={false}
            show={show}
            onHide={handleClose}
          >
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}

            <button type="button" onClick={handleClose} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 left-image-register">
                  <div className="text">
                    <p>Olona</p>
                    <p>digital . outsourcing . data</p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <Registration mode={isCheck} candidat={candidatUpdate} actionClose={handleClose} />
                </div>
              </div>
            </Modal.Body>
          </Modal>
          <Modal
            className="my-modal"
            animation={false}
            show={showSearch}
            onHide={handleCloseSearch}
          >
            {/* <Modal.Header closeButton> */}
            {/* <Modal.Title></Modal.Title> */}
            {/* </Modal.Header> */}

            <button type="button" onClick={handleCloseSearch} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
            <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <BoxSearch actionSearch={onSearchCandidat} selected={selected} etat = "PUBLISHED"/>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    )
}

export default Dashboard
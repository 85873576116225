import AssignementAPI from "./storeAPI/Assignement";
import CandidateAPI from "./storeAPI/Candidate";
import ClientAPI from "./storeAPI/Client";
import CollaboratorAPI from "./storeAPI/Collaborator";
import CollaboratorEvaluationAPI from "./storeAPI/CollaboratorEvaluation";
import CommentAPI from "./storeAPI/Comment";
import ContactAPI from "./storeAPI/Contact";
import EvaluationAPI from "./storeAPI/Evaluation";
import FavoriteAPI from "./storeAPI/Favorite";
import FileAPI from "./storeAPI/File";
import FormAPI from "./storeAPI/Form";
import PostAPI from "./storeAPI/Post";
import PrestanceAPI from "./storeAPI/Prestance";
import UserAPI from "./storeAPI/User";
import FacebookAPI from "./Facebook/graph";

const API = {
    Assignement: new AssignementAPI(),
    Candidate: new CandidateAPI(),
    Client: new ClientAPI(),
    Collaborator: new CollaboratorAPI(),
    CollaboratorEvaluation: new CollaboratorEvaluationAPI(),
    Comment: new CommentAPI(),
    Contact: new ContactAPI(),
    Evaluation: new EvaluationAPI(),
    Favorite: new FavoriteAPI(),
    File: new FileAPI(),
    Form: new FormAPI(),
    Post: new PostAPI(),
    Prestance: new PrestanceAPI(),
    User: new UserAPI(),
    Facebook: new FacebookAPI()
}

export default API
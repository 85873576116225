import React from "react";
import { Route } from 'react-router-dom';
import TestEvaluation from '../views/TestEvaluation';
import '../views/app/App.css';

const RegisrationSpotaneousContainer = () => (
    <div >
      <Route path="/testevaluation">
        <TestEvaluation/>
      </Route>
    </div>
)

export default RegisrationSpotaneousContainer
/* eslint-disable react/prop-types */
import React from "react";
import { Route, Redirect } from 'react-router-dom';
import Header from '../components/Header';
import Menu from '../components/Menu';
import Footer from '../components/Footer';
import Dashboard from '../views/Dashboard';
import Registration from "../views/Registration";
import Detail from "../views/Details";
import Register from "../views/UserRegister";
import UserList from "../views/UserList";
import Favorite from '../views/Favorites';
import Assignment from '../views/Assignment';
import CandidatSpontannee from '../views/CustomerSpotaneous';
import CandidatList from '../views/ClientCandidatList';
import DetailClient from '../views/DetailClient';
import DetailClientSuivi from '../views/DetailClientSuivi';
import Message from '../views/Message';
import SuiviClientList from '../views/SuiviClientList';
import SuiviClient from '../views/SuiviClient';
import ClientCollaboratorList from '../views/ClientColaboratorList';
import SuiviCollaborator from '../views/SuiviCollaborator';
import CollaboratorList from '../views/CollaboratorList';
import CollaboratorListClient from '../views/CollaboratorListClient';
import SuiviParCollaborator from '../views/SuiviParCollaborator';
import SuiviCollaboratorPerDay from '../views/SuiviCollaboratorParJour';
import DetailSpontanee from '../views/DetailSpontanee';
import FavorisClient from '../views/favorisClient';
import Presence from '../views/PresenceModule';
import PresenceList from '../views/DoPresence';
import PresenceCollaboratorClient from '../views/PresenceCollaborateurClient';
import PresenceCollaboratorPerDay from '../views/PresencCollaboratorParJour';
import Evaluation from '../views/Evaluation';
import Evaluationc from '../views/Evaluationc';
import Collaborateur from '../views/EvaluationCollaborateur';
import EvaluationVue from '../views/EvaluationVue';
import Definition from '../views/EvaluaitonDefinition';
import Item from '../views/EvaluationItem';
import PresenceAdmin from '../views/PresenceModuleAdmin';
import DoPresenceAdmin from '../views/DoPresenceAdmin';
import EvaluationAdmin from '../views/EvaluationAdmin';
import ClientListSocialeModule from '../views/ClientListSocialeModule';
import ClientListSocialeModuleAdmin from '../views/ClientListSocialeModuleAdmin';
import SocialeModuleCM from '../views/SocialeModuleCM';
import CreateModulePost from '../views/createpostSocialeModule';
import '../views/app/App.css';

const faker = {
    firstname: '',
    lastname: '',
    id: ''
}

const DefaultContainer = () => (
    <div>
        <PrivateRoute path="/">
          <Header />
          <Menu />
        </PrivateRoute>
        <PrivateRoute path="/Registration">
          <Registration />
        </PrivateRoute>
        <Route exact path="/Detail/:id" render={(props: any) => <Detail {...props} />}>
        </Route>
  
        <Route exact path="/DetailSpontanee/:id" render={(props: any) => <DetailSpontanee {...props} />}>
        </Route>
        <Route exact path="/DetailClient/:id" render={(props: any) => <DetailClient {...props} />}>
        </Route>
        <Route exact path="/DetailClientSuivi/:id" render={(props: any) => <DetailClientSuivi {...props} />}>
        </Route>
        <Route exact path="/CandidatList/:id" render={(props: any) => <CandidatList {...props} />}>
        </Route>
        <Route exact path="/SuiviClient/:id" render={(props: any) => <SuiviClient {...props} />}>
        </Route>
        <Route exact path="/SuiviCollaborator/:id" render={(props: any) => <SuiviCollaborator {...props} />}>
        </Route>
        <Route exact path="/DoPresence/:id" render={(props: any) => <PresenceList {...props} />}>
        </Route>
        <Route exact path="/SuiviParCollaborator/:id/:client_id" render={(props: any) => <SuiviParCollaborator {...props} />}>
        </Route>
        <Route exact path="/CollaboratorList/:id" render={(props: any) => <CollaboratorList {...props} />}>
        </Route>
        <Route exact path="/PresenceAdmin/:id" render={(props: any) => <PresenceAdmin {...props} />}>
        </Route>
        <Route exact path="/DoPresenceAdmin/:id/:id_user" render={(props: any) => <DoPresenceAdmin {...props} />}>
        </Route>
  
        <PrivateRoute path="/CollaboratorListClient">
          <CollaboratorListClient />
        </PrivateRoute>
        <PrivateRoute path="/Presence">
          <Presence />
        </PrivateRoute>
        <PrivateRoute path="/PresenceCollaborator">
          <PresenceCollaboratorClient />
        </PrivateRoute>
        <PrivateRoute path="/PresenceCollaboratorParJour">
          <PresenceCollaboratorPerDay />
        </PrivateRoute>
        
        {/* menu module evaluaiton */}
        <PrivateRoute path="/Collaborateurs"> <Collaborateur /> </PrivateRoute>
        <PrivateRoute path="/Collaborateur"> <Evaluation /> </PrivateRoute>
        <PrivateRoute path="/Evaluationsc"> <Evaluationc /> </PrivateRoute>
        <PrivateRoute path="/EvaluationsAdmin"> <EvaluationAdmin /> </PrivateRoute>
        <PrivateRoute path="/Evaluation"> <EvaluationVue /> </PrivateRoute>
        <PrivateRoute path="/Definition"> <Definition /> </PrivateRoute>
        <PrivateRoute path="/Item"> <Item /> </PrivateRoute>
  
        <PrivateRoute path="/SuiviCollaboratorParJour">
          <SuiviCollaboratorPerDay />
        </PrivateRoute>
        <PrivateRoute path="/Register">
          <Register />
        </PrivateRoute>
        <PrivateRoute path="/SuiviClientList">
          <SuiviClientList />
        </PrivateRoute>
        <PrivateRoute path="/ClientList">
          <ClientCollaboratorList />
        </PrivateRoute>
  
        <Route exact path="/grscm/:id" render={(props: any) => <SocialeModuleCM {...props} />}>
        </Route>
        <PrivateRoute path="/socialmodule">
          <ClientListSocialeModule />
        </PrivateRoute>
        <PrivateRoute path="/socialmoduleadmin">
          <ClientListSocialeModuleAdmin />
        </PrivateRoute>
        <PrivateRoute path="/publish">
          <CreateModulePost />
        </PrivateRoute>
        
        <PrivateRoute path="/Contact">
          <Message user="" candidat={faker} actionStatus={undefined} actionClose={undefined} />
        </PrivateRoute>
        <PrivateRoute path="/UserList">
          <UserList />
        </PrivateRoute>
        <PrivateRoute path="/Favorites">
          <Favorite />
        </PrivateRoute>
        <PrivateRoute path="/FavoritesClient">
          <FavorisClient />
        </PrivateRoute>
        <PrivateRoute path="/Assignment">
          <Assignment />
        </PrivateRoute>
        <PrivateRoute path="/CandidatSpontannee">
          <CandidatSpontannee />
        </PrivateRoute>
        <PrivateRoute path="/Dashboard">
          <Dashboard />
        </PrivateRoute>
      <Footer />
    
    </div>
  )
const PrivateRoute = ({ children, ...rest }: any) => (
      <Route
        {...rest}
        render={({ location }: any) =>
          sessionStorage.getItem('id') ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/Login",
                state: { from: location }
              }}
            />
          )
        }
      />
    )

export default DefaultContainer
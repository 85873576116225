import React, { useState, useEffect, FormEvent } from "react";
import {Button} from 'react-bootstrap';
import API from '../services/API/API';

// const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

/* eslint-disable no-unused-vars */
type actionSearchFunction = (nom: string, prenom: string, note: string, prix: string, point_faible: string, point_fort: string, poste: string, sousposte: string) => void
/* eslint-enable no-unused-vars */

interface ICandidat {
    firstname: string,
    lastname: string,
    note: string,
    poste: string,
    point_fort: string,
    point_faible: string,
    prix: string,
    sousposteValue: string,
}

interface IProps {
    etat: string
    selected: ICandidat
    actionSearch: actionSearchFunction
}

const BoxSearch = ({ etat, selected, actionSearch }: IProps) => {
    const [, setId] = useState(null)
    const [, setUsername] = useState<string | null>(null)
    const [firstname, setFirstname] = useState(selected.firstname)
    const [lastname, setLastname] = useState(selected.lastname)
    const [note, setNote] = useState(selected.note)
    const [poste, setPoste] = useState(selected.poste)
    const [point_fort, setPoint_fort] = useState(selected.point_fort) 
    const [point_faible, setPoint_faible] = useState(selected.point_faible)
    const [, setDisponibilite] = useState('')
    const [prix, setPrix] = useState(selected.prix)
    const [, setSubmitted] = useState(false)
    const [sousposteValue, setSousposteValue] = useState(selected.sousposteValue)
    const [postes, setPostes] = useState([])
    const [souspostes, setSouspostes] = useState([])
    // const [posteSelected, setPosteSelected] = useState(null)

    const handleSearch = () => {
        actionSearch(firstname, lastname, note, prix, point_faible, point_fort, poste, sousposteValue);
    }

    const handleReset = () => {
        setId(null)
        setFirstname('')
        setLastname('')
        setNote('')
        setPoste('')
        setSousposteValue('')
        setPoint_faible('')
        setPoint_fort('')
        setDisponibilite('')
        setPrix('')
        setSubmitted(false)
    }

    const onChangeFirstname = (e: FormEvent<HTMLInputElement>) => {
        setFirstname(e.currentTarget.value)
    }

    const onChangeLastname = (e: FormEvent<HTMLInputElement>) => {
        setLastname(e.currentTarget.value)
    }

    const onChangeNote = (e: FormEvent<HTMLInputElement>) => {
        setNote(e.currentTarget.value)
    }

    const onChangePoste = async (e: FormEvent<HTMLSelectElement>) => {
        /* const poste =  e.target.value
        setState({
            poste: e.target.value
        });
        if(poste !== ""){
        service.getSousPoste(poste,etat)
        .then(response => {
          setState({
            souspostes: response.data
          });
        })
        .catch(e => {
          console.log(e);
        });
        } */
        setPoste(e.currentTarget.value)
        if (poste !== '') {
            try {
                const response = await API.Post.getSousPoste(poste, etat)
                setSouspostes(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }

    const onChangeSousPoste = (e: FormEvent<HTMLSelectElement>) => {
        setSousposteValue(e.currentTarget.value)
    }
    
    const onChangePrix = (e: FormEvent<HTMLInputElement>) => {
        setPrix(e.currentTarget.value)
    }

    const onChangePointfaible = (e: FormEvent<HTMLInputElement>) => {
        setPoint_faible(e.currentTarget.value)
    }

    const onChangePointFort = (e: FormEvent<HTMLInputElement>) => {
        setPoint_fort(e.currentTarget.value)
    }

    /* const onChangeDisponibilite = (e: FormEvent<HTMLInputElement>) => {
        setDisponibilite(e.currentTarget.value)
    } */

    const retrievePostes = async () => {
        /* service.getPostes()
          .then(response => {
            setState({
              postes: response.data
            });
            console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Post.getPostes()
            setPostes(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        setUsername(sessionStorage.getItem('username'))
        ;(async () => {
            await retrievePostes()
        })()
    }, [])

    return (
        <div>
            {/* Main content */}
            <section className="content">
            <div>
                        <div className="row box-register">
                            <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="title-container">
                                    <div className="title">
                                        <h2>Chercher <br />un candidat avec : <br /></h2>
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="lastname">Prénom(s)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastname"
                                                required
                                                value={lastname}
                                                onChange={onChangeLastname}
                                                name="lastname"
                                                placeholder="ex : John"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="firstname">Nom</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstname"
                                                required
                                                value={firstname}
                                                onChange={onChangeFirstname}
                                                name="firstname"
                                                placeholder="ex : Smith"
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="poste">Secteur d`activité</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select  required style={{ width: '100%'}} value={poste} onChange={onChangePoste} placeholder="Choisir le secteur d'activité">
                                                <option value="" ></option>
                                                {postes.map((item: any) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="poste">Poste</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select  style={{ width: '100%'}} value={sousposteValue} onChange={onChangeSousPoste} placeholder="Choisir le Poste">
                                                <option value="" ></option>
                                                {souspostes.map((item: any) => (
                                                    <option value={item.name}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="note">Note <span className="custom-text-blue">supérieur ou égale à ( {note} / 10 )</span></label>
                                            <input
                                                type="range" min="0" max="10"
                                                className="form-control"
                                                id="note"
                                                required
                                                value={note}
                                                onChange={onChangeNote}
                                                name="note"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group custom-range">
                                            <label htmlFor="point_fort"><i className="ok fas fa-check-circle"></i> Point fort</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="point_fort"
                                                required
                                                value={point_fort}
                                                onChange={onChangePointFort}
                                                name="point_fort"
                                                placeholder="ex : sérieux"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="point_faible"><i className="oknt fas fa-times-circle"></i> Point faible</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="point_faible"
                                                required
                                                value={point_faible}
                                                onChange={onChangePointfaible}
                                                name="point_faible"
                                                placeholder="ex : retardataire"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="prix">Prétention salariale <span className="custom-text-blue">( Ar / mois ) </span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="prix"
                                                required
                                                value={prix}
                                                onChange={onChangePrix}
                                                name="prix"
                                                placeholder=" <= Ar / mois"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <Button className="universal-length mbtn" onClick={handleSearch}>
                                            <strong><i className="fas fa-search"></i> Chercher</strong>
                                        </Button>
                                        <Button className="universal-length mbtn" onClick={handleReset}>
                                            <strong> Réinitialiser</strong>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
            </section>
        </div>
    );
}

export default BoxSearch

import http from '../APIs/olonaTeams'
import httpFb from '../APIs/facebookGraph'

export default class FacebookAPI {
     // facebook
    public getAllPages = (userid: string | number, userToken: string) => httpFb.get(`${userid}/accounts?access_token=${userToken}`)
 
    public getPostesPages = (pageid: string | number, userToken: string) => httpFb.get(`${pageid}/feed?access_token=${userToken}`)
 
    public postContentPages = (pageid: string | number, userToken: string, data: any) => httpFb.post(`${pageid}/feed?access_token=${userToken}`,data)

    public commentPosts = (postid: string | number, message: string, userToken: string) => httpFb.post(`${postid}/comments?message=${message}&access_token=${userToken}`)

    public creatschedule = (data: any) => http.post(`/schedule`, data)

    public commentGet = (postid: string | number,pagetoken: string) => httpFb.get(`${postid}/comments?access_token=${pagetoken}`)
 
    public getLongAccessToken = (app_id: string | number, client_secret: string, access_token: string) => httpFb.get(`oauth/access_token?grant_type=fb_exchange_token&client_id=${app_id}&client_secret=${client_secret}&fb_exchange_token=${access_token}`)

    public getAccessToken = (client_id: string | number, typeRs: string | number) => http.get(`/grs/${client_id}/${typeRs}`)

    public addRsFacebook = (data: any ) => http.post(`/grs`, data)
 
    // statistics
    public getInsigthsPages2 = (pageid: string | number, userToken: string, metrics: string, access_token: string) => httpFb.get(`${pageid}/insights?metric=${metrics}&access_token=${access_token}`)
}
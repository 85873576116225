/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from 'react-bootstrap';
// eslint-disable-next-line no-irregular-whitespace
import swal from 'sweetalert';
import SinglePagePDFViewer from "../views/pdf/single-page";
import Message from '../views/Message';
import API from '../services/API/API'
import Comment from "../components/comment";
import PageHead from "../components/pageHead";
import defaultAvatar from '../assets/images/default_avatar.jpg'
import "../styles/styles.css";


const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }
const background = sessionStorage.getItem('background')
const URL_CV = "https://www.api.olonateams.com/uploads/"

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}

interface IProps {
    match: IMatch
}

const Detail = ({ match }: IProps) => {
    const role = sessionStorage.getItem('role') ?? '';
    const username = sessionStorage.getItem('username') ?? '';
    const id_user = sessionStorage.getItem('id') ?? '';
    const [id, ] = useState(match.params.id ?? '')
    const [candidats, setCandidats] = useState<Array<any>>([])
    const [selectedValue, setSelectedValue] = useState('')
    const [users, setUsers] = useState<Array<any>>([])
    const [comment, setComment] = useState('')
    const [comments, setComments] = useState<Array<any>>([])
    const [show, setShow] = useState(false)
    const [showEvaluation, setShowEvaluation] = useState(false)
    const [skills, setSkills] = useState<Array<any>>([])
    const [list, setList] = useState<Array<any>>([])
    const [noteSelected, setNoteSelected] = useState<Array<any>>([])

    const handleClose = () =>{
        setShow(false)
    }

    const handleShow = () => {  
        setShow(true)
    }

    const handleCloseEvaluation = () => {
        setShowEvaluation(false)
    }
    
    const retrieveSkills = async (id_poste: string) => {
        /* service.getSkillsByPoste(id_poste)
          .then(response => {
            setState({
              skills: response.data
            });
            //console.log(response.data);
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Post.getSkillsByPoste(id_poste)
            setSkills(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleShowEvaluation = async (poste_id: string) => {
        setShowEvaluation(true)
        try {
            await retrieveSkills(poste_id);
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const onChangeValue = (e: FormEvent<HTMLInputElement>, i: number, nom: string) => {
        const l = [...list];
        l[i] = { id_candidat: id, name: nom, note: e.currentTarget.value }
        setList(l)
        const note = [...noteSelected];
        note[i] = e.currentTarget.value
        setNoteSelected(note)
        console.log(list)
    }
    
    const retrieveUsers = async () => {
        /*  service.getClientProspects(token)
          .then(response => {
            setState({
              users: response.data
            });
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Client.getClientProspects(token)
            setUsers(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const addAssignments = async (userId: string, candidatId: string) => {
        const data = {
            id_user: userId,
            id_candidat: candidatId
        };
        /* service.addAssignment(data, token)
          .then(response => {
            setState({
    
            });
            swal("Bon travail!", "Le candidat est bien affecté au client avec succès", "success");
          })
          .catch(e => {
            alert('erreur lors d\'affectation aux candidat')
          }); */
        try {
            await API.Assignement.addAssignment(data, token)
            swal("Bon travail!", "Le candidat est bien affecté au client avec succès", "success")
        }
        catch (error) {
            console.log(error)
        }
    }
     
    const updateStatus = async (candidat_id: string) => {
        /* service.updateStatusCustomer(candidat_id, token)
          .then(response => {
            setState({
              show : false
            });
            swal("Bon travail!", "Votre demande a été bien envoyé avec succès", "success");
          })
          .catch(e => {
            alert('erreur lors d\'affectation aux candidat')
          }); */
        try {
            await API.Candidate.updateStatusCustomer(candidat_id, token)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const getComments = async () => {
        /* service.getComment(id, token)
            .then(response => {
                setState({
                    comments: response.data
                });
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.Comment.getComment(id, token)
            setComments(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const getDetailCandidat = async () => {
        /* service.getDetailCandidats(id, token)
          .then(response => {
            setState({
              candidats: response.data,
    
            });
            console.log(response.data[0].evaluations)
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            const response = await API.Candidate.getDetailCandidats(id, token)
            setCandidats(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const deleteEvaluation = async (evaluationId: string) => {
      /* service.deleteEvaluation(evaluationId, token)
          .then(response => {
            getDetailCandidat();
            retrieveUsers();
            getComments();
          })
          .catch(e => {
              console.log(e);
          }); */
        try {
            await API.Evaluation.deleteEvaluation(evaluationId)
            await getDetailCandidat()
            await retrieveUsers()
            await getComments()
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const addComments = async (e: FormEvent<HTMLFormElement>, userId: string, candidatId: string) => {
        e.preventDefault();
        const data = {
            id_user: userId,
            id_candidat: candidatId,
            comment
        };
        /* service.addComment(data, token)
          .then(response => {
            setState({
              comment : ""
            });
            getComments();
          })
          .catch(e => {
            alert('erreur lors d\'ajout e commentaire')
          }); */
        try {
            await API.Comment.addComment(data, token)
            setComment('')
            await getComments()
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const onChangeSelected = (e: FormEvent<HTMLSelectElement>) => {
        setSelectedValue(e.currentTarget.value)
    }

    const onChangeComment = (e: FormEvent<HTMLTextAreaElement>) => {
        setComment(e.currentTarget.value)
    }
      
    const saveEvaluation = async () => {
        const data = {
            data: list,
        };
        console.log(data);

        /* service.addEvaluation(data)
          .then(response => {
            setState({
              showEvaluation: false,
              list : [],
              noteSelected : []
            });
            getDetailCandidat();
            retrieveUsers();
            getComments();
          })
          .catch(e => {
            console.log(e);
          }); */
        try {
            await API.Evaluation.addEvaluation(data)
            setShowEvaluation(false)
            setList([])
            setNoteSelected([])
            await getDetailCandidat()
            await retrieveUsers()
            await getComments()
        }
        catch (error) {
            console.log(error)
        }
    }
    
    useEffect(() => {
        (async () => {
            await getDetailCandidat()
            await retrieveUsers()
            await getComments()
        })()
    }, [])  

    return (
        <div >
          {candidats.map((candidat) => (
            <div className="content-wrapper" style={{backgroundImage: `url(${background})`,
            backgroundSize: "100% 100%",
            backgroundRepeat: "no-repeat"}}>
              <PageHead title={candidat.firstname} subject="Detail du candiidat" name="Detail" />
              {/* /.content-header */}
              {/* Main content */}
              {role !== 'user'
                ?
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12">
                      <div className="affect d-inline-flex">
                        <div ><b style={{color: "white"}}> Affecter à </b></div>
                        <div>
                          <select value={selectedValue} onChange={onChangeSelected}>
                            <option value=""></option>
                            {users.map((user) => (
                              <option value={user.id}>{user.username}</option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <button type="button" onClick={() => addAssignments(selectedValue, candidat.id)} className="universal-length btn btn-success btn-block"> Assigner </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                : 
                   <div className="action-container">
                <div className="button-action">
                  {/* <Link to="/Registration"> */}
                    {/* <Button className="universal-length mbtn" block type="submit">
                      <i class="fas fa-plus-circle"></i> <strong>Ajouter un candidat</strong>
                    </Button> */}
                    <Button onClick={handleShow} className="universal-length mbtn" type="submit">
                      <i className="fas fa-plus-circle"></i> <strong>Demandez un entretien</strong>
                    </Button>
                  {/* </Link> */}
                </div>
                </div>
              }
              <section className="content">
                <div>
                  {/* Small boxes (Stat box) */}
                  <div className="row">
                    {/* USER INFO */}
                    <div className="user-info-container col-md-5 col-lg-5 mt-2 col-xs-12">
                      <div className="box-item detail">
                        {/* small box */}
                        <div className="">
                          <div className="row r-1">
                            <div className="col-12 text-al">
                              <div className="image image-olona detail">
                                <img src={defaultAvatar} className="" alt="Avatar" />
                              </div>
                            </div>
                            <div className="col-12 mt-3 general-infos text-center">
                              <p className="name text-black"><strong>{candidat.lastname} {candidat.firstname}</strong></p>
                              <p className="poste"><strong>Secteur : {candidat.postes.name}</strong></p>
                              <p className="poste"><strong>Poste : {candidat.poste_precis}</strong></p>
                              <div className="sep"></div>
                              <span className="dispo"><strong>{candidat.disponibilite}</strong></span>
                            </div>
                          </div>
                          <div className="sep"></div>
                          <div className="sep"></div>
                          <hr />
                          <div className="row r-2">
                            <div className="col-12">
                            { candidat.username.includes("-") ?
                                <h4>COMMENTAIRE/{candidat.username.split('-')[0]}  <i className="ok fas fa-check-circle"></i></h4>
                               :
                               <h4>COMMENTAIRE/{candidat.username} </h4>
                              }                  
                              <p className="text-black">{candidat.comment}</p>
                              {/* <div class="sep"></div> */}
                            </div>
                          </div>
                          <div className="row r-2">
                            <div className="col-12">
                            { candidat.username.includes("-") ?
                                <h4>RAPPORT D`ENTRETIEN/{candidat.username.split('-')[0]}  <i className="ok fas fa-check-circle"></i></h4>
                               :
                               <h4>RAPPORT D`ENTRETIEN/{candidat.username} </h4>
                              }                          
                              <p className="text-black">{candidat.rapport}</p>
                              {/* <div class="sep"></div> */}
                            </div>
                          </div>
                          <div className="row r-2 r-4">
                            <div className="col-12">
                              { candidat.username.includes("-") ?
                               <h4>COMPETENCES/Candidat</h4>
                               :
                               <h4>COMPETENCES/{candidat.username} </h4>
                              }   
                              {role !== "user" ?
                              <button type="button" className="mbtn btn-favori" onClick={()=> handleShowEvaluation(candidat.postes.id)}>Ajouter l`évaluation(s)</button>                    
                              : null
                              }   
                              <div className="sep"></div>
                              {candidat.evaluations.map((evaluation: any) => (
                                <div>
                                  <h5>{evaluation.name}<span className="text-black"> ( {evaluation.note} / 10 ) {role !== "user" ? <button type="button" className="btn-favori delete" onClick={()=> deleteEvaluation(evaluation.id)}>X</button> : null }</span></h5>
                                  
                                  <div className="progress">
                                    <div className="progress-bar" style={{ width: `${evaluation.note * 10  }%` }} role="progressbar" aria-valuenow={evaluation.note} aria-valuemin={0} aria-valuemax={10}></div> 
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="sep"></div>
                        </div>
                      </div>
                      {/* <div className="image">
                        <img width="160px" height="160px" src={'http://localhost:8080/uploads/' + candidat.files[1].name} className="img-circle elevation-2" alt="User Image" />
                      </div>
                      <div className="inner">
                        <p>{candidat.firstname}/{candidat.postes.name}</p>
                        <p>Commentaire : {candidat.comment}</p>
                      </div>
  
                      <p className="small-box-footer">{candidat.postes.name}</p> */}
                      {/* {role !== 'user'
                        ?
                        <div>
                          <div>
                            <p>Affecter à :</p>
                            <select value={selectedValue} onChange={onChangeSelected}>
                              <option value=""></option>
                              {users.map((user) => (
                                <option value={user.id}>{user.username}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <button onClick={() => addAssignments(selectedValue, candidat.id)} className="btn btn-success">
                              Assigner
                            </button>
                          </div>
                        </div>
                        : null} */}
                    </div>
  
                    {/* PDF */}
                    <div className="user-cv-container col-md-7 col-lg-7 col-xs-12 mt-2">
                      {/* show PDF as image here */}
                      <div className="App">
                        {console.log(`${URL_CV}${candidat.files[0].name}`.replace(' ','%20'))}
                        { candidat.files ? <SinglePagePDFViewer pdf={`${URL_CV}${candidat.files[0].name}`} /> : null}
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-5" style={{display: "flex", backgroundColor: "white", fontFamily: "Montserrat, sans-serif"}}>
                    <div className="col-12">
                      <form onSubmit={(e) => addComments(e, id_user, candidat.id)}>
                      <div className="commentaire-detail">
                        <label>COMMENTAIRE</label>
                        <textarea className="form-control" required name="commentaire" value={comment} onChange={onChangeComment} />
                        <button type="submit" className="mt-4 float-right mr-0 btn btn-success comment-btn">
                          Commenter
                        </button>
                      </div>
                      </form>
                    </div>
                    <table className="table table-inbox table-hover">
                      <tbody>
                        {comments.map((com) => (
                          com.comments.map((coms: any) => (
                              <Comment comment={coms} />
                        ))))}
                      </tbody>
                    </table>
                  </div>
                  <div className="row">
                  
                                    </div>
                </div>
              </section>
              <Modal className="my-modal" animation={false} show={show} onHide={handleClose}>
                      {/* <Modal.Header closeButton> */}
                          {/* <Modal.Title></Modal.Title> */}
                      {/* </Modal.Header> */}
                
            <button type="button" onClick={handleClose} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
                      <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <Message user={username} candidat={candidat} actionStatus={updateStatus} actionClose={handleClose}/>
                </div>  
              </div>
            </Modal.Body>
                  </Modal>
          <Modal className="my-modal" animation={false} show={showEvaluation} onHide={handleCloseEvaluation}>
                      {/* <Modal.Header closeButton> */}
                          {/* <Modal.Title></Modal.Title> */}
                      {/* </Modal.Header> */}
                
            <button type="button" onClick={handleCloseEvaluation} className="close">
              <i className="fas fa-times"></i>
              <span className="sr-only">Close</span>
            </button>
                      <Modal.Body>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div>
                       <h3>Evaluation</h3>
                       {skills.map((skill) => (
                         skill.skills.map((skil: any, i: number) => (
                           <div className="form-group" key={i}>
                              <label htmlFor={skil.name}>{skil.name}<span className="custom-text-blue">(  {noteSelected[i] != null ? ( `${noteSelected[i] }/10` ) : `${0  }/10`})</span></label>
                             <input
                               type="range" min="0" max="10"
                               className="form-control"
                               id={skil.name}
                               required
                               value={noteSelected[i] != null ? ( noteSelected[i]) : 0}
                               onChange={e => onChangeValue(e, i, skil.name)}
                               name={skil.name}
                             />
                           </div>
                         ))))}
                          <div className="row">
                                          <div className="col-12">
                                              <button type="button" onClick={()=> saveEvaluation()} className="w-100 btn-register btn btn-success">Enregister</button>
                                          </div>
                          </div>
  
                     </div>
                </div>  
              </div>
            </Modal.Body>
                  </Modal>
          
            </div>
          ))}
        </div>
      )
}

export default Detail
import React, { useState, useEffect, FormEvent } from "react";
import {Button} from 'react-bootstrap';
import API from '../services/API/API'
/* eslint-disable-next-line import/extensions */
import defaultAvatar from '../assets/images/default_avatar.jpg'

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

interface ICandidat {
    id: string | number
    firstname: string
    lastname: string
    note: string | number
    poste: string
    point_fort: string
    point_faible: string
    evaluation: string
    disponibilite: string
    prix: string
    prix_admin: string
    comment: string
    rapport: string
    poste_precis: string
    email: string
}

type actionCloseFunction = () => void

interface IProps {
    mode: string
    candidat: ICandidat
    actionClose: actionCloseFunction
}

const Registration = ({ mode, candidat, actionClose }: IProps) => {
    const role = sessionStorage.getItem('role');
    const [id, setId] = useState(null)
    const [username] = useState(sessionStorage.getItem('username') ?? '')
    const [firstname, setFirstname] = useState(mode === 'update' ? candidat.firstname : '')
    const [lastname, setLastname] = useState(mode === 'update' ? candidat.lastname: '')
    const [note, setNote] = useState(mode === 'update' ? candidat.note : '')
    const [poste, setPoste] = useState(mode === 'update' ? candidat.poste : '')
    const [point_fort, setPoint_fort] = useState(mode === 'update' ? candidat.point_fort : '')
    const [point_faible, setPoint_faible] = useState(mode === 'update' ? candidat.point_faible : '')
    const [evaluation, setEvaluation] = useState(mode === 'update' ? candidat.evaluation : '')
    const [disponibilite, setDisponibilite] = useState(mode === 'update' ? candidat.disponibilite : '')
    const [prix, setPrix] = useState(mode === 'update' ? candidat.prix : '')
    const [prix_admin, setPrix_admin] = useState(mode === 'update' ? candidat.prix_admin : '')
    const [comment, setComment] = useState(mode === 'update' ? candidat.comment : '')
    const [rapport, setRapport] = useState(mode === 'update' ? candidat.rapport : '')
    const [sousposte, setSousposte] = useState(mode === 'update' ? candidat.poste_precis : '')
    const [email, setEmail] = useState(mode === 'update' ? candidat.email : '')
    const [selectedFile, setSelectedFile] = useState<File | string>('')
    const [avatar, setAvatar] = useState(defaultAvatar)
    const [submitted, setSubmitted] = useState(false)
    const [list, setList] = useState<Array<any>>([])
    const [skills, setSkills] = useState([])
    const [postes, setPostes] = useState([])
    const [, setPosteSelected] = useState(null)
    const [isOk, setIsOk] = useState(false)
    const [noteSelected, setNoteSelected] = useState<Array<any>>([])

    const onChangeValue = (e: FormEvent<HTMLInputElement>, i: number, nom: string) => {
        const l = [...list];
        l[i] = { id_candidat: id, name: nom, note: e.currentTarget.value }
        setList(l)

        const n = [...noteSelected];
        n[i] = e.currentTarget.value
        setNoteSelected(n)
    }

    const onChangeFileCv = (e: FormEvent<HTMLInputElement>) => {
        // Update the state 
        const file = e.currentTarget.files ? e.currentTarget.files[0] : ''
        setSelectedFile(file)
    };

    const handleClose = () => {
        actionClose()
    }

    /* handleChange(e) {
        setState({
            avatar: URL.createObjectURL(e.target.files[0]),
            selectedAvatar: e.target.files[0],
        })
    } */

    const onChangeFirstname = (e: FormEvent<HTMLInputElement>) => {
        setFirstname(e.currentTarget.value)
    }

    const onChangeRapport = (e: FormEvent<HTMLTextAreaElement>) => {
        setRapport(e.currentTarget.value)
    }

    const onChangeMail = (e: FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)
    }

    const onChangeLastname = (e: FormEvent<HTMLInputElement>) => {
        setLastname(e.currentTarget.value)
    }

    const onChangeNote = (e: FormEvent<HTMLInputElement>) => {
        setNote(e.currentTarget.value)
    }

    const onChangePoste = (e: FormEvent<HTMLSelectElement>) => {
        setPoste(e.currentTarget.value)
    }

    const onChangePrix = (e: FormEvent<HTMLInputElement>) => {
        setPrix(e.currentTarget.value)
    }

    const onChangePrixAdmin = (e: FormEvent<HTMLInputElement>) => {
        setPrix_admin(e.currentTarget.value)
    }

    const onChangeEvaluation = (e: FormEvent<HTMLInputElement>) => {
        setEvaluation(e.currentTarget.value)
    }

    const onChangePointfaible = (e: FormEvent<HTMLInputElement>) => {
        setPoint_faible(e.currentTarget.value)
    }

    const onChangePointFort = (e: FormEvent<HTMLInputElement>) => {
        setPoint_fort(e.currentTarget.value)
    }

    const onChangeSousPoste = (e: FormEvent<HTMLInputElement>) => {
        setSousposte(e.currentTarget.value)
    }

    const onChangeDisponibilite = (e: FormEvent<HTMLInputElement>) => {
        setDisponibilite(e.currentTarget.value)
    }

    const onChangeComment = (e: FormEvent<HTMLTextAreaElement>) => {
        setComment(e.currentTarget.value)
    }

    const retrieveSkills = async (id_poste: string) => {
        try {
            const response = await API.Post.getSkillsByPoste(id_poste)
            setSkills(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const fileupload = async (id_candidat: string) => {
        const formData = new FormData();
        formData.append(`file`, selectedFile);
        formData.append('id_candidat', id_candidat);
        /* service.uploadFile(formData)
            .then(response => {
                setState({
                    submitted: true
                });
                retrieveSkills(poste);
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            await API.File.uploadFile(formData)
            setSubmitted(true)
            retrieveSkills(poste)
        }
        catch (error) {
            console.log(error)
        }
    }

    const saveCandidat = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            firstname,
            lastname,
            note,
            poste,
            evaluation,
            disponibilite,
            prix,
            sousposte,
            prix_admin,
            point_faible,
            point_fort,
            comment,
            rapport,
            email,
            user : username,
            etat: 'PUBLISHED',
            etat_sousposte : 'PUBLISHED',
        };

        /* service.create(data)
            .then(response => {
                setState({
                    id: response.data.id,
                    firstname: response.data.firstname,
                    lastname: response.data.lastname,
                    note: response.data.note,
                    poste: response.data.poste,
                    evaluation: response.data.evaluation,
                    disponibilite: response.data.disponibilite,
                    prix: response.data.prix,
                    point_faible: response.data.point_faible,
                    point_fort: response.data.point_fort,
                    comment: response.data.comment
                });
                fileupload(response.data.id)
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.Candidate.create(data)
            setId(response.data.id)
            setFirstname(response.data.firstname)
            setLastname(response.data.lastname)
            setNote(response.data.note)
            setPoste(response.data.poste)
            setEvaluation(response.data.evaluation)
            setDisponibilite(response.data.disponibilite)
            setPrix(response.data.prix)
            setPoint_faible(response.data.point_faible)
            setPoint_fort(response.data.point_fort)
            setComment(response.data.comment)
            
            await fileupload(response.data.id)
        }
        catch (error) {
            console.log(error)
        }
    }

    const fileuploadUpdate = async (id_candidat: string) => {
        const formData = new FormData();
        formData.append(`file`, selectedFile);
        formData.append('id_candidat', id_candidat);
        /* service.uploadFileSpontanne(formData)
            .then(response => {
                handleClose()
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            await API.File.uploadFileSpontanne(formData)
            handleClose()
        }
        catch (error) {
            console.log(error)
        }
    }

    const updateCandidat = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            firstname,
            lastname,
            note,
            poste,
            evaluation,
            disponibilite,
            prix,
            sousposte,
            prix_admin,
            point_faible,
            point_fort,
            comment,
            rapport,
            email,
            etat: 'PUBLISHED',
            etat_sousposte : 'PUBLISHED',
        };

        /* service.updateCandidat(candidat.id,data, token)
            .then(response => {
                if(selectedFile === null){
                    handleClose()
                }
                else{
                fileuploadUpdate(response.data.id)
                }
            })
            .catch(e => {
                console.log(e);
            }); */
        try {
            const response = await API.Candidate.updateCandidat(candidat.id, data, token)
            if (!selectedFile) handleClose()
            else await fileuploadUpdate(response.data.id)
        }
        catch (error) {
            console.log(error)
        }
    }
    
    const newCandidat = () => {
        setId(null)
        setFirstname('')
        setLastname('')
        setNote('')
        setPoste('')
        setPoint_faible('')
        setPoint_fort('')
        setEvaluation('')
        setDisponibilite('')
        setPrix('')
        setPrix_admin('')
        setComment('')
        setRapport('')
        setSousposte('')
        setEmail('')
        setSelectedFile('')
        setAvatar('')
        setSubmitted(false)
        setList([])
        setSkills([])
        setPostes([])
        setPosteSelected(null)
        setIsOk(false)
        setNoteSelected([])
    }

    const retrievePostes = async () => {
        try {
            const response = await API.Post.getPostes()
            setPostes(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    
    
    const saveEvaluation = async () => {
        const data = {
          data: list,
        };

        try {
            await API.Evaluation.addEvaluation(data)
            setIsOk(true)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await retrievePostes()
        })()
    }, [])

    return (
        <div>
            {/* Main content */}
            <section className="content">
            <div>
          {isOk ? (
              <div className="submitted">
              <div style={{textAlign: 'center'}}>
                  <i className="ok fas fa-3x fa-check-circle"></i>
                  <h2><strong>Enregistrement terminé avec succès</strong></h2>
                  <Button onClick={newCandidat} className="universal-length mbtn" >
                      <i className="fas fa-plus-circle"></i> <strong>Ajouter un autre candidat</strong>
                  </Button>
              </div>
              {/* <button className="btn btn-success" onClick={newCandidat}>
                  Ajouter un autre candidat 
              </button> */}
          </div>
            ) : (
                <div className="submit-form">
                    {submitted ? (
                        <div className="submitted">
                           <div>
               <p>Compléter l`évaluation</p>
               <div>
                 <h3>Evaluation</h3>
                 {skills.map((skill: any) => (
                   skill.skills.map((skil: any, i: number) => (
                     // eslint-disable-next-line react/no-array-index-key
                     <div className="form-group" key={i}>
                        <label htmlFor={skil.name}>{skil.name}<span className="custom-text-blue">(  {noteSelected[i] != null ? ( `${noteSelected[i] }/10` ) : `${0  }/10`})</span></label>
                       <input
                         type="range" min="0" max="10"
                         className="form-control"
                         id={skil.name}
                         required
                         value={noteSelected[i] != null ? ( noteSelected[i]) : 0}
                         onChange={e => onChangeValue(e, i, skil.name)}
                         name={skil.name}
                       />
                     </div>
                   ))))}

               </div>
               <div className="form-group">
                 <button type="button" onClick={saveEvaluation} className="btn btn-success">
                   Enregistrer
                 </button>
               </div>
             </div>
                        </div>
                    ) : (
                        <form onSubmit={mode === "update" ? updateCandidat : saveCandidat}>
                        <div className="row box-register">
                            <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="title-container">
                                    <div className="title">
                                        <h2>{ mode === "update" ? "Modifier un" : "Ajouter un nouveau "} <br /><span className="text-highlight">Candidat</span></h2>
                                    </div>
                                    <div className="image image-olona">
                                        <img src={avatar} alt="User" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="lastname">Prénom(s)</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastname"
                                                required
                                                value={lastname}
                                                onChange={onChangeLastname}
                                                name="lastname"
                                                placeholder="ex : John"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="firstname">Nom</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstname"
                                                required
                                                value={firstname}
                                                onChange={onChangeFirstname}
                                                name="firstname"
                                                placeholder="ex : Smith"
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="poste">Secteur d`activité</label>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="form-group">
                                            <select  required style={{ width: '100%'}} value={poste} onChange={onChangePoste} placeholder="Choisir Votre Poste">
                                                <option value="" ></option>
                                                {postes.map((item: any) => (
                                                    <option value={item.id}>{item.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="email">Poste</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="sousposte"
                                                required
                                                value={sousposte}
                                                onChange={onChangeSousPoste}
                                                name="sousposte"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="email"
                                                required
                                                value={email}
                                                onChange={onChangeMail}
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="note">Note <span className="custom-text-blue">( {note} / 10 )</span></label>
                                            <input
                                                type="range" min="0" max="10"
                                                className="form-control"
                                                id="note"
                                                required
                                                value={note}
                                                onChange={onChangeNote}
                                                name="note"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group custom-range">
                                            <label htmlFor="point_fort"><i className="ok fas fa-check-circle"></i> Point Fort</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="point_fort"
                                                required
                                                value={point_fort}
                                                onChange={onChangePointFort}
                                                name="point_fort"
                                                placeholder="ex : sérieux"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="point_faible"><i className="oknt fas fa-times-circle"></i> Point Faible</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="point_faible"
                                                required
                                                value={point_faible}
                                                onChange={onChangePointfaible}
                                                name="point_faible"
                                                placeholder="ex : retardataire"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="disponibilite">Disponibilité</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="disponibilite"
                                                required
                                                value={disponibilite}
                                                onChange={onChangeDisponibilite}
                                                name="disponibilite"
                                                placeholder="ex : Disponible"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="prix">Prétention salariale <span className="custom-text-blue">( Ar / mois )</span></label>
                                            <input
                                                type="number"
                                                className="form-control"
                                                id="prix"
                                                required
                                                value={prix}
                                                onChange={onChangePrix}
                                                name="prix"
                                                placeholder="Ar / mois"
                                            />
                                        </div>
                                    </div>

                                    {role === 'admin'
                                        ?

                                        <div className="col-6">
                                            <div className="form-group">
                                                <label htmlFor="prix_admin">Forfait Olona <span className="custom-text-blue">( € / mois )</span></label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="prix_admin"
                                                    value={prix_admin}
                                                    onChange={onChangePrixAdmin}
                                                    name="prix_admin"
                                                    placeholder="€ / mois"
                                                />
                                            </div>
                                        </div>
                                        : null
                                    }
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="image" className=" control-label">CV</label>
                                            {mode === "update"?
                                            <input className="form-control-file" type="file" name="profile" onChange={onChangeFileCv} />
                                            : <input required className="form-control-file" type="file" name="profile" onChange={onChangeFileCv} />
                                            } 
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="disponibilite">Rapport d`évalutaion du candidat</label>
                                            <textarea
                                                className="form-control"
                                                id="rapport"
                                                required
                                                value={rapport}
                                                onChange={onChangeRapport}
                                                name="rapport"
                                                placeholder="ex : bon communication etc ..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="evaluation">Avis <span className="custom-text-blue">( {evaluation} / 5 )</span></label>
                                            <input
                                                type="range" min="0" max="5"
                                                className="form-control"
                                                id="evaluation"
                                                required
                                                value={evaluation}
                                                onChange={onChangeEvaluation}
                                                name="evaluation"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="comment" className="control-label">Commentaire</label>
                                            <textarea required className="form-control" id="comment" name="comment" value={comment}
                                                onChange={onChangeComment} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <button type="submit" className="w-100 btn-register btn btn-success">{ mode === "update" ? "Modifier" : "Suivant"}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </form>
                    )}
                </div>
                )}
            </div>
            </section>
        </div>
    )
}

export default Registration
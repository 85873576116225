import React, { useEffect, useState, FormEvent } from 'react'
import API from '../services/API/API';

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }


interface ICandidate {
    id: string
    email: string
    firstname: string
}
/* eslint-disable no-unused-vars */
type actionAccepterFunction = (id: string, email: string, firstname: string) => void 
/* eslint-enable no-unused-vars */
interface IProps {
    candidat: ICandidate
    username: string
    actionAccepter: actionAccepterFunction
}

const Registration = ({ username, candidat, actionAccepter }: IProps) => {
    // const [id, setId] = useState(null)
    const [selectedFile, setSelectedFile] = useState<null | any>(null)
    const [comment, setComment] = useState('')
    const [rapport, setRapport] = useState('')
    const [note, setNote] = useState('0')
    const [evaluation, setEvaluation] = useState('0')
    // const [submitted, setSubmitted] = useState(false)
    const [isOk, ] = useState(false)
    const [wait, setWait] = useState(false)

    useEffect(() => {
        if(isOk)
            window.location.href = "/Dashboard"
    }, [isOk])

    const handleAccepter = () => {
        actionAccepter(candidat.id, candidat.email, candidat.firstname)
    }

    const onChangeRapport = (e: FormEvent<HTMLTextAreaElement>) => {
        setRapport(e.currentTarget.value)
    }
    const onChangeFileCv = (e: FormEvent<HTMLInputElement>) => {
        const file = e.currentTarget.files ? e.currentTarget.files[0] : null
        setSelectedFile(file)
    };

    const onChangeNote = (e: FormEvent<HTMLInputElement>) => {
        setNote(e.currentTarget.value)
    }

    const onChangeEvaluation = (e: FormEvent<HTMLInputElement>) => {
        setEvaluation(e.currentTarget.value)
    }
    const onChangeComment = (e: FormEvent<HTMLTextAreaElement>) => {
        setComment(e.currentTarget.value)
    }

    const fileupload = async (id_candidat: string | Blob) => {
        /* var formData = new FormData();
        formData.append(`file`, this.state.selectedFile);
        formData.append('id_candidat', id_candidat);
        service.uploadFileSpontanne(formData)
            .then(response => {
                this.handleAccepter()
            })
            .catch(e => {
                console.log(e);
            }); */
        const formData = new FormData()
        formData.append(`file`, selectedFile);
        formData.append('id_candidat', id_candidat);
        try {
            await API.File.uploadFileSpontanne(formData)
            handleAccepter()
        }
        catch (error) {
            console.log(error)
        }
    }

    const saveCandidat = async () => {
        /* var data = {
            note: this.state.note,
            evaluation: this.state.evaluation,
            comment: this.state.comment,
            rapport: this.state.rapport,
            etat: 'PUBLISHED',
            user : this.props.username +"-candidat"
        };
        service.update(this.props.candidat.id, data, token)
            .then(response => {
                this.setState({
                    note: response.data.note,
                    evaluation: response.data.evaluation,
                    comment: response.data.comment,
                    wait : true
                });
                this.fileupload(this.state.id);
            })
            .catch(e => {
                console.log(e);
            }); */
        const data = {
            note,
            evaluation,
            comment,
            rapport,
            etat: 'PUBLISHED',
            user: `${username}-candidat`
        }
        try {
            const response = await API.Candidate.update(candidat.id, data, token)
            setNote(response.data.note)
            setEvaluation(response.data.evaluation)
            setComment(response.data.comment)
            setWait(true)
            await fileupload(candidat.id)
        }
        catch (error) {
            console.log(error)
        }
    }

    return (
        <div>
            {/* Main content */}
            <section className="content">
                <div>
                    <div className="submit-form">
                        <div className="row box-register">
                            <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="rapport">Rapport </label>
                                            <textarea 
                                                className="form-control"
                                                id="rapport"
                                                required
                                                value={rapport}
                                                onChange={onChangeRapport}
                                                name="rapport"
                                                placeholder="ex : bon communication etc ..."
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="note">Note <span className="custom-text-blue">( {note} / 10 )</span></label>
                                            <input
                                                type="range" min="0" max="10"
                                                className="form-control"
                                                id="note"
                                                required
                                                value={note}
                                                onChange={onChangeNote}
                                                name="note"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="evaluation">Avis <span className="custom-text-blue">( {evaluation} / 5 )</span></label>
                                            <input
                                                type="range" min="0" max="5"
                                                className="form-control"
                                                id="evaluation"
                                                required
                                                value={evaluation}
                                                onChange={onChangeEvaluation}
                                                name="evaluation"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="comment" className="control-label">Commentaire</label>
                                            <textarea className="form-control" id="comment" name="comment" value={comment}
                                                onChange={onChangeComment}  />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="image" className=" control-label">Nouveau CV</label>
                                            <input required className="form-control-file" type="file" name="profile" onChange={onChangeFileCv} />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        { wait ?
                                            <button type="button" className="w-100 btn-register btn btn-success">Veuillez patienter ...</button>
                                        :<button type="button" onClick={saveCandidat} className="w-100 btn-register btn btn-success">Enregistrer</button>
                                        }
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    );
}

export default Registration
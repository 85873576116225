import React, { useState, FormEvent  } from 'react'
import * as emailjs from "emailjs-com";

const template_id = "template_u9dguxs"
const serice_id = "service_0w314u9"
const user_id = "user_zOdi9xxPdyaZik1HKn38h"
let wait = false

interface ICandidat {
    firstname: string
    lastname: string
    id: string
}

interface IProps {
    user: string
    candidat: ICandidat
    actionStatus: any
    actionClose: any
}

const Message = ({ user, candidat, actionClose, actionStatus }: IProps) => {
    const [reply_to, setReply_to] = useState('s.maurel@olona-outsourcing.com')
    const [, setIsOk] = useState(false)

    const handlestatus = (candidat_id: any) => {
        actionStatus(candidat_id);
    }
    
    const handleClose = () => {
        actionClose();
    }
    
    const handleChangeReply = (e: FormEvent<HTMLInputElement>) => {
        setReply_to(e.currentTarget.value)
    };
    
    const onSubmit = async (e: FormEvent<HTMLButtonElement>) => {
        wait = true
        e.preventDefault();
        const sendto: any = {}
        sendto.from_name = user
        sendto.candidat_name = `${candidat.firstname} ${candidat.lastname}`
        sendto.to_name = 'Responsable Recrutement'
        sendto.message = `Veuillez me programmer un entretien avec ${candidat.firstname} ${candidat.lastname} s’il vous plait.` 
        sendto.reply_to = reply_to
        /* emailjs.send(
          serice_id,
          template_id,
          sendto,
          user_id
        )
          .then((response) => {
            handlestatus(candidat.id)
            setState({
              isOk: true
            });
          })
          .catch((err) => {
            console.log('FAILED...', err);
            alert("mesage no complète ou serveur error")
          }); */
        try {
            await emailjs.send(
                serice_id,
                template_id,
                sendto,
                user_id
            )
            handlestatus(candidat.id)
            setIsOk(true)
        }
        catch (error) {
            console.log(error)
        }
    };

    return (
        <div>
            <div>
                <h3>vous voulez vraiment faire une demande d`entretien avec {candidat.firstname} ?</h3>
            </div>
                <input type="email" hidden
                 className="form-control" id="email" aria-describedby="emailHelp" value={reply_to} onChange={handleChangeReply} />
            {wait ? <div>
                <button type="button" className="btn btn-primary"  >Veuillez patienter ...</button>
              </div>
            :<div>
                <button type="button" className="btn btn-primary" onClick={onSubmit} >Oui</button>
                <button type="button"  className="btn btn-primary" onClick={handleClose} >Non</button>
            </div>
            }
        </div>
      )
}

export default Message
import http from '../APIs/olonaTeams'

export default class UserAPI {
    // user
    public signin = (data: any) => http.post('/signin', data)

    public signup = (data: any) => http.post('/signup', data)

    public updatePassword = (id: string | number, data: any, token: any) => http.put(`/password/${id}`, data, token)

    public getAllUser = (token: any) => http.get("/users", token)

    public getAllUsers = (id_user: string | number, token: any) => http.get(`/allusers/${id_user}`, token)

    public getDetailUser = (id_user: string | number) => http.get(`/user/${id_user}`)
}
import React, { useState, useEffect, FormEvent } from "react";
import { Button } from "react-bootstrap";
import API from '../services/API/API'
import "react-datepicker/dist/react-datepicker.css";

const token = {
    headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};

/* eslint-disable no-unused-vars */
type actionSearchFunction = (trimestre: string, collaborator: string, annee: string) => void
/* eslint-enable no-unused-vars */

interface IProps {
    client: string
    actionSearch: actionSearchFunction
}

const BoxSearchSuivi = ({ client, actionSearch }: IProps) => {
    const role = sessionStorage.getItem("role");
    // const [id, setId] = useState<string | null>(null)
    const [id_col, setId_col] = useState('')
    // const [username, setUsername] = useState('')
    const [trimestre, setTrimestre] = useState('')
    const [annee, setAnnee] = useState('')
    const [collaborator, setCollaborator] = useState('')
    const [collaborators, setCollaborators] = useState([])
    // const [date_start, setDate_start] = useState(new Date())
    // const [date_end, setDate_end] = useState(new Date())

    const getAllCollaborator = async () => {
        /* service.getAllCollaborator(token)
            .then(response => {
                setState({
                    collaborators: response.data,
                });
            })
            .catch(e => {
                console.log(e);
            }); */

        try {
            const response = await API.Collaborator.getAllCollaborator(token)
            setCollaborators(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const retrieveCollaborators = async (client_id: string) => {
        /* service.getCollaboratorByClientId(client_id, toke)
            .then((response) => {
                setState({
                    collaborators: response.data,
                });
                //console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            }); */
        try {
            const response = await API.CollaboratorEvaluation.getCollaboratorByClientId(client_id, token)
            setCollaborators(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleSearch = (trimestreP: string, collaboratorP: string, anneeP: string) => {
        actionSearch(trimestreP, collaboratorP, anneeP);
    }

    const onChangeTrimestre = (e: FormEvent<HTMLSelectElement>) => {
        setTrimestre(e.currentTarget.value)
    }

    const onChangeYear = (e: FormEvent<HTMLInputElement>) => {
        setAnnee(e.currentTarget.value)
    }

    const onChangeCollaborator = (e: FormEvent<HTMLSelectElement>) => {
        setCollaborator(e.currentTarget.value)
    }

    useEffect(() => {
        (async () => {
            if(sessionStorage.getItem("role") === "user")
                await retrieveCollaborators(client)
            else
                await getAllCollaborator()
        })()
        // id_user = sessionStorage.getItem('client_id');
        const idCol = sessionStorage.getItem('id')
        if (idCol) setId_col(idCol)
    }, [])

    return (
        <div>
            {/* Main content */}
            <section className="content">
                <div>
                    <div className="row box-register">
                        <div className="col-10 offset-1 pt-5 pb-5">
                            <div className="title-container">
                                <div className="title">
                                    <h2>
                                        Rechercher évaluation : <br />
                                    </h2>
                                </div>
                            </div>
                           {role !== "collaborator"  && role !== "moderator" ?
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="collaborator">Nom</label>
                                        <select required style={{ width: '100%' }} value={collaborator} onChange={onChangeCollaborator} placeholder="Choisir un mois">
                                            <option value=""></option>
                                            {collaborators.map((col: any) => (<option value={col.id}>{col.username}</option>)
                                            )}
                                        </select>
                                    </div>
                                </div>

                            </div>
                            : null
                            }
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="trimestre">Mois</label>
                                        <select required style={{ width: '100%' }} value={trimestre} onChange={onChangeTrimestre} placeholder="Choisir un mois">
                                            <option value=""></option>
                                            <option value="1">Janvier</option>
                                            <option value="2">Fevrier</option>
                                            <option value="3">Mars</option>
                                            <option value="4">Avril</option>
                                            <option value="5">Mai</option>
                                            <option value="6">Juin</option>
                                            <option value="7">Juillet</option>
                                            <option value="8">Août</option>
                                            <option value="9">Septembre</option>
                                            <option value="10">Octobre</option>
                                            <option value="11">Novembre</option>
                                            <option value="12">Décembre</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label htmlFor="annee">Année</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="annee"
                                            required
                                            value={annee}
                                            onChange={onChangeYear}
                                            name="annee"
                                            placeholder=""
                                        />
                                    </div>
                                </div>

                            </div>
                        {role !== "collaborator" && role !== "moderator" ?
                            <div className="row">
                                <div className="col-3">
                                    <Button
                                        className="universal-length mbtn"
                                        onClick={() =>
                                            handleSearch(
                                                trimestre,
                                                collaborator,
                                                annee
                                            )
                                        }
                                    >
                                        <strong>
                                            <i className="fas fa-search"></i> Rechercher
                                        </strong>
                                    </Button>
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="col-3">
                                    <Button
                                        className="universal-length mbtn"
                                        onClick={() =>
                                            handleSearch(
                                                trimestre,
                                                id_col,
                                                annee
                                            )
                                        }
                                    >
                                        <strong>
                                            <i className="fas fa-search"></i> Rechercher
                                        </strong>
                                    </Button>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
} 

export default BoxSearchSuivi
import React, { useState, useEffect, FormEvent } from "react";
import { EditorState, convertToRaw ,ContentState} from 'draft-js';
import { Alert } from 'react-bootstrap'
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import API from '../services/API/API';
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const token = { headers: { "x-access-token": sessionStorage.getItem('accessToken') } }

interface IProps {
    mode: string
    interest: string
    company: string
    activite: string
    fiche: string
    client_id: string
    onSuccess: any
}

const { Heading } = Alert

const RegisterClient = ({ mode, interest, company, activite, fiche, client_id, onSuccess }: IProps) => {
    const [, setId] = useState('')
    const [, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [selectedValue, setSelectedValue] = useState(mode === 'update' ? interest : '')
    const [password, setPassword] = useState('')
    const [societe, setSociete] = useState(mode === 'update' ? company : '')
    const [Activite, setActivite] = useState('')
    const [Fiche, setFiche] = useState('')
    const [editorState, setEditorState] = useState(EditorState.createEmpty())
    const [editorState1, setEditorState1] = useState(EditorState.createEmpty())
    const [showAlert, setShowAlert] = useState(false)
    const [success, setSuccess] = useState(true)
    const [message, setMessage] = useState(mode === 'update' ? 'Modification réussie!' : 'Données enregistrée!')

    const onChangeSociete = (e: FormEvent<HTMLInputElement>) => {
        setSociete(e.currentTarget.value)
    }

    const onChangeFichePoste = (editorStateP: EditorState) => {
        setEditorState(editorStateP)
        setFiche(draftToHtml(convertToRaw(editorState.getCurrentContent())))
    }

    const onChangeActivite = (editorState1P: EditorState) => {
        setEditorState1(editorState1P)
        setActivite(draftToHtml(convertToRaw(editorState1P.getCurrentContent())))
    }

    const onChangeSelected = (e: FormEvent<HTMLSelectElement>) => {
        setSelectedValue(e.currentTarget.value)
    }

    const onChangeEmail = (e: FormEvent<HTMLInputElement>) => {
        setEmail(e.currentTarget.value)
    }

    const editorActivite = () => {
        const html = activite;
        const contentBlock = htmlToDraft(html);

        if (contentBlock) {
            const contentStateB = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorStateB = EditorState.createWithContent(contentStateB);
            setEditorState1(editorStateB)
            setActivite(draftToHtml(convertToRaw(editorStateB.getCurrentContent())))
        }
    }

    const editorFiche = () => {
        const html = fiche;
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentStateB = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorStateB = EditorState.createWithContent(contentStateB);
            setEditorState(editorStateB)
            setFiche(draftToHtml(convertToRaw(editorStateB.getCurrentContent())))
        }
    }

    const onChangePassword = (e: FormEvent<HTMLInputElement>) => {
        setPassword(e.currentTarget.value)
    }

    const updateUser = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
          company: societe,
          interest: selectedValue,
          activite: Activite,
          fiche: Fiche
        };

        try {
            const response = await API.Client.updateClientProspect(client_id, data, token)
            if (response.data.id) {
                setId(response.data.id)
                setUsername(response.data.username)
                setEmail(response.data.email)
                setShowAlert(true)
                onSuccess()
                setMessage(mode === 'update' ? 'Modification réussie!' : 'Données enregistrée!')
                setTimeout(() => {
                    setShowAlert(false)
                }, 1500)
            }
            else {
                setShowAlert(true)
                setSuccess(false)
                setMessage(mode === 'update' ? 'Oups, une erreur est survenu lors de la modification' : "Oups, une erreur est survenu lors de l'enregistrement")
                setTimeout(() => {
                    setShowAlert(false)
                    setSuccess(true)
                    setMessage(mode === 'update' ? 'Modification réussie!' : 'Données enregistrée!')
                }, 1500)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const saveUser = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            username: societe,
            email,
            societe,
            fiche: Fiche,
            interest: selectedValue,
            activite: Activite,
            etat: 'NOT PUBLISHED',
            password,
        };

        try {
            const response = await API.Client.createClient(data, token)
            if (response.data.id) {
                setId(response.data.id)
                setUsername(response.data.username)
                setEmail(response.data.email)
                setShowAlert(true)
                onSuccess()
                setMessage(mode === 'update' ? 'Modification réussie!' : 'Données enregistrée!')
                setTimeout(() => {
                    setShowAlert(false)
                }, 1500)
            }
            else {
                setShowAlert(true)
                setSuccess(false)
                setMessage(mode === 'update' ? 'Oups, une erreur est survenu lors de la modification' : "Oups, une erreur est survenu lors de l'enregistrement")
                setTimeout(() => {
                    setShowAlert(false)
                    setSuccess(true)
                    setMessage(mode === 'update' ? 'Modification réussie!' : 'Données enregistrée!')
                }, 1500)
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    /* const onEditorStateChange = (editorStateP: EditorState) => {
        setEditorState(editorStateP)
    } */

    useEffect(() => {
        if (mode === 'update') {
            editorActivite()
            editorFiche()
        }
    }, [])

    return (
        <div>
            <div className="content">
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div>
                        
                        {/* Small boxes (Stat box) */}
                        <form onSubmit={mode === "update" ? updateUser : saveUser}>
                        <div className="row box-register">
                            <div className="col-10 offset-1 pt-5 pb-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="societe">Société</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="societe"
                                                required
                                                value={societe}
                                                onChange={onChangeSociete}
                                                name="societe"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="societe">Intérêt</label>
                                            <select required className="form-control" value={selectedValue} onChange={onChangeSelected}>
                                            <option value=""></option>
                                            <option value="Faible">Faible</option>
                                            <option value="Moyen">Moyen</option>
                                            <option value="Fort">Fort</option>
                                            <option value="Sortie">Sortie</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="comment" className="control-label">Fiche de poste</label>
                                            <Editor
                                              editorState={editorState}
                                              editorStyle={{ border: "1px solid #C0C0C0", minHeight: "75px", fontFamily: "'Montserrat', sans-serif;"}}
                                              onEditorStateChange={onChangeFichePoste}
                                            />
                                            {/* <textarea required className="form-control" type="text" id="comment" name="comment" value={fiche}
                                                onChange={onChangeFichePoste} accept="" /> */}
                                        </div>
                                    </div>
                                </div>
                               
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="comment" className="control-label">Activité</label>
                                            <Editor
                                              editorState={editorState1}
                                              editorStyle={{ border: "1px solid #C0C0C0", minHeight: "75px", fontFamily: "'Montserrat', sans-serif;"}}
                                              onEditorStateChange={onChangeActivite}
                                            />
                                            {/* <textarea required className="form-control" type="text" id="comment" name="comment" value={fiche}
                                                onChange={onChangeFichePoste} accept="" /> */}
                                        </div>
                                    </div>
                                </div>
                                {mode !== "update" ?
                                <div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="point_faible">Email</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                required
                                                value={email}
                                                onChange={onChangeEmail}
                                                name="point_faible"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <label htmlFor="password">Mot de passe</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                id="password"
                                                required
                                                value={password}
                                                onChange={onChangePassword}
                                                name="password"
                                            />
                                        </div>
                                    </div>
                                </div>
                                </div>
                                : null
                                }
                                {
                                    showAlert ? (
                                        <div className="row">
                                            <div className="col-12">
                                                <Alert variant={success ? 'success' : 'danger'}>
                                                    <Heading>Mise à jour</Heading>
                                                    <p>{message}</p>
                                                </Alert>
                                            </div>
                                        </div>
                                    ) : null
                                }
                                <div className="row">
                                    <button type="submit" className="w-100 btn-register btn btn-success"> Enregistrer</button>
                                </div>
                            </div>
                        </div>
                        </form>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default RegisterClient
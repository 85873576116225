/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import API from "../services/API/API";
import 'react-tabs/style/react-tabs.css';

interface IParams {
    id: string
}

interface IMatch {
    params: IParams
}

interface IProps {
    match: IMatch
}

const SocialeModuleCM = ({ match}: IProps) => {
    const [page_id, setPage_id] = useState('')
    const [pages, setPages] = useState<Array<any>>([])
    const [name_of_page, setName_of_page] = useState('')
    const [showContent, setShowContent] = useState(false)
    const [user_token, setUser_token] = useState('')
    const [posts, setPosts] = useState<Array<any>>([])
    const [code, setCode] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [access, setAccess] = useState<any>({})
    const [access_token, setAccess_token] = useState('')

    const getAllPages = async (user_id: string,userToken: string) => {
        /* service.getAllPages(user_id, userToken)
        .then(response => {
            setState({
                pages: response.data.data,
            });
            console.log("aall page"+JSON.stringify(response.data.data))
        })
        .catch(e => {
            console.log(e);
        }); */
        try {
            const response = await API.Facebook.getAllPages(user_id, userToken)
            setPages(response.data.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const getAccessToken = async (typegrs: string) => {
      /* service.getAccessToken(match.params.id, typegrs)
      .then(response => {
          setState({
            access: response.data,
          });
          getAllPages(response.data.user_id, response.data.access_token)
      })
      .catch(e => {
          console.log(e);
      }); */
        try {
            const response = await API.Facebook.getAccessToken(match.params.id, typegrs)
            setAccess(response.data)
            await getAllPages(response.data.user_id, response.data.access_token)
        }
        catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        (async () => {
            await getAccessToken("fb")
        })()
    }, [])

    return (
        <div>
        <div
        className="content-wrapper"
        style={{
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%"}}>
        <div>
        <Tabs>
        <TabList>
          <Tab>Facebook</Tab>
          <Tab>LinkedIn</Tab>
        </TabList>
    
        <TabPanel>
          <h3>Liste des pages</h3>
             <div className="row userList">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Nom de la page</th>
                          <th className="text-center" colSpan={3} scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pages.map((page) => (
                            <tr>
                              <th scope="row">
                                  0
                              </th>
                              <td>{page.name}</td>
                              <td className="decision" align="left"  title="voir contenue page">
                                <Link to={`/grspost/${  page.id}/${ page.name}/${ page.access_token}/${match.params.id}`}>
                                  <i className="fas fa-info-circle"> Voir contenue page</i>
                                </Link>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
        </TabPanel>
        <TabPanel>
          <h3>Listes des pages</h3>
          
        </TabPanel>
      </Tabs>
       </div>
      <div>
      </div>
      </div>
      </div>
     );
}

export default SocialeModuleCM
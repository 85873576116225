import React, { useState, useEffect, FormEvent } from "react";
import { Button, Modal } from "react-bootstrap";
import Pagination from "react-js-pagination";
import API from '../services/API/API';
import PageHead from "../components/pageHead";
import EvaluationDetailsComponent from "../components/evaluation";

const token = {
  headers: { "x-access-token": sessionStorage.getItem("accessToken") },
};
const background = sessionStorage.getItem("background");

const Evaluationc = () => {
    const [evaluations, setEvaluations] = useState<Array<any>>([])
    const [searchTitle, setSearchTitle] = useState('')
    const [mois, setMois] = useState<any>(null)
    const [annee, setAnnee] = useState<any>(null)
    // eslint-disable-next-line no-unused-vars
    const [show, setShow] = useState(false)
    const [showDetails, setShowDetails] = useState(false)
    const [activePage, setActivePage] = useState(1)
    const [totalCustomers, ] = useState(0)
    const [search, ] = useState(false)
    const [id_user, ] = useState(sessionStorage.getItem('id') ?? '')
    const [currentCollaborateur, setCurrentCollaborateur] = useState('')
    const [collaborateurName, setCollaborateurName] = useState('')

    // eslint-disable-next-line no-unused-vars
    const retrieveEvaluationSommes = async (pageNumber: number) => {
        /* service.getCollaboratorEvaluation(id_user, token)
        .then((response) => {
            setState({
                evaluations: response.data,
            });
            //console.log(response.data);
        })
        .catch((e) => {
            console.log(e);
        }); */
        try {
            const response = await API.CollaboratorEvaluation.getCollaboratorEvaluationc(id_user, token)
            setEvaluations(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    // eslint-disable-next-line no-unused-vars
    const handleClose = async () => {
        setShow(false)
        await retrieveEvaluationSommes(1);
    }

    const handleCloseDetails = () => {
        setShowDetails(false)
    }                                 

    /* const handleShow = () => {
        setShow(true)
    } */

    const handleShowDetails = () => {
        setShowDetails(true)
    }

    /* const evaluer = (collaborateur_id: string) => {
        // setState({ show: true });
        setCurrentCollaborateur(collaborateur_id)
        handleShow()
    } */

    const details = (collaborateur_id: string, moisP: any, anneeP: any, collaborateurNameP: string) => {
        // setState({ show: true });
        setCurrentCollaborateur(collaborateur_id)
        setCollaborateurName(collaborateurNameP)
        setMois(moisP)
        setAnnee(anneeP)
        handleShowDetails()
    }
    
    /* const retrieveEvaluations = async (pageNumber: number) => {
        try {
            const response = await API.Collaborator.getUserCollaborator(id_user, token)
            setEvaluations(response.data)
        }
        catch (error) {
            console.log(error)
        }
    } */
    
    const onChangeSearchTitle = async (e: FormEvent<HTMLInputElement>) => {
        setSearchTitle(e.currentTarget.value)
        if (e.currentTarget.value === "") {
            await retrieveEvaluationSommes(activePage)
        } else {
            /* service.find(searchTitle, id_user, token)
            .then((response) => {
                setState({
                    evaluations: response.data
                });
                //console.log(response.data);
            })
            .catch((e) => {
                console.log(e);
            }); */
            try {
                const response = await API.CollaboratorEvaluation.findCollaborator(e.currentTarget.value, id_user, token)
                setEvaluations(response.data)
            }
            catch (error) {
                console.log(error)
            }
        }
    }
    
    const handlePageChange = async (pageNumber: number) => {
        setActivePage(pageNumber)
        if (!search) await retrieveEvaluationSommes(pageNumber)
    }

    const digitToMonth = (digit: number) => {
        switch (digit) {
            case 1:
                return "Janvier";
            case 2:
                return "Fevrier";
            case 3:
                return "Mars";
            case 4:
                return "Avril";
            case 5:
                return "Mai";
            case 6:
                return "Juin";
            case 7:
                return "Juillet";
            case 8:
                return "Août";
            case 9:
                return "Septembre";
            case 10:
                return "Octobre";
            case 11:
                return "Novembre";
            case 12:
                return "Decembre";
            default:
                return "";
        }
    }

    useEffect(() => {
        (async () => {
            await retrieveEvaluationSommes(1)
        })()
    }, [])

    return (
        <div>
          <div
            className="content-wrapper"
            style={{backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%"}}
          >
            {/* TITLE & BREADCRUMBS */}
            <PageHead title="Les évaluations" subject="Liste évaluations" name="Evaluations" />
  
            {/* SEARCH FORM */}
              <div className="action-container">
                <div className="searchbar-container">
                  <div className="form-inline">
                    <div className="input-group input-group-sm" title="Vous pouvez faire des recherche par nom, premon, note, disponibilté ou status">
                      <span className="universal-length icon-r">
                        <i className="fas fa-search"></i>
                      </span>{" "}
                      <input
                        className="form-control form-control-navbar universal-length recherche"
                        value={searchTitle}
                        onChange={onChangeSearchTitle}
                        type="search"
                        placeholder="Rechercher"
                        aria-label="Search"
                      />
                    </div>
                    
                  </div>
                </div>
                
              </div>
  
            {/* END SEARCH FORM */}
            <section className="content">
              <div>
                  <div className="table-responsive">
                    <table className="table table-curved">
                      <thead >
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Mois</th>
                          <th scope="col">Année</th>
                          <th scope="col">Note/40</th>
                          <th scope="col" style={{width: "20%"}} >Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      { evaluations.map((collaborateur, i) => (
                            
                          <tr>
                            <td> {i+1} </td>
                            <td> {digitToMonth(collaborateur.mois)} </td>
                            <td> {collaborateur.annee} </td>
                            <td> {collaborateur.note} </td>
                            <td>
                                      <Button
                                          className="btn mbtn btn-information"
                                          onClick={() =>
                                            details(collaborateur.user_id, collaborateur.mois, collaborateur.annee, collaborateur.username)
                                          }
                                      >
                                          <strong>
                                          Details
                                          </strong>
                                      </Button>
                            </td>
                          </tr>
                      ))}
                      </tbody>
                    </table>
  
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={evaluations.length}
                      totalItemsCount={totalCustomers}
                      onChange={handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                 
              </div>
            </section>
  
            
            
            <Modal
              className="my-modal"
              animation={false}
              show={showDetails}
              onHide={handleCloseDetails}
            >
  
              <button type="button" onClick={handleCloseDetails} className="close">
                <i className="fas fa-times"></i>
                <span className="sr-only">Close</span>
              </button>
              <Modal.Body>
                <div className="row">
                  
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <EvaluationDetailsComponent client={id_user} collaborateur={currentCollaborateur} mois={mois} annee={annee} collaborateurName={collaborateurName}/>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            
          </div>
        </div>
      )
}

export default Evaluationc